










































import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";
import { categoryModel } from "@/models/categoryModel";
import { mediaType } from "@/models/mediaModel";
import { facetModel } from "@/models/facetModel";
@Component({})
export default class categoryCard extends Vue {
	@Prop({ default: () => new categoryModel() })
	category: categoryModel;
	@Prop({ default: () => null })
	selectedId: number | null;
	@Prop({ default: () => null })
	facet: facetModel;
	@Prop({ default: () => false })
	showTotal: boolean;
	onClick(c: categoryModel) {
		this.$emit("select", c);
	}

	visible: boolean = false;

	get accordionId() {
		return "accordion-" + (this.category == null ? "" : this.category.id);
	}
	get isSelected(): boolean {
		if (this.selectedId == null) return false;
		return this.category.id == this.selectedId || this.isAnyChildrenSelected(this.category.subCategories);
	}
	get showSubcategories(): boolean {
		return this.category != null && this.category.subCategories != null && this.category.subCategories.length > 0;
	}
	get hasMoreThenSubcategories(): boolean {
		return this.category != null && this.category.subCategories != null && this.category.subCategories.length > 4;
	}
	get totalProducts(): number {
		if (this.category == null || this.category.id == null || this.facet == null || this.facet.values == null) return 0;
		var total = this.facet.values[this.category.id];
		return total == null ? 0 : total;
	}
	getPropery(category: categoryModel, property: string) {
		if (category == null || category.locales == null) return "";
		var l = category.locales["it-IT"];
		return l == null ? "" : l[property];
	}
	getImageUrl(category: categoryModel) {
		if (category == null || category.media == null || category.media.length == 0) return null;

		var media = category.media
			//.sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1))
			.find(x => x.mediaType == mediaType.Image && x.languageId == "it-IT");

		return media == null ? null : media.path;
	}
	isAnyChildrenSelected(categories: categoryModel[]): boolean {
		var instance = this;
		if (instance.selectedId == null) return false;
		if (categories == null || categories.length == 0) return false;
		else {
			return categories.find(x => x.id == instance.selectedId || this.isAnyChildrenSelected(x.subCategories)) != null;
		}
	}
}

import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { pagedList, sortDirection } from "@/models/pagedList";
import { orderModel, orderState } from "@/models/orderModel";
@Component({})
export default class ordersList extends Vue {
  @Prop({ default: "Lista ordini" })
  title: string;

  @Prop({ default: sortDirection.ASC })
  sortDirection: sortDirection;

  @Prop({ default: "" })
  sortField: string;
  
  @Prop({ default: true })
  enableSort: boolean;

  @Prop({ default: false })
  isLoading: boolean;

  @Prop({ default: true })
  showDestination: boolean;

  @Prop({ default: () => new pagedList<orderModel>() })
  pagedList: pagedList<orderModel>;

  get orders(): Array<orderModel> {
    if (this.pagedList == null || this.pagedList.items == null) return [];
    return this.pagedList.items;
  }
  get collSpan(): number {
    var collSpan = 7;
    if (this.showDestination == false) collSpan--;
    return collSpan;
  }
  headClick(field: string) {
    this.$emit("sort", field);
  }
  getSortCss(field: string): string {
    if (field != this.sortField) return "fas fa-sort text-black-transparent-5";

    return this.sortDirection == sortDirection.ASC
      ? "fas fa-sort-up text-black"
      : "fas fa-sort-down text-black";
  }

  getIsERPAlign(order: orderModel): boolean {
    return order != null && order.orderState == orderState.Completed;
  }
  getCode(order: orderModel): string {
    var instance = this;
    // return `/order/${instance.getOrderCode(order)}`;
    return order.orderSourceId != null
      ? order.orderSourceId.split("_")[0]
      : order.orderSourceId;
  }
  getDestination(order: orderModel): string {
    var instance = this;
    // return `/order/${instance.getOrderCode(order)}`;
    return order.orderCustomer != null && order.orderCustomer.company != null
      ? order.orderCustomer.company
      : "";
  }
  getProductsNumber(order: orderModel): string | number {
    return order.orderDetails != null ? order.orderDetails.length : "";
  }
  getDetailUrl(order: orderModel): string {
    var instance = this;
    // return `/order/${instance.getOrderCode(order)}`;
    return `/order/${order.id}`;
  }
  getCustomerUrl(order: orderModel): string {
    var instance = this;
    // return `/order/${instance.getOrderCode(order)}`;
    return `/customer/${order.customer_Id}`;
  }
}

import eventHub from "@/eventHub";
import { AddedSkuToCartResponse, AddSkuToCart, AddSkuToCartRequest } from "@/models/productModel";
import axios from "axios";
import { Component } from "vue-property-decorator";
import baseUserContextProductComponent from "../baseUserContextProductComponent";

@Component({})
export default class quickInsertProduct extends baseUserContextProductComponent {
  productCode: string = "";
  productQuantity: number = 1;
  isAddingToCart: boolean = false;
  allowKeyCodes = [37, 38, 39, 40, 8];
  allowKeys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

  onKeyDown($event: KeyboardEvent) {
    if (this.allowKeyCodes.indexOf($event.keyCode) == -1 && this.allowKeys.indexOf($event.key) == -1) {
      $event.preventDefault();
    }
  }
  onBlur() {
    if (this.productQuantity < 1) {
      this.productQuantity = 1;
    }
  }
  increase() {
    if (this.productQuantity == null) this.productQuantity = 1;
    else this.productQuantity = Number.parseInt(this.productQuantity as any) + 1;
  }
  decrease() {
    if (this.productQuantity == null || this.productQuantity <= 1) this.productQuantity = 1;
    else this.productQuantity = Number.parseInt(this.productQuantity as any) - 1;
  }

  onSubmit(e) {
    e.preventDefault();
    var instance = this;
    var request = new AddSkuToCartRequest();
    var p = new AddSkuToCart();
    p.customerId = instance.getCurrentCustomerId;
    p.sku = instance.productCode;
    p.quantity = instance.productQuantity;
    request.products.push(p);
    instance.isAddingToCart = true;
    axios
      .post<AddedSkuToCartResponse>(`cart/addproducts`, request)
      .then(res => {
        instance.setCurrentCart(res.data.cart);
        res.data.products.forEach(x => instance.$set(x, "source", "quick-insert-product"));
        eventHub.$emit(eventHub.events.CART_UPDATED, res.data.products);

        instance.isAddingToCart = false;
      })
      .catch(error => {
        console.error(error);
        instance.isAddingToCart = false;
      })
      .finally(() => (instance.isAddingToCart = false));


  }
}
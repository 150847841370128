import { Component } from "vue-property-decorator";
import { mapActions } from "vuex";
import { AxiosPromise } from "axios";
import baseUserContextComponent from "../baseUserContextComponent";

@Component({
  methods: {
    ...mapActions(["logoutUser"])
  }
})
export default class sidebarNavProfile extends baseUserContextComponent {
  stat: string = "";
  logoutUser: () => AxiosPromise<Boolean>;

  expand() {
    this.stat = this.stat == "expand" ? "collapse" : "expand";
  }
  onLogout() {
    var instance = this;
    return new Promise((result, reject) => {
      instance
        .logoutUser()
        .then(res => {
          instance.$router.push({ name: "login" });
          result(true);
        })
        .catch(error => {
          console.error(error);
          reject();
        });
    });
  }
}

import { Component, Prop, Watch } from "vue-property-decorator";
import baseUserContextComponent from "../baseUserContextComponent";
import { categoryModel } from "@/models/categoryModel";
import { mediaType } from "@/models/mediaModel";
@Component({})
export default class categoryPanelNavBarTabs extends baseUserContextComponent {
  tabIndex: number = null;
  resetIndex() {
    this.tabIndex = null;

    if (this.$refs.subNavBars != null) {
      (this.$refs.subNavBars as Array<categoryPanelNavBarTabs>).forEach(snb =>
        snb.resetIndex()
      );
    }
  }
  get subLevelOpened(): boolean {
    if (this.categories == null || this.categories.length == 0) return false;

    var c = this.categoriesSorted[this.currentTabIndex];
    return this.hasSubcategories(c);
  }
  @Watch("currentTabIndex")
  onCurrentTabIndexChange(newValue, oldValue) {
    this.$emit(
      "levelChange",
      this.subLevelOpened ? this.level + 1 : this.level
    );
  }

  // get currentOpenedLevel(): number {
  //   if (this.subLevelOpened == false) return this.level;
  //   if (this.$refs.subNavBars != null) {
  //     var level = Math.max.apply(
  //       (this.$refs.subNavBars as Array<categoryPanelNavBarTabs>).map(x =>
  //         x == null ? this.level : x.currentOpenedLevel
  //       )
  //     );

  //     return level;
  //   }
  //   return this.level;
  // }
  // @Watch("currentOpenedLevel")
  // onCurrentOpenedLevel(newValue, oldValue) {
  //   this.$emit("levelChange", newValue);
  // }

  onLevelChange(level: number) {
    this.$emit("levelChange", this.subLevelOpened ? level : this.level);
  }

  get currentTabIndex() {
    return this.tabIndex == null ? 0 : this.tabIndex;
  }
  set currentTabIndex(index) {
    this.tabIndex = index;
  }
  @Prop({ default: () => 0 })
  level: number;
  get cssLevel() {
    return `level-${this.level}`;
  }
  get nextLevel() {
    return this.level + 1;
  }
  get hasBorderLeft() {
    return this.level < 2;
  }
  @Prop({ default: "" })
  title: string;
  @Prop({ default: () => new Array<categoryModel>() })
  categories: categoryModel[];
  get categoriesSorted(): categoryModel[] {
    var instance = this;
    var cat = [];
    if (instance.categories != null) {
      cat = instance.categories.sort((a, b) =>
        a.displayOrder > b.displayOrder ? 1 : -1
      );
    }
    return cat;
  }
  getPropery(category: categoryModel, property: string) {
    if (category == null || category.locales == null) return "";
    var l = category.locales["it-IT"];
    return l == null ? "" : l[property];
  }
  getImageUrl(category: categoryModel) {
    if (
      category == null ||
      category.media == null ||
      category.media.length == 0
    )
      return null;

    var media = category.media
      //.sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1))
      .find(x => x.mediaType == mediaType.Image && x.languageId == "it-IT");

    return media == null ? null : media.path;
  }
  hasSubcategories(category: categoryModel): boolean {
    return (
      category != null &&
      category.subCategories != null &&
      category.subCategories.length > 0
    );
  }
  get categoryId(): number {
    var instance = this;
    if (instance.$route.query && instance.$route.query.categoryId)
      return instance.$route.query.categoryId as any;
    return null;
  }
  onHover(c: categoryModel, index: number) {
    if (this.lg) this.tabIndex = index;
  }
  // onCategoryLeave(c: categoryModel) {
  //   if (this.categoryIdOver == c.id) this.categoryIdOver = null;
  // }
  // categoryIdOver: number = null;
  // get currentCategoryIdToShow(): number {
  //   if (this.categoryIdOver != null) {
  //     return this.categoryIdOver;
  //   }
  //   return null; // this.categoryId;
  // }
  onCategorySelected(c: categoryModel): void {
    var instance = this;
    instance.onCategoryClick(c);
  }
  onCategoryClick(c: categoryModel): void {
    var instance = this;
    if (
      instance.$route.name != "catalog" ||
      instance.$route.query.categoryId != (c == null ? null : c.id.toString())
    )
      instance.$router.push({
        name: "catalog",
        query: {
          categoryId: c == null ? null : c.id.toString()
        }
      });
  }
}

import Vue from "vue";
import moment from "moment";

Vue.filter("formatDate", function(value, format) {
  if (value) {
    if (format == null) return moment(String(value)).format("L");
    // if (format == "fullDate")
    //     return moment(String(value)).format('YYYY-MM-DD HH:mm');
    if (format == "fullDate") return moment(String(value)).format("L LT");
    if (format == "dayAndDate") return moment(String(value)).format("ddd L");
    if (format == "monthAndFullDate") return moment(String(value)).format("LLL");
    if (format == "monthAndDate") return moment(String(value)).format("LL");
    if (format == "dayStringMonthStringAndFullDate") return moment(String(value)).format("LLLL");
    if (format == "dayString") return moment(String(value)).format("ddd");
    if (format == "dayStringAndNumber")
      return moment(String(value)).format("ddd DD");
  }
  return "";
});

<template>
  <div>
    <!--<div id="sidebar" class="sidebar" v-bind:class="{ 'sidebar-transparent': pageOptions.pageSidebarTransparent }">
        <div class="height-full" v-scroll="handleScroll">
            <sidebar-nav v-bind:scrollTop="scrollTop"></sidebar-nav>
        </div>
    </div>
    <div class="sidebar-bg"></div>
    <div class="mobile-click" v-on:click="dismissSidebar"></div>-->
    <div id="sidebar" class="sidebar" data-disable-slide-animation="true" v-bind:style="{'padding-top': paddingTop}">
      <div data-scrollbar="true" data-height="100%">
        <sidebar-nav v-bind:scrollTop="scrollTop"></sidebar-nav>
      </div>
    </div>
    <div class="sidebar-bg"></div>
  </div>
</template>

<script>
import SidebarNav from "./SidebarNav.vue";
import SidebarNavProfile from "./SidebarNavProfile.vue";
import PageOptions from "../../config/PageOptions";

export default {
  name: "Sidebar",
  components: {
    SidebarNav,
    SidebarNavProfile
  },
  props: {
    top: String
  },
  data() {
    return {
      scrollTop: "",
      pageOptions: PageOptions
    };
  },
  computed: {
    paddingTop() {
      return this.top == null || this.top == "" ? "60px" : this.top;
    }
  },
  directives: {
    scroll: {
      inserted: function(el, binding) {
        let f = function(evt) {
          if (binding.value(evt, el)) {
            el.removeEventListener("scroll", f);
          }
        };
        el.addEventListener("scroll", f);

        if (typeof Storage !== "undefined") {
          if (localStorage.sidebarScroll) {
            el.scrollTop = localStorage.sidebarScroll;
          }
        }
      }
    }
  },
  methods: {
    handleScroll: function(evt) {
      this.scrollTop = evt.target.scrollTop;
      if (typeof Storage !== "undefined") {
        localStorage.setItem("sidebarScroll", this.scrollTop);
      }
    },
    dismissSidebar: function() {
      this.pageOptions.pageMobileSidebarToggled = false;
      this.pageOptions.pageMobileRightSidebarToggled = false;
    }
  }
};
</script>

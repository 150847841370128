import { productModel } from "./productModel";
import { addressModel } from "./addressModel";

export class ShoppingCartModel {
  //cartService: IShoppingCartService;
  constructor() { }

  id: string;

  // siteId: number;

  createDate: Date | string;
  createBy_Employee_Id: number | null;

  updateDate: Date | string;
  updateBy_Employee_Id: number | null;

  // customer: CartCustomerModel;

  shipping: addressModel[];

  // billingSameAsShipping: boolean;

  // billing: CartBillingInfoModel;

  products: CartProductModel[];

  notes: string;

  shippingsType: string;
  deliveryDate: Date | string | null;

  // orderId: number | null;
}
export class CartShippingInfoRequest {
  constructor() {
    this.shippingType = null;
    this.deliveryDate = null;
  }
  shippingType: string;
  deliveryDate: Date | string | null;
}
export class CartProductModel {
  constructor() {
    this.productId = null;
    this.masterProductId = null;
    this.sku = null;
    this.ean13 = null;
    this.isbn = null;
    this.code1 = null;
    this.code2 = null;
    this.code3 = null;
    this.code4 = null;
    this.code5 = null;
    this.name = null;
    this.image = null;
    this.quantity = null;
    this.product = null;
    this.taxPercentage = null;
    this.unitNetPrice = null;
    this.unitSellingPrice = null;
    this.unitDiscountPrice = null;
    this.rowTotal = null;
    this.createDate = null;
    this.updateDate = null;
  }
  productId: number;
  masterProductId: number | null;
  sku: string;
  ean13: string;
  isbn: string;
  code1: string;
  code2: string;
  code3: string;
  code4: string;
  code5: string;
  name: string;
  image: string;
  quantity: number;
  product: productModel;
  taxPercentage: number;
  unitNetPrice: number;
  unitSellingPrice: number;
  unitDiscountPrice: number | null;
  rowTotal: number | null;
  createDate: Date | string | null
  updateDate: Date | string | null
}

import Vue from "vue";

//thema app components
import Sidebar from "./components/sidebar/Sidebar.vue";
import SidebarRight from "./components/sidebar-right/SidebarRight.vue";
import TopMenu from "./components/top-menu/TopMenu.vue";
import Footer from "./components/footer/Footer.vue";

// plugins

//plugins

// customization


class thema_v2 {
  init = function (): void {


    Vue.component("Sidebar", Sidebar);
    Vue.component("SidebarRight", SidebarRight);
    Vue.component("TopMenu", TopMenu);
    Vue.component("Footer", Footer);

  };
}

export default new thema_v2();

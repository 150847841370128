// import Vue from 'vue'
import { Component, Watch, Prop } from "vue-property-decorator";
import axios, { AxiosPromise } from "axios";
import { pagedList, sortDirection } from "@/models/pagedList";
import baseUserContextComponent from "../baseUserContextComponent";
import { Permissions } from "../../AppConfig";
import { categoryModel } from "@/models/categoryModel";
import domainService from "@/services/domainService";
import { mediaType } from "@/models/mediaModel";

@Component({})
export default class topNav extends baseUserContextComponent {
    selectCustomerClick(){
        this.$emit('onSelectCustomerClick');
    }
      unselectCustomerClick(){
        this.$emit('onUnselectCustomerClick',null);
    }
}

// import Vue from 'vue'
import { Component, Watch, Prop, Mixins } from "vue-property-decorator";
import axios, { AxiosPromise } from "axios";
import {
  productModel,
  productType,
  productSelectorResult
} from "@/models/productModel";
import {
  iProductOption,
  optionModel,
  optionValueModel,
  optionAndSingleValueModel
} from "@/models/optionModel";
import domainService from "@/services/domainService";
import { familyOptionViewModel } from "@/models/familyOptionModel";
import baseUserContextProductComponent from "@/components/baseUserContextProductComponent";
import Vue from "vue";

@Component({})
export default class productSelectorConfigured extends baseUserContextProductComponent {
  // getSkusProducts!: (product: productModel | null) => productModel[];
  // getVariantCodes!: (product: productModel) => Array<string>;
  @Prop({ default: false })
  isLoading: boolean;
  @Prop({ default: false })
  markNotSelected: boolean;
  @Prop({ default: null })
  prefilledId: number;
  get prefilledItem(): productModel {
    var instance = this;
    if (instance.prefilledId == null)
      return null;
    var skus = instance.item == null || instance.item.skus == null ? [] : instance.item.skus;
    return skus.find(x => x.id == instance.prefilledId);
  }
  // @Watch("markNotSelected")
  // onMarkNotSelectedChange(newVal: boolean, old: boolean) {
  //   debugger;
  // }

  @Prop({ default: () => new productModel() })
  item: productModel;
  @Watch("item", { deep: true })
  onProductChange(newVal: any) {
    this.caricaFilters();
  }
  @Prop({ default: () => [] })
  selectedFilters: any[];
  @Watch("selectedFilters", { deep: true })
  onSelectedFiltersChange(newVal: any) {
    this.caricaFilters();
  }

  // selectedProduct: productModel = new productModel();

  ALL_FILTERS: optionModel[] = [];
  filters: optionModel[] = [];

  emitSelectedOptionFiltersChange() {
    var instance = this;
    var f = {};
    instance.filters.forEach(x => {
      var s = (x.values || []).find(v => v.selected);
      if (s != null) {
        f[x.code] = [s.code];
      }
    });
    instance.$emit('selectedOptions', f);
  }

  idtest: number = null;
  @Watch("products", { deep: true })
  onProductsFilteredChange(newVal: any) {
    var instance = this;
    var selectResult = new productSelectorResult();
    if (instance.allFiltersSelected == false) {
      selectResult.allFiltersSelected = false;
      selectResult.product = null;
      instance.$emit("select", selectResult);
      instance.idtest = -1;
    } else if (instance.products.length == 0) {
      selectResult.allFiltersSelected = true;
      selectResult.product = null;
      instance.$emit("select", selectResult);
      instance.idtest = -1;
    } else {
      selectResult.allFiltersSelected = true;
      selectResult.product = instance.products[0];
      instance.idtest = instance.products[0].id;
      instance.$emit("select", selectResult);
    }
    // if (instance.products.length == 1)
    //   instance.idtest = instance.products[0].id;
    // else instance.idtest = -1;

    // instance.$emit(
    //   "select",
    //   instance.products.length == 1 ? instance.products[0] : null
    // );
  }
  get products(): productModel[] {
    var instance = this;
    var filtered = instance.getSkusProducts(instance.item);

    for (var code in instance.activeFilterValues) {
      if (
        instance.activeFilterValues[code] != null &&
        instance.activeFilterValues[code].length > 0
      )
        filtered = filtered.filter(
          x => instance.activeFilterValues[code] == x.variantsValues[code]
          // x =>
          //   instance.activeFilterValues[code].indexOf(x.variantsValues[code]) !=
          //   -1
        );
    }

    return filtered;
  }

  // get skus(): string[] {
  //   var instance = this;
  //   if (instance.item == null) return [];

  //   var skus = [];
  //   if (instance.item.skus != null && instance.item.skus.length > 0) {
  //     instance.item.skus.forEach(p => {
  //       skus.push(p.sku);
  //       if (p.skus != null)
  //         p.skus.forEach(c => {
  //           skus.push(c.sku);
  //         });
  //     });
  //   }
  //   return skus;
  // }
  hasOptionValueSelected(v: familyOptionViewModel) {
    var instance = this;
    if (instance.filters == null || instance.filters.length == 0) return false;
    var f = instance.filters.find(x => x.code == v.code);

    if (f == null) return [];
    var values = f.values.filter(
      o =>
        instance
          .getSkusProducts(instance.item)
          .find(x => x.variantsValues[v.code] == o.code) != null
    );
    return values == null || values.length == 0
      ? false
      : values.find(x => x.selected == true) != null;
  }
  getOptionValueSelected(v: familyOptionViewModel) {
    var instance = this;
    var f = instance.filters.find(x => x.code == v.code);

    if (f == null) return [];
    var values = f.values.filter(
      o =>
        instance
          .getSkusProducts(instance.item)
          .find(x => x.variantsValues[v.code] == o.code) != null
    );
    if (values == null) return null;
    var s = values.find(x => x.selected);
    return s == null ? null : s.name;
  }
  getOptionValues(v: familyOptionViewModel): optionValueModel[] {
    var instance = this;
    var f = instance.filters.find(x => x.code == v.code);

    if (f == null) return [];
    var values = f.values.filter(
      o =>
        instance
          .getSkusProducts(instance.item)
          .find(x => x.variantsValues[v.code] == o.code) != null
    );
    return values == null
      ? []
      : values.sort((a, b) => (a.name > b.name ? 1 : -1));
  }
  onOptionClick(v: familyOptionViewModel, o: optionValueModel) {
    var instance = this;
    var f = instance.filters.find(x => x.code == v.code);
    if (f != null && f.values != null) {
      f.values.forEach(x => (x.selected = x.code == o.code));

      instance.settaFiltriValidi(v, o);
    }
    // o.selected = !o.selected;
    instance.emitSelectedOptionFiltersChange();
  }
  isOptionSelected(v: familyOptionViewModel, o: optionValueModel) {
    return o.selected;
  }
  // get activeFilters() {
  //   var instance = this;
  //   if (instance.filters == null) return [];
  //   var variantCodes = instance.getVariantCodes(instance.item);
  //   var validFilters = instance.filters.filter(
  //     x => variantCodes.indexOf(x.code) != -1
  //   );
  //   return validFilters.filter(
  //     x => x.values != null && x.values.find(o => o.selected) != null
  //   );
  // }
  get invalidConfiguration(): boolean {
    if (this.allFiltersSelected == false) return false;
    else return this.products == null || this.products.length == 0;
  }
  get allFiltersSelected(): boolean {
    var instance = this;
    if (instance.filters == null || instance.filters.length == 0) return false;
    return instance.filters.every((opt, index, filters) => {
      return opt.values.find(x => x.selected) != null;
    });
  }
  get activeFilterValues() {
    var instance = this;
    if (instance.filters == null) return [];
    var variantCodes = instance.filters.reduce((result, x) => {
      result[x.code] = x.values
        .filter(v => v.selected == true)
        .map(o => o.code);
      return result;
    }, {});
    return variantCodes;
  }

  onSelectFilter(f: optionAndSingleValueModel) {
    var instance = this;
    f.value.selected = !f.value.selected;
  }
  getSelectedOptions(filter: optionModel) {
    var instance = this;
    if (filter.values == null) return [];
    return filter.values.filter(x => x.selected);
  }
  beforeMount() {
    var instance = this;
    domainService.getOptinFilters().then(res => {
      if (res == null) instance.filters = [];
      else {
        instance.ALL_FILTERS = res.map(x => {
          // var selFilter = instance.selectedFilters[x.code];
          var f = new optionModel();
          f.code = x.code;
          f.name = x.name;
          f.type = x.type;
          f.languageId = x.languageId;
          f.values =
            x.values == null
              ? []
              : x.values.map(y => {
                var o = new optionValueModel();
                o.code = y.code;
                o.name = y.name;
                o.languageId = y.languageId;
                o.selected = false;
                // selFilter != null && selFilter.indexOf(y.code) != -1;
                return o;
              });
          return f;
        });

        instance.caricaFilters();
      }
    });
  }
  mounted() {
    var instance = this;
  }
  // Carico solo i filtri applicabili in base alle varianti dei prodotti
  caricaFilters() {

    var instance = this;
    if (
      instance.item == null ||
      instance.ALL_FILTERS == null ||
      instance.ALL_FILTERS.length == 0
    )
      instance.filters = [];
    else {
      instance.filters = [];
      var varinats = instance.getVariantDefinitions(instance.item);
      varinats.forEach(v => {
        var fs = instance.ALL_FILTERS.filter(x => x.code == v.code).map(x => x);
        if (fs != null && fs.length > 0) {
          var f = fs[0];
          var values = f.values.filter(
            o =>
              instance
                .getSkusProducts(instance.item)
                .find(x => x.variantsValues[v.code] == o.code) != null
          );
          f.values =
            values == null
              ? []
              : values.sort((a, b) => (a.name > b.name ? 1 : -1));
        }
        var setSelected = false;

        if (instance.prefilledItem != null) {
          var selCode = instance.prefilledItem.variantsValues[f.code];
          if (selCode) {
            f.values
              .filter(x => x.code == selCode)
              .forEach(x => (x.selected = true));
            setSelected = true;
          }
        }
        // se ho passato uno e un solo filtro per la variante (query string) lo applico
        if (setSelected == false && instance.selectedFilters != null) {
          var selFilter = instance.selectedFilters[f.code];
          if (selFilter != null && (selFilter as any).length == 1) {
            var selCode = selFilter[0];
            f.values
              .filter(x => x.code == selCode)
              .forEach(x => (x.selected = true));
            setSelected = true;
          }
        }
        if (setSelected == false && f.values.length == 1)
          f.values[0].selected = true;

        instance.filters.push(f);
      });
      varinats.forEach(v => {
        var opts = instance.getOptionValues(v);
        var o = opts.find(x => x.selected);
        if (o != null)
          instance.onOptionClick(v, o);
      });
      // imposto i filtri non settati con i valori dei prodotti filtrati (sono rodinati per top, prendo il primo con listino valorizzato)
      // if (instance.products != null && instance.products.length > 0) {
      //   var p =
      //     instance.products.find(
      //       x => x.listini != null && x.listini.length > 0
      //     ) || instance.products[0];
      //   instance.filters.forEach(x => {
      //     x.values.forEach(
      //       v => (v.selected = v.code == p.variantsValues[x.code])
      //     );
      //   });
      // }
      // instance.settaFiltriValidi();
    }
    instance.emitSelectedOptionFiltersChange();
    // instance.settaFiltriValidi();
  }
  // da migliorare ora non utilizzato
  settaFiltriValidi(fovm: familyOptionViewModel, ovm: optionValueModel) {
    var instance = this;
    var filtered = instance.getSkusProducts(instance.item).filter(p => p.variantsValues[fovm.code] == ovm.code);
    // var varinats = instance.getVariantDefinitions(instance.item);
    // varinats.forEach(v => {
    //   if (fovm.code != v.code) {
    //     let opts = instance.getOptionValues(v);
    //     opts.forEach(o => {
    //       Vue.set(o, 'isValidSelection', filtered.find(p => p.variantsValues[v.code] == o.code) != null);
    //     });
    //   }
    // });
    instance.filters.forEach(x => {
      if (fovm.code != x.code)
        x.values.forEach(v => {
          // Vue.set(v, 'isValidSelection', filtered.find(p => p.variantsValues[x.code] == v.code) != null);
          v.isValidSelection = filtered.find(p => p.variantsValues[x.code] == v.code) != null;

          // v.isValidSelection =
          //   instance.products.find(p => p.variantsValues[x.code] == v.code) !=
          //   null;
        });
    });
    // var f = instance.filters.find(x => x.code == v.code);
    // if (f != null && f.values != null) {
    //   f.values.forEach(x => (x.selected = x.code == o.code));

    //   instance.settaFiltriValidi(v, o);
    // }
    // var varinats = instance.getVariantDefinitions(instance.item);
    // varinats.forEach(v => {
    //   var fs = instance.ALL_FILTERS.filter(x => x.code == v.code).map(x => x);
    //   if (fs != null && fs.length > 0) {
    //     var f = fs[0];
    //     var values = f.values.filter(
    //       o =>
    //         instance
    //           .getSkusProducts(instance.item)
    //           .find(x => x.variantsValues[v.code] == o.code) != null
    //     );
    //     f.values =
    //       values == null
    //         ? []
    //         : values.sort((a, b) => (a.name > b.name ? 1 : -1));
    //   }
    //   var setSelected = false;

    //   // se ho passato uno e un solo filtro per la variante (query string) lo applico
    //   if (instance.selectedFilters != null) {
    //     var selFilter = instance.selectedFilters[f.code];
    //     if (selFilter != null && (selFilter as any).length == 1) {
    //       var selCode = selFilter[0];
    //       f.values
    //         .filter(x => x.code == selCode)
    //         .forEach(x => (x.selected = true));
    //       setSelected = true;
    //     }
    //   }
    //   if (setSelected == false && f.values.length == 1)
    //     f.values[0].selected = true;

    //   instance.filters.push(f);
    // });
  }
}

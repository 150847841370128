import { Component, Prop, Watch } from "vue-property-decorator";
import baseUserContextComponent from "../baseUserContextComponent";
import { orderDetailModel } from "@/models/orderDetailModel";
import { productModel } from "@/models/productModel";
import _ from "lodash";
import { sisasListiniModel } from "@/models/sisasPimListiniModel";
import { mediaType } from "@/models/mediaModel";

@Component({})
export default class productOrderThumbnail extends baseUserContextComponent {
  @Prop({ default: () => new orderDetailModel() })
  product: orderDetailModel;
  @Prop({ default: () => false })
  isLoadingListini: boolean;

  get mainProduct(): productModel {
    return this.product == null ? null : this.product.product;
  }

  get mainImagePath(): string {
    if (this.product == null) return null;
    if (this.product.product == null) return this.product.image;
    if (
      this.product.product.mainImage != null &&
      this.product.product.mainImage != ""
    )
      this.product.product.mainImage;

    var media =
      this.product.product.media == null
        ? null
        : this.product.product.media.find(x => x.mediaType == mediaType.Image);
    if (media != null) return media.path;
    return this.product.image;
  }
  getDetailUrl(product: orderDetailModel): string {
    if (product != null) return "/product/" + product.productId;
    return "";
  }
  get showDiscount(): boolean {
    var show = false;

    if (this.mainProduct != null && this.mainProduct.listini != null) {
      show =
        this.mainProduct.listini.find(o => {
          return o.sconto1 != null && o.sconto1 != 0;
        }) != null;
    }
    return show;
  }

  get ums() {
    var ums = [];
    // if (this.product != null) {
    //   var um1 = this.product.options.find(
    //     x => (x as any).optionCode == "ERP_UM_PRINCIPALE"
    //   );
    //   if (um1 != null) {
    //     var val = (um1 as any).value[0];
    //     ums.push(val);
    //   }
    //   var um2 = this.product.options.find(
    //     x => (x as any).optionCode == "ERP_UM_SECONDARIA"
    //   );
    //   if (um2 != null) {
    //     var val = (um2 as any).value[0];
    //     if (val != null && val != "") ums.push(val);
    //   }
    // }
    if (this.mainProduct != null && this.mainProduct.listini != null) {
      var bufferums = _.uniq(this.mainProduct.listini.map(x => x.um));
      if (bufferums != null) ums = bufferums;
    }
    return ums;
  }
  getTotal(um: string) {
    var instance = this;
    if (instance.product == null) return 0;
    var listino = instance.getListino(um);
    if (listino == null) return " -";

    var total = listino.prezzo;
    if (listino.sconto1 != null && listino.sconto1 != 0) {
      //var sconto1 = Math.abs(listino.sconto1);
      var sconto1 = listino.sconto1;
      var s = (total * sconto1) / 100;
      // total = total - s;
      total = total + s;
    }
    if (listino.sconto2 != null && listino.sconto2 != 0) {
      // var sconto2 = Math.abs(listino.sconto2);
      var sconto2 = listino.sconto2;
      var s = (total * sconto2) / 100;
      // total = total - s;
      total = total + s;
    }
    return total;
  }
  getListino(um: string): sisasListiniModel {
    if (this.mainProduct == null || this.mainProduct.listini == null)
      return null;

    var listino = this.mainProduct.listini.find(l => l.um == um);
    return listino;
  }
}

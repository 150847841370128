import { EmployeeLightModel } from "./employeeModels";
import { addressModel } from "./addressModel";
import { SisasCommercialCondiction } from "./sisasCommercialCondiction";

export class CustomerInfoModel {
  constructor() {
    this.id = null;
    this.sourceId = null;
    this.source = null;
    this.type = null;
    this.prefix = null;
    this.firstName = null;
    this.middleName = null;
    this.lastName = null;
    this.email = null;
    this.mobilePhone = null;
    this.phone1 = null;
    this.phone2 = null;
    this.company = null;
    this.vatCode = null;
    this.taxCode = null;
    this.billingAddresses = [];
    this.shippingAddresses = [];
    this.employees = [];

    this.condizioniCommerciali = new SisasCommercialCondiction();
  }

  id: number;
  sourceId: string;
  source: string;
  type: string;
  prefix: string;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  mobilePhone: string;
  phone1: string;
  phone2: string;
  company: string;
  vatCode: string;
  taxCode: string;
  billingAddresses: addressModel[];
  shippingAddresses: addressModel[];
  employees: EmployeeLightModel[];
  //extra info
  condizioniCommerciali: SisasCommercialCondiction;
}

export class CustomerFatturatoModel extends CustomerInfoModel {
  constructor() {
    super();
    this.fatturato = null;
  }
  fatturato: number;
}

import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import axios from "axios";
import { productSearchResult } from "@/models/productSearchModel";
import { pagedList } from "@/models/pagedList";
import { categoryModel } from "@/models/categoryModel";
import { productModel, productType } from "@/models/productModel";
import baseUserContextComponent from "../baseUserContextComponent";

@Component({})
export default class customerSuggestedProductsList extends baseUserContextComponent {
  @Prop()
  category: categoryModel;

  @Prop({ default: "Le migliori soluzioni" })
  title: string;

  @Prop({ default: "Sfoglia il catalogo" })
  link: string;

  @Watch("category", { immediate: true })
  onCategoryChanged(category: categoryModel) {
    var instance = this;

    instance.fetchCategory(category == null ? null : category.id);
  }

  onSelectProduct(product: productModel) {

    this.$emit('select', product);
  }
  isLoading: boolean = false;
  productsList: productModel[] = [];

  fetchCategory(categoryId: number): void {
    // if (!categoryId == null) return;

    var instance = this;
    instance.isLoading = true;

    axios
      .get<productSearchResult>(`/product/search?pageSize=12&pageIndex=0&orderField=name&direction=ASC&categoryId=${categoryId ? categoryId : '49'}&addFacets=false`)
      .then(res => {
        if (res.data && res.data.list) {
          instance.productsList = res.data.list.items;
        }
        instance.isLoading = false;
      })
      .catch(error => {
        console.error(error);
        instance.isLoading = false;
      });
    // }
  }
}
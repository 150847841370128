import { Component, Prop, Watch } from "vue-property-decorator";
import Vue from "vue";
import { pagedList } from "@/models/pagedList";
import baseUserContextComponent from './baseUserContextComponent';

@Component({})
export default class pager extends baseUserContextComponent {
  @Prop({ default: '' })
  title: string;
  @Prop({ default: null })
  elementId: string;

  @Prop({ default: null })
  totalCount: number;

  @Prop({ default: null })
  currentPage: number;

  @Prop({ default: null })
  totalPages: number;

  @Prop({ default: null })
  pageSize: number;

  @Prop({ default: () => new pagedList() })
  pagedList: pagedList<any>;


  @Prop({ default: null })
  type: string;

  // internalPage: number = 1;
  get internalPage(): number {
    return this.currentPageOrDefault;
  }
  defaultValues: any = {
    totalPages: 23,
    pageSize: 25,
    currentPage: 1,
    totalCount: 0
  };

  beforeMount() {
    //this.internalPage = this.currentPageOrDefault;
  }

  get currentPageOrDefault(): number {
    return this.currentPage != null
      ? this.currentPage
      : this.pagedList != null && this.pagedList.currentPage != null
        ? this.pagedList.currentPage
        : this.defaultValues.currentPage;
  }
  get totalCountOrDefault(): number {
    return this.totalCount != null
      ? this.totalCount
      : this.pagedList != null && this.pagedList.totalCount != null
        ? this.pagedList.totalCount
        : this.defaultValues.totalCount;
  }
  get totalPagesOrDefault(): number {
    return this.totalPages != null
      ? this.totalPages
      : this.pagedList != null && this.pagedList.totalPages != null
        ? this.pagedList.totalPages
        : this.defaultValues.totalPages;
  }
  get isFirstPage(): boolean {
    return this.internalPage === 1;
  }

  get isLastPage(): boolean {
    return this.internalPage === this.totalPages;
  }

  get pageLinksToShow() {
    var range = [];
    var rangeWithDots = [];
    var current = this.internalPage;
    var last = this.totalPagesOrDefault;

    var delta = 2,
      left = current - delta,
      right = current + delta + 1,
      // range = [],
      // rangeWithDots = [],
      l;

    for (let i = 1; i <= last; i++) {
      if (i == 1 || i == last || (i >= left && i < right)) {
        range.push(i);
      }
    }

    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push("...");
        }
      }
      rangeWithDots.push(i.toString());
      l = i;
    }

    return rangeWithDots;
  }

  turnPage(i: number): void {
    this.changePage(this.currentPageOrDefault + i);
  }

  changePage(i: number | string): void {
    var p = parseInt(i as string, 10);
    if (p < 1 || p > this.totalPagesOrDefault) {
      return;
    }
    // this.internalPage = p;
    // this.$emit("pageChanged", this.internalPage - 1);
    this.$emit("pageChanged", p - 1);
  }
  get firstElementIndex(): number {
    if (this.pagedList == null)
      return 0;
    var currentPage = this.pagedList.currentPage || 1;
    var pageSize = this.pagedList.pageSize || 0;
    return 1 + ((currentPage - 1) * pageSize)
  }
  get lastElementIndex(): number {
    if (this.pagedList == null || this.pagedList.items == null)
      return 0;
    return this.firstElementIndex + this.pagedList.items.length - 1;
  }
}

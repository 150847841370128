import { Component, Prop } from "vue-property-decorator";
import baseUserContextComponent from "../baseUserContextComponent";
// import SidebarMenu from "./SidebarMenu";
import PageOptions from "../../config/PageOptions";
import SidebarNavList from "./SidebarNavList.vue";
import { routes } from "../../router";

@Component({
  components: {
    SidebarNavList
  }
})
export default class sidebarNav extends baseUserContextComponent {
  //menus = SidebarMenu;
  pageOptions = PageOptions;
  @Prop({ default: "" })
  scrollTop: string;

  get menus() {

    // var filterd =routes
    // .filter(x => {
    //   console.log(this.hasAnyPermissions(x.permissions));
    //   return (
    //     x.meta != null &&
    //     x.meta.sideNavBar != null &&
    //     this.hasAnyPermissions(x.permissions)
    //   );
    // });

    // if(filterd != null && filterd.length > 0){


    // }
    var instance = this;

    return routes
      .filter(x => {
        return (
          x.meta != null &&
          x.meta.sideNavBar != null &&
          instance.hasAnyPermissions(x.permissions)
        );
      })
      .map(x => x.meta.sideNavBar)
      .sort((x, y) => {
        return x.order > y.order ? 1 : -1;
      });
  }
  handleCollapseOther(menu): void {
    for (var i = 0; i < this.menus.length; i++) {
      this.$refs.sidebarNavList[i].collapse(menu);
    }
  }
  handleSidebarMinify() {
    this.pageOptions.pageSidebarMinified = !this.pageOptions
      .pageSidebarMinified;
  }
}

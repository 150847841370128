export class productFeed
{
    constructor(){
        additional_image_link : "";
        categories: [];
        description : "";
        df_group_leader : null;
        df_grouping_id : "";
        dfid : "";
        dfscore : null;
        family : "";
        group_id : "";
        highlight : "";
        id : "";
        image_link : "";
        link : "";
        main_category : "";
        mpn : "";
        product_sku : "";
        product_normative : "";
        product_tags : "";
        product_image:"";
        title : "";
        type : "";
    }
    additional_image_link: string;
    categories: string[];
    description: string;
    df_group_leader: boolean;
    df_grouping_id: string;
    dfid: string;
    dfscore: number;
    family: string;
    group_id: string;
    highlight: any;
    id: string;
    image_link: string;
    link: string;
    main_category: string;
    mpn: string;
    product_normative: string;
    product_sku: string;
    product_tags: string;
    product_image: string;
    title: string;
    type: string;
}
import Vue from "vue";
import Router from "vue-router";
import homePage from "./pages/homePage.vue";
import loginPage from "./pages/loginPage.vue";
import cartPage from "./pages/cart/cartPage.vue";
import errorPage403 from "./pages/errors/errorPage403.vue";
import { Component } from "vue-property-decorator";
import { Permissions } from "./AppConfig";

Vue.use(Router);

Component.registerHooks([
  "beforeRouteEnter",
  "beforeRouteUpdate",
  "beforeRouteLeave"
]);

export interface IAppRouteMenuItem {
  path: string;
  icon: string;
  title: string;
  order: number;
}
export interface IAppRouteMeta {
  permissions: Permissions[];
  sideNavBar: IAppRouteMenuItem;
}
class test {
  prova: string;
}
export const routes = [
  {
    path: "/403",
    name: "forbidden",
    component: errorPage403,
    meta: { layout: "empty-layout", allowanonymous: true }
  },
  {
    path: "/",
    name: "home",
    component: homePage,
    meta: {
      sideNavBar: {
        path: "/",
        icon: "fa fa-th text-sisas",
        title: "Dashboard",
        order: 1
      },
      moduleKey: "dashboard"
    }
  },
  {
    path: "/cart",
    name: "cart",
    component: cartPage
  },
  {
    path: "/checkout",
    name: "checkout",
    component: () => import("./pages/checkout/checkoutPage.vue"),
    meta: { layout: "checkout-layout" }
  },
  {
    path: "/thankyou/:code",
    name: "thankyou",
    component: () => import("./pages/checkout/thankyouPage.vue"),
    meta: {
      moduleKey: "thankyou"
    }
  },
  {
    path: "/login",
    name: "login",
    component: loginPage,
    meta: { layout: "empty-layout", allowanonymous: true }
  },
  {
    path: "/orders",
    name: "orders",
    component: () => import("./pages/orders/ordersPage.vue"),
    meta: {
      sideNavBar: {
        path: "/orders",
        icon: "fa fa-clipboard text-sisas",
        title: "Ordini",
        order: 2
      },
      moduleKey: "orders"
    }
  },
  {
    path: "/order/:code",
    name: "order",
    component: () => import("./pages/orders/orderPage.vue"),
    meta: {
      moduleKey: "orders"
    }
  },
  {
    path: "/employees",
    name: "employees",
    permissions: [Permissions.B2BADMISTRATOR, Permissions.REF_EMPLOYEE],
    component: () => import("./pages/employees/employeesPage.vue"),
    meta: {
      sideNavBar: {
        path: "/employees",
        icon: "fa fa-user-tie text-sisas",
        title: "Agenti",
        order: 6
      },
      moduleKey: "employees"
    }
  },
  {
    path: "/employee/:id",
    name: "employee",
    permissions: [Permissions.B2BADMISTRATOR, Permissions.REF_EMPLOYEE],
    component: () => import("./pages/employees/employeePage.vue"),
    meta: {
      moduleKey: "employees"
    }
  },
  {
    path: "/customers",
    name: "customers",
    permissions: [
      Permissions.B2BADMISTRATOR,
      Permissions.REF_EMPLOYEE,
      Permissions.EMPLOYEE
    ],
    component: () => import("./pages/customers/customersPage.vue"),
    meta: {
      sideNavBar: {
        path: "/customers",
        icon: "fa fa-address-book text-sisas",
        title: "Clienti",
        order: 5
      },
      moduleKey: "customers"
    }
  },
  {
    path: "/customer/:id",
    name: "customer",
    permissions: [
      Permissions.B2BADMISTRATOR,
      Permissions.REF_EMPLOYEE,
      Permissions.EMPLOYEE
    ],
    component: () => import("./pages/customers/customerPage.vue"),
    meta: {
      moduleKey: "customers"
    }
  },
  {
    path: "/catalog/:categoryId?",
    name: "catalog",
    component: () => import("./pages/catalogPage.vue"),
    meta: {
      sideNavBar: {
        path: "/catalog",
        icon: "fa fa-list text-sisas",
        title: "Catalogo",
        order: 3
      },
      moduleKey: "catalog"
    }
  },
  {
    path: "/inserimento-prodotti",
    name: "inserimento-prodotti",
    component: () => import("./pages/multiInsertPage.vue")
  },
  {
    path: "/categories/:id?",
    name: "categories",
    component: () => import("./pages/categoriesPage.vue"),
    meta: {
      moduleKey: "catalog"
    }
  },
  {
    path: "/product/:categoryId?/:id",
    name: "product",
    component: () => import("./pages/productPage.vue"),
    meta: {
      moduleKey: "catalog"
    }
  },
  {
    path: "/my-area/:section?",
    name: "my-area",
    component: () => import("./pages/myArea.vue"),
    meta: {
      moduleKey: "my-area"
    }
  },
  {
    path: "/expert-on-demand",
    name: "expert-on-demand",
    component: () => import("./pages/expertOnDemand.vue"),
    meta: {
      moduleKey: "assistenza"
    }
  },
  {
    path: "/chi-siamo",
    name: "chi-siamo",
    component: () => import("./pages/chi-siamoPage.vue"),
    meta: {
      moduleKey: "chi-siamo"
    }
  },
  {
    path: "/assistenza",
    name: "assistenza",
    component: () => import("./pages/assistenzaPage.vue"),
    meta: {
      moduleKey: "assistenza"
    }
  },
  {
    path: "/faq",
    name: "faq",
    component: () => import("./pages/faqPage.vue"),
    meta: {
      moduleKey: "faq"
    }
  },
  {
    path: "/faq/:id",
    name: "faqDetail",
    component: () => import("./pages/faqDetailPage.vue"),
    meta: {
      moduleKey: "faq"
    }
  }
];

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes
});

























import baseUserContextComponent from "@/components/baseUserContextComponent";
import { CustomerInfoModel } from "@/models/customerModels";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component({})
export default class defaultLayout extends baseUserContextComponent {
	showCustomerModal: boolean = false;
	showContent: boolean = false;
	onSelectCustomer(customer: CustomerInfoModel) {
		var instance = this;
		instance.setCurrentCustomer(customer);
		instance.showCustomerModal = false;
	}
	@Watch("$route")
	onRouteChange(to, from) {
		var instance = this;
		if (to.name != from.name) {
			// setTimeout(() => {
			// 	var element = document.getElementById("app-layout-content");
			// 	element.scrollIntoView();
			// 	// element.scrollIntoView({ behavior: "smooth", block: "start" });
			// }, 10);
			// debugger;
			// instance.showContent = false;
			// let element = document.getElementById("app-layout-content");
			// debugger;
			// element.scrollIntoView({ behavior: "smooth", block: "start" });
			//(instance.$refs.applayoutcontent as any).$el.scrollIntoView();
			// setTimeout(() => {
			// 	instance.showContent = true;
			// }, 10);
			// instance.$nextTick(() => {
			// 	setTimeout(() => {
			// 		instance.showContent = true;
			// 	}, 10);
			// });
		}
	}
	created() {
		// debugger;
		var instance = this;
		instance.showContent = true;
	}
}

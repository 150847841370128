import { Component, Prop, Watch } from "vue-property-decorator";
import baseUserContextComponent from "../baseUserContextComponent";
import { categoryModel } from "@/models/categoryModel";
import { facetModel } from "@/models/facetModel";
@Component({})
export default class categoryListGroupItem extends baseUserContextComponent {
  @Prop({ default: () => new categoryModel() })
  category: categoryModel;
  @Prop({ default: null })
  selectedId: number | null;
  @Prop({ default: "first-level" })
  type: string | null;
  @Prop({ default: false })
  isLast: boolean | null;

  @Prop({ default: () => [] })
  facets: facetModel[];

  showSubCategoriesPanel: boolean = false;
  // get items(): categoryModel[] {
  //   var instace = this;
  //   if (instace.categories == null || instace.categories.length == 0) return [];
  //   var result = instace.categories;
  //   return result;
  // }

  getCategoryPropery(category: categoryModel, property: string) {
    if (category == null || category.locales == null) return "";
    var l = category.locales["it-IT"];
    return l == null ? "" : l[property];
  }
  isCategorySelected(c: categoryModel): boolean {
    var instace = this;
    return (
      c != null &&
      (c.id == instace.selectedId ||
        this.isAnyChildrenCategorySelected(c.subCategories))
    );
  }
  isAnyChildrenCategorySelected(categories: categoryModel[]): boolean {
    var instance = this;
    if (instance.selectedId == null) return false;
    if (categories == null || categories.length == 0) return false;
    else {
      return (
        categories.find(
          x =>
            x.id == instance.selectedId ||
            this.isAnyChildrenCategorySelected(x.subCategories)
        ) != null
      );
    }
  }
  getFacetCategory(category: categoryModel): number {
    var instace = this;
    if (instace.facets == null) return null;
    var f = instace.facets.find(x => (x.code = "CategoryId"));
    if (f == null) return null;
    var fc = f.values[category.id];
    return fc == null ? 0 : fc;
  }
}

import { mediaModel } from "./mediaModel";

export class categoryModel {
  constructor() {
    this.id = null;
    this.siteId = null;
    this.parentId = null;
    this.locales = {};
    this.subCategories = new Array<categoryModel>();
    this.media = [];
    this.displayOrder = null;
  }
  id: number;

  siteId: number;

  parentId: number | null;

  locales: { [key: string]: categoryLocaleModel };

  subCategories: categoryModel[];

  media: mediaModel[];

  displayOrder: number;
}

export class categoryLocaleModel {
  constructor() {
    this.id = null;
    this.name = null;
    this.description = null;
  }
  id: number;

  name: string;

  description: string;

  //metatitle: string;

  //metadescription: string;

  //metakeywords: string;

  //friendlyUrl: string;
}

import { Component, Prop, Watch } from "vue-property-decorator";
import axios, { AxiosPromise } from "axios";
import eventHub from "../../eventHub";
import {
  ShoppingCartModel,
  CartProductModel
} from "@/models/shoppingCartModel";
import baseUserContextProductComponent from "../baseUserContextProductComponent";
@Component({})
export default class cartNavBarProductListItem extends baseUserContextProductComponent {
  @Prop({ default: () => [] })
  products: CartProductModel[];
  get product(): CartProductModel {
    return this.products == null || this.products.length == 0
      ? new CartProductModel()
      : this.products[0];
  }

  get totalQuantity(): number {
    if (this.products == null || this.products.length == 0)
      return 0;
    return this.products.reduce((a, b) => a + b.quantity, 0);
  }
  // @Watch("product", { deep: true })
  // onProductChange(val: CartProductModel, oldVal: CartProductModel) {
  //   this.note = this.product.code2;
  //   this.quantity = this.product.quantity;
  // }

  isInEditQuantity: boolean = false;
  isSendingQuantity: boolean = false;
  isInEditNote: boolean = false;
  isSendingNote: boolean = false;
  isSendingDelete: boolean = false;
  note: string = null;
  quantity: number = null;

  get discountDescription(): string {
    var instance = this;
    if (
      instance.getCurrentCustomer != null &&
      instance.product != null &&
      instance.product.code3 != null &&
      instance.product.code3 != "" &&
      (instance.product.code3 as any) != 0
    ) {
      if (instance.getCurrentCustomer.type == "Company")
        return `Prezzo riservato ${instance.getCurrentCustomer.company}`;
      return `Prezzo riservato ${instance.getCurrentCustomer.lastName}`;
    }
    return "";
  }
  setEditNote() {
    this.isInEditNote = true;
    this.$emit("edit", this.isInEditQuantity || this.isInEditNote);
  }
  canelEditNote() {
    this.isInEditNote = false;
    this.note = this.product.code2;
    this.$emit("edit", this.isInEditQuantity || this.isInEditNote);
  }
  confirmEditNote() {
    var instance = this;
    instance.isSendingNote = true;
    axios
      .post<ShoppingCartModel>(`cart/addproduct`, {
        customerId: instance.getCurrentCustomerId,
        productId: this.product.productId,
        quantity: this.product.quantity,
        um: this.product.code1,
        note: this.note
      })
      .then(res => {
        instance.setCurrentCart(res.data);
        instance.$notify({
          title: "",
          text: "Le note sono state modificate",
          type: "success",
          group: "msg"
        });

        instance.isSendingNote = false;
        instance.isInEditNote = false;
        instance.$emit("edit", this.isInEditQuantity || this.isInEditNote);
      })
      .catch(error => {
        console.error(error);
        instance.isSendingNote = false;
        instance.canelEditNote();
      });
  }
  setEditQuantity() {
    this.isInEditQuantity = true;
    this.$emit("edit", this.isInEditQuantity || this.isInEditNote);
  }
  canelEditQuantity() {
    this.isInEditQuantity = false;
    this.quantity = this.product.quantity;
    this.$emit("edit", this.isInEditQuantity || this.isInEditNote);
  }
  confirmEditQuantity() {
    var instance = this;
    instance.isSendingQuantity = true;
    axios
      .post<ShoppingCartModel>(`cart/addproduct`, {
        customerId: instance.getCurrentCustomerId,
        productId: this.product.productId,
        quantity: this.quantity,
        um: this.product.code1,
        note: this.product.code2
      })
      .then(res => {
        instance.setCurrentCart(res.data);
        instance.$notify({
          title: "",
          text: "Carrello aggiornato correttamente",
          type: "success",
          group: "msg"
        });

        instance.isSendingQuantity = false;
        instance.isInEditQuantity = false;
        instance.$emit("edit", this.isInEditQuantity || this.isInEditNote);
      })
      .catch(error => {
        console.error(error);
        instance.isSendingQuantity = false;
        instance.canelEditQuantity();
      });
  }
  get cart(): ShoppingCartModel {
    return this.getCurrentCart;
  }
  getDetailUrl(): string {
    return "/product/" + this.product.productId;
  }
  onRemove() {
    var instance = this;
    this.isSendingDelete = true;
    //axios
    // .post<ShoppingCartModel>(`cart/addproduct`, {
    //   customerId: instance.getCurrentCustomerId,
    //   productId: this.product.productId,
    //   quantity: 0,
    //   um: this.product.code1,
    //   note: this.product.code2
    // })
    var name = instance.product.name;
    axios
      .get(
        `cart/removeProduct?customerId=${instance.getCurrentCustomerId}&productId=${this.product.productId}`
      )
      .then(res => {
        instance.setCurrentCart(res.data);
        instance.$notify({
          title: "",
          text: `${name} è stato rimosso dal carrello`,
          type: "success",
          group: "msg"
        });
        this.isSendingDelete = false;
      })
      .catch(error => {
        console.error(error);
        this.isSendingDelete = false;
      });
  }

  getProporzioneProductUm(item: CartProductModel, um: string): string {
    var f = null;
    if (item != null && item.product != null)
      return this.getProporzioneUm(item.product, um);
    return "";
  }
  // arrows + Backspace
  allowKeyCodes = [37, 38, 39, 40, 8];
  allowKeys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
  onKeyDown($event: KeyboardEvent) {
    if (
      this.allowKeyCodes.indexOf($event.keyCode) == -1 &&
      this.allowKeys.indexOf($event.key) == -1
    ) {
      $event.preventDefault();
    }
  }
  increase() {
    if (this.quantity == null) this.quantity = 1;
    else this.quantity++;
  }
  decrease() {
    if (this.quantity == null) this.quantity = 1;
    else this.quantity--;
  }

  mounted() {
    this.note = this.product.code2;
    this.quantity = this.product.quantity;
  }
}

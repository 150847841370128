import { Component } from "vue-property-decorator";
import Vue from "vue";
import axios from "axios";
import { EmployeeModel } from "@/models/employeeModels";

@Component({})
export default class dashboardEmployee extends Vue {
  item: EmployeeModel = new EmployeeModel();

  isLoading: boolean = false;
  fetch(): void {
    var instance = this;
    instance.isLoading = true;
    axios
      .get<EmployeeModel>("employee/me")
      .then(res => {
        instance.item = res.data ? res.data : new EmployeeModel();
        instance.isLoading = false;
      })
      .catch(error => {
        console.error(error);
        instance.isLoading = false;
      });
  }
  mounted() {
    var instance = this;

    instance.fetch();
  }
}





















































































































































































































































































































































































































import { Component, Watch } from "vue-property-decorator";
import { mapActions, mapMutations } from "vuex";
import axios, { AxiosPromise } from "axios";
import baseUserContextPage from "@/pages/baseUserContextPage";
import { pagedList } from "@/models/pagedList";
import { productModel, productType } from "@/models/productModel";
import { productFeed } from "@/models/productFeed";
import { ShoppingCartModel } from "@/models/shoppingCartModel";
import { CustomerInfoModel } from "@/models/customerModels";
import { productSearchResult } from "@/models/productSearchModel";
import { facetModel } from "@/models/facetModel";
import { categoryModel } from "@/models/categoryModel";
import domainService from "@/services/domainService";
import { sisasListiniModel } from "@/models/sisasPimListiniModel";
import _ from "lodash";
import Vue from "vue";
import headerLogo from "./header-logo.vue";

@Component({
	components: { headerLogo },
	methods: {
		...mapActions(["logoutUser"]),
		...mapMutations(["setShowInfoCustomerTopBar", "setShowBreadcrumbsCategories"])
	}
})
export default class headerDefault extends baseUserContextPage {
	showAddToCartModal: boolean = false;
	// showPanelUser: boolean = false;
	// onClickOutsideUserPanel() {
	//   debugger;
	//   this.showPanelUser = false;
	// }
	// onShowUserPanel() {
	//   debugger;
	//   this.showPanelUser = true;
	// }

	selectedProduct: productModel = null;
	isLoadingListini: boolean = false;
	openSelectAfterCustomer: boolean = false;
	get productsOnCart(): number {
		if (this.cart == null || this.cart.products == null) return null;

		return _.uniq(_.map(this.cart.products, "sku")).length;
	}
	@Watch("$route")
	onRouteChange(to, from) {
		var instance = this;
		instance.showSearchPanel = false;
		instance.showSearchProductModal = false;

		instance.resetSearch();
	}
	@Watch("showAddToCartModal")
	onShowAddToCartModalChange(newValue, oldValue) {
		// debugger;
		var instance = this;
		if (newValue == false) instance.openSelectAfterCustomer = false;
	}
	resetSearch() {
		var instance = this;
		instance.productSearch = null;
		instance.facets = [];
		instance.list = new pagedList();
	}
	hideSearchPanel() {
		var instance = this;
		// debugger;
		// setTimeout(() => {
		//   instance.showSearchPanel = false;
		// }, 300);
		Vue.set(instance, "showSearchPanel", false);
		// instance.showSearchPanel = false;
		// instance.$nextTick(function () {
		//   instance.showSearchPanel = false;
		// });
	}
	//fatchCall: any = null;

	// get productsRows() {
	//   var instance = this;
	//   return _.chunk(instance.list.items, 3);
	// }
	showSearchProductModal: boolean = false;
	focusInputSearchElement() {
		var instance = this;

		(instance.$refs.modalSearchInput as any).focus();
	}
	openSearchProductModal() {
		var instance = this;
		instance.showSearchProductModal = true;
	}
	closeSearchProductModal() {
		var instance = this;
		// instance.productSearch = null;
		instance.showSearchProductModal = false;
	}
	isoverserarch: boolean = false;
	list: pagedList<productModel> = new pagedList();
	get productsList() {
		var instance = this;
		return instance.list == null ? [] : instance.list.items || [];
	}
	get productsCount() {
		var instance = this;
		return instance.list == null ? 0 : instance.list.totalCount || 0;
	}
	facets: facetModel[] = [];
	setShowInfoCustomerTopBar!: (show: boolean) => void;
	get showInfoCustomerTopBar(): boolean {
		return this.getShowInfoCustomerTopBar;
	}
	set showInfoCustomerTopBar(show: boolean) {
		this.setShowInfoCustomerTopBar(show);
	}
	setShowBreadcrumbsCategories!: (show: boolean) => void;
	get showBreadcrumbsCategories(): boolean {
		return this.getShowBreadcrumbsCategories;
	}
	set showBreadcrumbsCategories(show: boolean) {
		this.setShowBreadcrumbsCategories(show);
	}

	stat: string = "";
	isLoadingProducts: boolean = false;
	showCustomerModal: boolean = false;
	show: boolean = false;
	productSearch: string = "";
	items: productFeed[] = [];
	slimscrollOption = {
		height: "auto"
	};

	get cart(): ShoppingCartModel {
		return this.getCurrentCart;
	}

	get isCustomerSelected(): boolean {
		return this.getCurrentCustomer != null;
	}
	get showCustomerDescription(): boolean {
		return this.isUserB2BAdmim || this.isUserRefEmployee || this.isUserEmployee;
	}
	get customerDescription(): string {
		var instance = this;
		if (instance.getCurrentCustomerId == null || instance.getCurrentCustomer == null) return "";
		if (instance.getCurrentCustomer.type == "Company") return instance.getCurrentCustomer.company;
		return instance.getCurrentCustomer.lastName;
	}
	onSelectCustomer(customer: CustomerInfoModel) {
		var instance = this;
		instance.setCurrentCustomer(customer);
		instance.showCustomerModal = false;
		// this.fetchProducts();
		instance.fetchListini().then(res => {
			if (instance.selectedProduct != null && instance.openSelectAfterCustomer) instance.showAddToCartModal = true;
		});
	}
	onSelectProduct(p: productModel) {
		var instance = this;
		instance.selectedProduct = p;

		//if(true){
		// instance.productSearch = instance.selectedProduct.sku;
		// instance.checkForm(null);
		//}

		if (instance.getCurrentCustomer == null) {
			instance.showCustomerModal = true;
			instance.openSelectAfterCustomer = true;
		} else {
			// instance.showAddToCartModal = true;
			instance.fetchListini().then(res => {
				instance.showAddToCartModal = true;
			});
		}
	}
	getDetailUrl(product: productFeed): string {
		return "/product/" + product.id;
	}
	logoutUser: () => AxiosPromise<Boolean>;

	expand() {
		this.stat = this.stat == "expand" ? "collapse" : "expand";
	}
	onClearCache() {
		var instance = this;
		axios
			.get<string>(`tests/cache/reset`)
			.then(res => {
				if (res != null && res.data == "OK")
					instance.$notify({
						title: "",
						text: "cache svuotata",
						type: "success",
						group: "msg"
					});
				else
					instance.$notify({
						title: "",
						text: "cache non svuotata",
						type: "warning",
						group: "msg"
					});
			})
			.catch(error => {
				console.error(error);
				instance.$notify({
					title: "",
					text: "cache non svuotata",
					type: "warning",
					group: "msg"
				});
			});
	}
	onLogout() {
		var instance = this;
		return new Promise((result, reject) => {
			instance
				.logoutUser()
				.then(res => {
					instance.$router.push({ name: "login" });
					result(true);
				})
				.catch(error => {
					console.error(error);
					reject();
				});
		});
	}
	toggleMobileSidebar() {
		this.pageOptions.pageMobileSidebarToggled = !this.pageOptions.pageMobileSidebarToggled;
	}
	toggleMobileRightSidebar() {
		this.pageOptions.pageMobileRightSidebarToggled = !this.pageOptions.pageMobileRightSidebarToggled;
	}
	toggleMobileTopMenu() {
		this.pageOptions.pageMobileTopMenu = !this.pageOptions.pageMobileTopMenu;
	}
	toggleMobileMegaMenu() {
		this.pageOptions.pageMobileMegaMenu = !this.pageOptions.pageMobileMegaMenu;
	}
	toggleRightSidebar() {
		this.pageOptions.pageRightSidebarToggled = !this.pageOptions.pageRightSidebarToggled;
	}
	handleSidebarMinify() {
		this.pageOptions.pageSidebarMinified = !this.pageOptions.pageSidebarMinified;
	}
	get showResult(): boolean {
		return this.show;
	}
	checkForm(e: Event) {
		if (e != null && e.preventDefault != null) e.preventDefault();
		// if (
		//   this.$refs.searchInput != null &&
		//   (this.$refs.searchInput as any).$el != null
		// )
		//   (this.$refs.searchInput as any).$el.focus();
		// this.onKeyUp(null);
		if (this.productSearch != null && this.productSearch != "") {
			var search = this.productSearch;
			// this.productSearch = "";
			this.$router.push({
				name: "catalog",
				query: {
					search: search
				}
			});
			this.showSearchPanel = false;
		}
		//this.show = true;
	}
	timeoutFunction: any = null;
	allowKeyCodes = [144, 33, 34, 35, 36, 37, 38, 39, 40, 45, 9, 16, 17, 20]; //8,
	onKeyUp($event: KeyboardEvent) {
		if ($event != null && this.allowKeyCodes.indexOf($event.keyCode) != -1) return;

		if ($event != null && $event.keyCode == 13) return this.checkForm($event);
		// console.log(this.searchParams.search);
		// if (
		//   this.searchParams.search != null &&
		//   (this.searchParams.search as string).length > 2
		// ) {
		//   // this.fetch();
		//   // this.show = true;
		// }
		// var instance = this;
		// setTimeout(() => {
		//   instance.checkForm(null);
		// }, 300);

		var instance = this;
		if (instance.timeoutFunction) {
			clearTimeout(instance.timeoutFunction);
		}
		instance.timeoutFunction = setTimeout(() => {
			instance.fetchSearch();
			// console.log("onSearchKeyUp");
		}, 500);
	}
	onSearchChange($event: KeyboardEvent) {
		// console.log(this.searchParams.search);
	}
	onBlur() {
		// var instance = this;
		// setTimeout(() => {
		//   instance.show = false;
		//   instance.searchParams.search = "";
		//   instance.list = new pagedList();
		// }, 500);
	}
	close() {
		var instance = this;
		setTimeout(() => {
			instance.show = false;
			// instance.searchParams.search = "";
			// instance.list = new pagedList();

			instance.productSearch = "";
			instance.items = [];
		}, 300);
	}
	_searchKey: string = "";
	fetchSearch(): void {
		// var instance = this;
		// instance.isLoadingProducts = true;
		// var CancelToken = axios.CancelToken;
		// if (instance.fatchCall)
		//   instance.fatchCall.cancel("Operation canceled by the user.");
		// instance.fatchCall = CancelToken.source();
		// axios
		//   .get<pagedList<productModel>>("/product/find", {
		//     params: instance.searchParams
		//     // cancelToken: instance.fatchCall.token
		//   })
		//   .then(res => {
		//     instance.list = res.data ? res.data : new pagedList();
		//     instance.isLoadingProducts = false;
		//   })
		//   .catch(error => {
		//     console.error(error);
		//     instance.isLoadingProducts = false;
		//   });
		var instance = this;
		instance.isLoadingProducts = true;
		// productSearchService
		//   .search(instance.productSearch)
		//   .then(res => {
		//     instance.items = res ? res : [];
		//     instance.isLoadingProducts = false;
		//     this.show = true;
		//   })
		//   .catch(error => {
		//     console.error(error);
		//     instance.isLoadingProducts = false;
		//   });
		if (instance.productSearch != null) {
			instance._searchKey = Date.now().toString();
			// console.log(`ricerca: ${instance._searchKey}`);
			var p = {
				search: instance.productSearch,
				searchKey: instance._searchKey,
				pageSize: 5,
				customerId: instance.getCurrentCustomerId,
				addFacets: false
			};
			// p.searchKey = instance._searchKey;
			axios
				.get<productSearchResult>("/product/search", {
					params: p
					// paramsSerializer: function(params) {
					//   // Qs is already included in the Axios package
					//   return Qs.stringify(params, {
					//     arrayFormat: "none"
					//   });
					// }
				})
				.then(res => {
					if (res.data && res.data.searchKey == instance._searchKey) {
						instance.list = res.data && res.data.list ? res.data.list : new pagedList();
						instance.facets = res.data && res.data.facets ? res.data.facets : [];

						instance.isLoadingProducts = false;
					}
				})
				.catch(error => {
					console.error(error);
					instance.isLoadingProducts = false;
				});
		}
	}
	get skus(): string[] {
		var instance = this;
		if (instance.list == null || instance.list.items == null || instance.list.items.length == 0) return [];

		var skus = [];

		instance.list.items.forEach(p => {
			skus.push(p.sku);
			if (p.skus != null)
				p.skus.forEach(c => {
					skus.push(c.sku);
				});
		});

		return skus;
	}
	get selectedProductSkus(): string[] {
		var instance = this;
		if (instance.selectedProduct == null) return [];

		var skus = [];
		skus.push(instance.selectedProduct.sku);
		if (instance.selectedProduct.skus != null)
			instance.selectedProduct.skus.forEach(c => {
				skus.push(c.sku);
			});

		return skus;
	}
	fetchListini(): Promise<boolean> {
		var instance = this;
		instance.isLoadingListini = true;

		return new Promise((resolve, reject) => {
			// if (instance.skus != null && instance.skus.length > 0) {
			if (instance.selectedProductSkus != null && instance.selectedProductSkus.length > 0) {
				axios
					.post<sisasListiniModel[]>("/product/listini", {
						customerId: this.getCurrentCustomerId,
						// skus: instance.skus
						skus: instance.selectedProductSkus
					})
					.then(res => {
						if (res.data) {
							instance.list.items.forEach(p => {
								p.listini = res.data.filter(x => x.articolo == p.sku);
								if (p.skus != null)
									p.skus.forEach(c => {
										c.listini = res.data.filter(x => x.articolo == c.sku);
									});
							});
						}
						instance.isLoadingListini = false;
						resolve(true);
					})
					.catch(error => {
						console.error(error);
						instance.isLoadingListini = false;
						reject(error);
					});
			} else {
				resolve(false);
			}
		});
	}
	showSearchPanel: boolean = false;
	// get showSearchPanel(): boolean {
	//   var instance = this;
	//   //return true;
	//   return (
	//     instance.isoverserarch &&
	//     instance.productSearch != null &&
	//     instance.productSearch.length > 0
	//   );
	// }
	get specificoId(): number {
		var instance = this;
		if (instance.productSearch && instance.list.totalCount == 1 && instance.list.items[0].type == productType.Configurable) {
			var p = instance.list.items[0];
			var params = instance.productSearch.split(" ") || [];

			var children = (p.skus || []).filter(x => (params as Array<string>).find(p => p == x.sku) != null);
			if (children && children.length == 1) {
				var p = children[0];
				return p.id;
			}
		}
		return null;
	}
	getProductTitle_old(product: productModel): string {
		if (product == null) return "";
		if (product.type != productType.Configurable) return product.sku;
		return `(${product.skus.length} Prodotti)`;
	}
	getProductTitle(product: productModel): string {
		if (product == null) return "";
		return `Codice ${product.sku}`;
	}
	getProductVariantDescription(product: productModel): string {
		if (product == null) return "";
		if (product.type != productType.Configurable) return "";
		return `Diponibile in ${product.skus.length} versioni`;
	}
	getHasVariant(product: productModel): boolean {
		if (product == null) return false;
		if (product.type != productType.Configurable) return false;
		return product.skus.length > 0;
	}
	onProductClick(product: productModel) {
		var instance = this;

		// instance.productSearch = null;
		if (instance.showSearchProductModal == true) instance.closeSearchProductModal();

		if (instance.specificoId != null) {
			instance.$router.push({
				path: `/product/${instance.specificoId}`
				// query: {
				//   categoryId: c == null ? null : c.id.toString()
				// }
			});
		} else {
			instance.$router.push({
				path: `/product/${product.id}`
				// query: {
				//   categoryId: c == null ? null : c.id.toString()
				// }
			});
		}
	}
	onCategoryClick(c: categoryModel) {
		var instance = this;
		if (c != null)
			instance.$router.push({
				name: "catalog",
				query: {
					search: instance.productSearch,
					categoryId: c == null ? null : c.id.toString()
				}
			});
		else
			instance.$router.push({
				name: "catalog",
				query: {
					search: instance.productSearch
				}
			});
		// instance.productSearch = null;
	}

	categories: categoryModel[] = [];
	getCount(c: categoryModel): number | null {
		var instance = this;
		if (instance.facets == null || c == null) return 0;
		var f = instance.facets.find(x => x.code == "CategoryId");
		if (f == null) return 0;
		var count = f.values[c.id];
		return count != null ? count : 0;
	}
	getPropery(category: categoryModel, property: string) {
		if (category == null || category.locales == null) return "";
		var l = category.locales["it-IT"];
		return l == null ? "" : l[property];
	}
	beforeMount() {
		var instance = this;
		domainService.getCategories().then(res => {
			instance.categories = res == null ? [] : res;
			// if (instance.categories != null && instance.categories.length > 0)
			//   instance.selectedCategory = instance.categoriesSorted[0];
		});
	}
	get categoriesList(): Array<any> {
		var instance = this;
		var clist = [];
		if (instance.productSearch == null || instance.productSearch.trim() == "") return [];
		var l = instance.buildCategoriesSearch(instance.categories, null);

		if (l && l.length > 0) clist.push(...l);
		clist = clist.sort((a, b) => (a.count > b.count ? -1 : 1));
		return clist.length > 5 ? clist.slice(0, 5) : clist;
		// return clist;
	}
	onCategorySearchClick(category: categoryModel, lastlevel: boolean) {
		var instance = this;
		if (lastlevel) {
			instance.$router.push({
				name: "catalog",
				params: {
					categoryId: category == null ? null : category.id.toString()
				},
				query: {
					search: instance.productSearch
				}
			});
		} else {
			instance.$router.push({
				path: `/categories/${category.id}`
			});
		}
		instance.productSearch = null;
		instance.showSearchPanel = false;
		instance.showSearchProductModal = false;
	}
	buildCategoriesSearch(categories: categoryModel[], parentsDesc: string): Array<any> {
		var instance = this;
		if (categories == null || categories.length == 0) return [];
		var list = [];
		var parents = parentsDesc != null && parentsDesc != "" ? parentsDesc + " > " : null;
		categories.forEach(c => {
			var name = instance.getPropery(c, "name") as string;
			// non cerco più per nome ma poi filtro per count
			// if (name && name.length > 0 && name.toLocaleLowerCase().indexOf((instance.productSearch || "").toLocaleLowerCase()) != -1) {
			var startIndx = name.toLocaleLowerCase().indexOf((instance.productSearch || "").toLocaleLowerCase());
			var match = name.substring(startIndx, startIndx + instance.productSearch.length);
			var n = {
				id: c.id,
				category: c,
				parents: parents,
				desc: name.replace(match, `<b class='text-dark'>${match}</b>`),
				name: name,
				lastlevel: c.subCategories == null || c.subCategories.length == 0,
				startIndx: startIndx,
				match: match,
				count: instance.getCount(c)
			};
			// aggiungo solo quelle di ultimo livello
			if (n.lastlevel && n.count > 0) list.push(n);
			// }
			if (c.subCategories != null && c.subCategories.length > 0) {
				var l = instance.buildCategoriesSearch(c.subCategories, `${parents || ""}${name}`);
				if (l && l.length > 0) list.push(...l);
			}
		});
		return list;
	}
}

import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import PageOptions from "./config/PageOptions";
import { UserLoggedInfoModel } from "@/models/userModels";
import { mapActions, mapGetters, mapMutations } from "vuex";
import eventHub from "./eventHub";
import axios, { AxiosPromise } from "axios";
import { ResponseMessage, MessageType } from "./models/messageModels";
import splashScreen from "./components/splashScreen.vue";
// import moment from "moment";
import moment from "moment-business-days";
import baseUserContextComponent from "./components/baseUserContextComponent";
import numeral from "numeral";
import { CustomerInfoModel } from "./models/customerModels";
import _ from "lodash";
import { productAddedCartModel } from "./models/productModel";


@Component({
  components: {
    "app-splash-screen": splashScreen,
  },
  // computed: {
  //   ...mapGetters(["getAppCulture", "isPageLoading"])
  // },
  computed: {
    ...mapGetters(["getAppCulture"])
  },
  methods: {
    ...mapActions(["logoutUser", "updateUserInfo"]),
    ...mapMutations(["setUser", "setWindowSize"])
  }
})
export default class App extends baseUserContextComponent {
  isPageLoading: boolean = false;
  get showFooter(): boolean {
    // non so se andrà implementato un layoutComponent che wrappi la mainView
    // o se si introdurrà a nuxt.js (che già prevede la gestione dei layout)
    // quindi per ora faccio una gestione brutale
    if (this.$route.name == 'login')
      return false;
    return true;
  }
  addedProducts: productAddedCartModel[] = [];
  showAddedToCartModal: boolean = false;
  @Watch("showAddedToCartModal")
  onItemChange(newVal: any) {
    // if (newVal == false)
    //   this.addedProducts = [];
  }
  getAppCulture!: string;
  updateUserInfo!: () => AxiosPromise<UserLoggedInfoModel>;
  logoutUser!: () => AxiosPromise<boolean>;
  setUser!: (userInfo: UserLoggedInfoModel | null) => void;
  setWindowSize!: (size: any | null) => void;
  isLoaded: boolean = false;
  pageOptions = PageOptions;
  showCustomerModal: boolean = false;

  onSelectCustomer(customer: CustomerInfoModel) {
    var instance = this;
    instance.setCurrentCustomer(customer);
    instance.showCustomerModal = false;
  }
  handleScroll() {
    PageOptions.pageBodyScrollTop = window.scrollY;
  }
  onAxiosResponseError(error: any) {
    var instance = this;

    instance.$notify({
      group: "msg",
      clean: true
    });
    instance.$notify({
      group: "generic",
      clean: true
    });
    // if (error.config.url != '/api/user/login') {
    if (error.response.status == 401) {
      instance.setUser(null);
      instance.setCurrentCustomer(null);
      instance.$router.push({ name: "login" });
    } else if (error.response.status == 403) {
      instance.$router.push({ name: "forbidden" });
    } else if (error.response.status == 400) {
      if (error.response.data != null && Array.isArray(error.response.data)) {
        (error.response.data as Array<ResponseMessage>).forEach(x => {
          (instance as any).$notify({
            group: "msg",
            type:
              x.type == null || x.type == MessageType.error
                ? "error"
                : x.type == MessageType.warning
                  ? "warn"
                  : "info",
            title: x.propertyName,
            text: x.text
          });
        });
      } else {
        (instance as any).$notify({
          group: "msg",
          type: "error",
          title: "",
          text:
            error.response.data && error.response.data.text
              ? error.response.data.text
              : error.message
        });
      }
    } else {
      (instance as any).$notify({
        group: "generic",
        type: "info",
        title: "Si è verificato un errore",
        text: error.message
      });
    }
    // }
  }
  onLogout() {
    var instance = this;

    return new Promise((result, reject) => {
      instance
        .logoutUser()
        .then(res => {
          instance.$router.push({ name: "login" });
          result(true);
        })
        .catch(error => {
          console.error(error);
          reject();
        });
    });
  }
  // checkCurrentCustomer() {
  //   var instance = this;
  //   if (instance.isUserCustomer) {
  //     axios
  //       .get<CustomerInfoModel>(`customer/me`)
  //       .then(res => {
  //         instance.setCurrentCustomer(res.data ? res.data : null);
  //       })
  //       .catch(error => {
  //         console.error(error);
  //       });
  //   }
  //}
  onResizeEvent(event) {
    var self = this;

    _.debounce(function () {

      self.onResize(null);
    }, 250);
  }
  onResize(event) {
    //console.log("window has been resized", event);
    this.setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight
    });
    this.setHeaderHeight();
  }
  setHeaderHeight() {
    this.headerHeight =
      //this.mdAndUp == false ? "0px" :
      this.$refs.header != null &&
        (this.$refs.header as any).$el != null &&
        (this.$refs.header as any).$el.clientHeight != null
        ? (this.$refs.header as any).$el.clientHeight + "px"
        : "60px";
  }
  headerHeight: string = "60px";
  beforeDestroy() {
    // Unregister the event listener before destroying this Vue instance
    // window.removeEventListener("resize", this.onResizeEvent);
    window.removeEventListener("resize", this.onResize);
  }
  beforeMount() {
    var instance = this;
  }
  mounted() {
    var instance = this;

    (instance as any).$insProgress.finish();
    // window.addEventListener("resize", instance.onResizeEvent);
    window.addEventListener("resize", instance.onResize);
    instance.$nextTick(function () {
      // Code that will run only after the
      // entire view has been rendered
      instance.onResize(null);
    });

    instance
      .updateUserInfo()
      .then(res => {
        if (res) {
          eventHub.$on(
            eventHub.events.AXIOS_RESPONSE_ERROR,
            instance.onAxiosResponseError
          );
          instance.isLoaded = true;
          //instance.checkCurrentCustomer();
        } else {
          instance.onLogout().then(res => {
            eventHub.$on(
              eventHub.events.AXIOS_RESPONSE_ERROR,
              instance.onAxiosResponseError
            );
            instance.isLoaded = true;
          });
        }
      })
      .catch(error => {
        instance.onLogout().then(res => {
          eventHub.$on(
            eventHub.events.AXIOS_RESPONSE_ERROR,
            instance.onAxiosResponseError
          );
          instance.isLoaded = true;
        });
      });
  }
  beforeCreate() {
    this.$store.commit("initialiseStore");
    this.$store.subscribe((mutation, state) => {
      // Store the state object as a JSON string
      localStorage.setItem("store", JSON.stringify(state));
    });
  }
  created() {
    var instance = this;
    eventHub.$on(
      eventHub.events.PAGE_LOADING, (loading: boolean) => instance.isPageLoading = loading
    );
    eventHub.$on(eventHub.events.USER_LOGIN, function () {
      // instance.beforeMount();
      instance.$nextTick(function () {
        // Code that will run only after the
        // entire view has been rendered
        instance.onResize(null);
      });
    });
    eventHub.$on(eventHub.events.CART_UPDATED, function (addedProducts) {

      instance.showAddedToCartModal = true;
      instance.addedProducts = addedProducts;
      // instance.$nextTick(function () {
      //   debugger;
      //   instance.addedProducts = addedProducts;

      // });
    });
    moment.locale(instance.getAppCulture);
    moment.updateLocale('it', {
      workingWeekdays: [1, 2, 3, 4, 5]
    });
    //to do: load numerlLocale based on appculture
    numeral.locale("it");
    PageOptions.pageBodyScrollTop = window.scrollY;

    window.addEventListener("scroll", this.handleScroll);

    (instance as any).$insProgress.start();

    this.$router.beforeEach((to, from, next) => {
      (instance as any).$insProgress.start();
      next();
    });
    this.$router.afterEach(() => {
      (instance as any).$insProgress.finish();
    });
  }
  get layout(): string {
    if (
      this.$route.meta.layout &&
      this.$route.meta.layout in this.$options.components
    )
      return this.$route.meta.layout;
    return "default-layout";
  }
  get layoutkey(): string {
    var key = "nameless";
    if (
      this.$route.meta.layout
    )
      key = this.$route.meta.layout;
    return key == "empty-layout" ? this.$route.name || key : key;
  }
}

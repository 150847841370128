import { Component, Prop, Watch } from "vue-property-decorator";
import Vue from "vue";
import axios from "axios";
import { pagedList, sortDirection } from "@/models/pagedList";
import { EmployeeModel } from "@/models/employeeModels";
import { CustomerInfoModel, CustomerFatturatoModel } from "@/models/customerModels";
import { orderModel } from "@/models/orderModel";
import baseUserContextPage from '@/pages/baseUserContextPage';

@Component({})
export default class dashboardDetail extends baseUserContextPage {
  @Prop({ default: () => new EmployeeModel() })
  employee: EmployeeModel;

  @Watch("employee", { deep: true })
  onEmployeeChanged(val: EmployeeModel, oldVal: EmployeeModel) {
    this.fetch();
  }
  @Prop({ default: false })
  isLoading: boolean = false;

  @Prop({ default: "Agente" })
  panelInfoTitle: string;

  @Prop({ default: true })
  ordersShowDestination: boolean;

  employeeOrderField: string= "Name";
  employeeDirection:sortDirection = sortDirection.ASC;

  isLoadingTopOrders: boolean = false;
  isLoadingTopSales: boolean = false;
  isLoadingEmployeesList: boolean = false;
  isLoadingOrders: boolean = false;
  topOrders: pagedList<CustomerInfoModel> = new pagedList();
  topSales: pagedList<CustomerFatturatoModel> = new pagedList();
  employeesList: pagedList<EmployeeModel> = new pagedList();
  lastOrders: pagedList<orderModel> = new pagedList();
  
  onSelectCustomer(customer: CustomerInfoModel) {
    this.$router.push({ path: `/customer/${customer.id}` });
  }
  onSort(field: string) {
    var instance = this;
    if (instance.employeeOrderField != field) {
      instance.employeeOrderField = field;
      instance.employeeDirection = sortDirection.ASC;
    } else {
      instance.employeeDirection =
        instance.employeeDirection == sortDirection.ASC
          ? sortDirection.DESC
          : sortDirection.ASC;
    }
    instance.fetchEmployee();
  }
  fetchEmployee(){
    var instance = this;
    if (instance.employee.isAreaReferent) {
      instance.isLoadingEmployeesList = true;
      axios
        .get<pagedList<EmployeeModel>>(
          `/employee/search?refEmployeeId=${
            instance.employee.id
          }&pageSize=-1&orderField=${instance.employeeOrderField}&direction=${instance.employeeDirection}`
        )
        .then(res => {
          instance.employeesList = res.data ? res.data : new pagedList();
          instance.isLoadingEmployeesList = false;
        })
        .catch(error => {
          console.error(error);
          instance.isLoadingEmployeesList = false;
        });
    } else instance.employeesList = new pagedList();
  }
  fetch() {
    var instance = this;
    if (instance.employee.id != null) {
      instance.fetchEmployee()
      // if (instance.employee.isAreaReferent) {
      //   instance.isLoadingEmployeesList = true;
      //   axios
      //     .get<pagedList<EmployeeModel>>(
      //       `/employee/search?refEmployeeId=${
      //         instance.employee.id
      //       }&pageSize=-1&orderField=Name`
      //     )
      //     .then(res => {
      //       instance.employeesList = res.data ? res.data : new pagedList();
      //       instance.isLoadingEmployeesList = false;
      //     })
      //     .catch(error => {
      //       console.error(error);
      //       instance.isLoadingEmployeesList = false;
      //     });
      // } else instance.employeesList = new pagedList();

      // instance.isLoadingTopOrders = true;
      // axios
      //   .get<pagedList<CustomerInfoModel>>(
      //     `/customer/search?employeeId=${instance.employee.id}&pageSize=10`
      //   )
      //   .then(res => {
      //     instance.topOrders = res.data ? res.data : new pagedList();
      //     instance.isLoadingTopOrders = false;
      //   })
      //   .catch(error => {
      //     console.error(error);
      //     instance.isLoadingTopOrders = false;
      //   });

      instance.isLoadingTopSales = true;
      axios
        .get<pagedList<CustomerFatturatoModel>>(
          `/customer/toppfatturato?pageIndex=0&pageSize=10&employeeId=${instance.employee.id}`
        )
        .then(res => {
          instance.topSales = res.data ? res.data : new pagedList();
          instance.isLoadingTopSales = false;
        })
        .catch(error => {
          console.error(error);
          instance.isLoadingTopSales = false;
        });

      instance.isLoadingOrders = true;
      axios
        .get<pagedList<orderModel>>(
          `order/search?pageSize=10&orderField=CreateDate&direction=DESC&employeeId=${
            instance.employee.id
          }`
        )
        .then(res => {
          instance.lastOrders = res.data ? res.data : new pagedList();
          instance.isLoadingOrders = false;
        })
        .catch(error => {
          instance.isLoadingOrders = false;
          console.error(error);
        });
    } else {
      instance.employeesList = new pagedList();
      instance.topOrders = new pagedList();
      instance.topSales = new pagedList();
      instance.lastOrders = new pagedList();
    }
  }
  mounted() {
    var instance = this;

    instance.fetch();
  }
}

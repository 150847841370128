import { Component, Prop, Watch } from "vue-property-decorator";
import baseUserContextComponent from "../baseUserContextComponent";
import { categoryModel } from "@/models/categoryModel";
import { mediaType } from "@/models/mediaModel";
import categoryPanelNavBarTabs from "./category-panel-nav-bar-tabs.vue";
@Component({})
export default class categoryPanelNavBar extends baseUserContextComponent {
  level: number = 0;
  @Prop({ default: () => new Array<categoryModel>() })
  categories: categoryModel[];

  reset() {
    if (this.$refs.navBarTab != null) {
      (this.$refs.navBarTab as categoryPanelNavBarTabs).resetIndex();
    }
  }

  onLevelChange(level: number) {
    // this.$emit(
    //   "levelChange",
    //   this.subLevelOpened ? this.level + 1 : this.level
    // );
    // debugger;
    this.level = level;
    // console.log(`main level`, level);
  }
  get isFull(): boolean {
    return true;
    //   return this.level > 1;
    // return (
    //   this.currentCategorySecondLevelToShow != null &&
    //   this.currentCategorySecondLevelToShow.subCategories != null &&
    //   this.currentCategorySecondLevelToShow.subCategories.length > 0
    // );
  }
}

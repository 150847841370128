import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { EmployeeModel, EmployeeLightModel } from "@/models/employeeModels";
@Component({})
export default class employeeInfo extends Vue {
  @Prop({ default: "Info Agente" })
  title: string;

  @Prop({ default: () => new EmployeeModel() })
  employee: EmployeeModel;

  getDescription(employee: EmployeeLightModel): string {
    if (employee.name) return employee.name;
    var desc = employee.firstName;
    if (employee.middleName) {
      if (desc) desc += " ";
      desc += employee.middleName;
    }
    if (employee.lastName) {
      if (desc) desc += " ";
      desc += employee.lastName;
    }
    return desc;
  }
}

import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { pagedList, sortDirection } from "@/models/pagedList";
import { CustomerInfoModel } from "@/models/customerModels";
@Component({})
export default class customersList extends Vue {
  @Prop({ default: "Lista clienti" })
  title: string;
  @Prop({ default: false })
  isLoading: boolean;

  @Prop({ default: true })
  selectable: boolean;
  
  @Prop({ default: false })
  showGoToCart: boolean;

  @Prop({ default: false })
  showFatturato: boolean;
  
  @Prop({ default: sortDirection.ASC })
  sortDirection: sortDirection;

  @Prop({ default: "" })
  sortField: string;
  
  @Prop({ default: true })
  enableSort: boolean;

  onSelect(customer: CustomerInfoModel) {
    if (this.selectable) this.$emit("selected", customer);
  }

  get colSpan(): number {
    return this.showFatturato ? 8 : 7;
  }
  @Prop({ default: () => new pagedList<CustomerInfoModel>() })
  pagedList: pagedList<CustomerInfoModel>;

  @Prop({ default: () => new Array<CustomerInfoModel>() })
  list: Array<CustomerInfoModel>;

  
  headClick(field: string) {
    this.$emit("sort", field);
  }
  getSortCss(field: string): string {
    if (field != this.sortField) return "fas fa-sort text-black-transparent-5";

    return this.sortDirection == sortDirection.ASC
      ? "fas fa-sort-up text-black"
      : "fas fa-sort-down text-black";
  }

  getProvincia(customer: CustomerInfoModel): string {
    if (
      customer.shippingAddresses == null ||
      customer.shippingAddresses.length == 0
    )
      return "";
    return customer.shippingAddresses[0].region;
  }
  getDescription(customer: CustomerInfoModel): string {
    if (customer.type == "Company") return customer.company;
    return customer.lastName;
  }
  getDetailUrl(customer: CustomerInfoModel): string {
    return `/customer/${customer.id}`;
  }
  get customers(): Array<CustomerInfoModel> {
    if (this.pagedList == null || this.pagedList.items == null)
      return this.list == null ? [] : this.list;
    return this.pagedList.items;
  }
}

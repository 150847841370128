export class addressModel {
  constructor() {
    this.prefix = null;
    this.firstName = null;
    this.middleName = null;
    this.lastName = null;
    this.company = null;
    this.country = null;
    this.addressLine1 = null;
    this.addressLine2 = null;
    this.city = null;
    this.region = null;
    this.zipCode = null;
    this.note = null;
    this.telephone = null;
    this.fax = null;
    this.taxCode = null;
    this.vatCode = null;
    this.email = null;
    this.code = null;
    this.code1 = null;
  }
  prefix: string;
  firstName: string;
  middleName: string;
  lastName: string;
  company: string;
  country: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  region: string;
  zipCode: string;
  note: string;
  telephone: string;
  fax: string;
  taxCode: string;
  vatCode: string;
  email: string;
  code: string;
  code1: string;
}

import { Component, Prop, Watch } from "vue-property-decorator";
import baseUserContextComponent from "../baseUserContextComponent";
import { categoryModel } from "@/models/categoryModel";
import { mediaType } from "@/models/mediaModel";
@Component({})
export default class categoryPanel extends baseUserContextComponent {
  @Prop({ default: () => new Array<categoryModel>() })
  categories: categoryModel[];
  @Prop({ default: null })
  selectedId: number | null;

  get items(): categoryModel[] {
    var instace = this;
    return instace.categories == null ? [] : instace.categories;
  }
  getPropery(category: categoryModel, property: string) {
    if (category == null || category.locales == null) return "";
    var l = category.locales["it-IT"];
    return l == null ? "" : l[property];
  }

  isAnyChildrenSelected(categories: categoryModel[]): boolean {
    var instance = this;
    if (instance.selectedId == null) return false;
    if (categories == null || categories.length == 0) return false;
    else {
      return (
        categories.find(
          x =>
            x.id == instance.selectedId ||
            this.isAnyChildrenSelected(x.subCategories)
        ) != null
      );
    }
  }
  isSelected(c: categoryModel): boolean {
    var instace = this;
    return (
      c != null &&
      (c.id == instace.selectedId ||
        this.isAnyChildrenSelected(c.subCategories))
    );
  }
  getImageUrl(category: categoryModel) {
    if (
      category == null ||
      category.media == null ||
      category.media.length == 0
    )
      return null;

    var media = category.media
      //.sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1))
      .find(x => x.mediaType == mediaType.Image && x.languageId == "it-IT");

    return media == null ? null : media.path;
  }
  onSelect(c: categoryModel) {
    if (c.id != this.selectedId) this.$emit("select", c);
  }
}

import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";
import {
  optionIconografiaModel,
  iconografiaValueModel
} from "@/models/optionModel";
import baseUserContextComponent from '../baseUserContextComponent';
@Component({})
export default class iconografiaDetails extends baseUserContextComponent {
  font: number = 1;
  visualizzazione: number = 2;
  @Prop({ default: () => [] })
  iconografie: optionIconografiaModel[];
  getIcons(opt: optionIconografiaModel): iconografiaValueModel[] {
    if (opt == null || opt.value == null) return [];
    var opts = opt.value.filter(o => o.languageId == "it-IT");
    if (opts == null || opts.length == 0) return [];


    return opts
      .map(o => o.value)
      .sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : a.displayOrder < b.displayOrder ? -1 :
        a.label > b.label ? 1 : -1));
  }
}

// import Vue from 'vue'
import { Component, Watch, Prop } from "vue-property-decorator";
import { productAddedCartModel, productModel, productType } from "@/models/productModel";
import { sisasListiniModel } from "@/models/sisasPimListiniModel";
import axios, { AxiosPromise } from "axios";
import baseUserContexetProductComponent from "@/components/baseUserContextProductComponent";
import eventHub from "@/eventHub";
import { ShoppingCartModel } from "@/models/shoppingCartModel";
import { umDefinition } from "@/models/umDefinition";

@Component({})
export default class addToCartPanel extends baseUserContexetProductComponent {
  get addedProdutcs(): productAddedCartModel[] {
    if (this.item == null)
      return [];
    else {
      var pacm = new productAddedCartModel();
      pacm.product = this.item;
      pacm.quantity = this.myQuantity;
      pacm.source = "addToCartPanel";
      pacm.added = true;
      var products = [];
      products.push(pacm);
      return products;
    }
  }
  isAddingProduct: boolean = false;
  showWarningQuantity: boolean = false;
  @Watch("item", { deep: true })
  onItemChange(newVal: any) {
    // this.setQuantity();
    this.initQuantity();
  }
  // @Watch("myQuantity", { deep: true })
  // onMyQuantityChange(newVal: any) {
  //   this.setQuantity();
  // }
  get umMinFattore(): number {
    var instance = this;
    var min = Math.min(...(instance.umsDefinitions || []).map(item => item.fattore));
    return min == Infinity ? 1 : min || 1;
  }
  get umsDefinitions(): umDefinition[] {
    return this.getUmsDefinition(this.item);
  }
  getQuantity(umDef: umDefinition): number {
    var instance = this;
    var residuo = instance.myQuantity || 0;

    var buffer = instance.umsDefinitions
      .slice()
      .sort((a, b) => (a.proporzione < b.proporzione ? 1 : -1));

    for (var i = 0; i < buffer.length; i++) {
      var x = buffer[i];
      var d = instance.umsDefinitions.find(u => u.um == x.um);

      var q = Math.floor(residuo / d.proporzione);
      if (q) residuo = residuo % d.proporzione;
      if (d.um == umDef.um) return q;
    }
    return 0;
  }

  get residuo(): number {
    var instance = this;
    var residuo = instance.myQuantity || 0;

    var buffer = instance.umsDefinitions
      .slice()
      .sort((a, b) => (a.proporzione < b.proporzione ? 1 : -1));

    for (var i = 0; i < buffer.length; i++) {
      var x = buffer[i];
      var d = instance.umsDefinitions.find(u => u.um == x.um);

      var q = Math.floor(residuo / d.proporzione);
      if (q) residuo = residuo % d.proporzione;
    }
    return residuo;
  }
  @Prop({ default: false })
  isLoading: boolean;
  @Prop({ default: false })
  isLoadingListini: boolean;
  @Prop({ default: false })
  showMostraOriginale: boolean;

  // @Prop({ default: () => new productModel() })
  @Prop({ default: () => null })
  item: productModel;
  myQuantity: number | null = 1;
  note: string | null = null;
  get notePlaceHolder(): string {
    return "Note...";
  }
  get showNote(): boolean {
    return false;
  }
  get isValidQuantity(): boolean {
    return this.myQuantity != null && this.myQuantity >= this.umMinFattore;
  }
  get enableDecrease(): boolean {
    return this.myQuantity != null && this.myQuantity > this.umMinFattore;
  }
  // arrows + Backspace
  allowKeyCodes = [37, 38, 39, 40, 8];
  allowKeys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
  onKeyDown($event: KeyboardEvent) {
    if (
      this.allowKeyCodes.indexOf($event.keyCode) == -1 &&
      this.allowKeys.indexOf($event.key) == -1
    ) {
      $event.preventDefault();
    }
  }

  onBlur() {
    if (this.residuo > 0) {
      this.showWarningQuantity = true;
      this.myQuantity = Number.parseInt(this.myQuantity as any) + (this.umMinFattore - this.residuo);
    }
    if (this.myQuantity < this.umMinFattore) {
      this.myQuantity = this.umMinFattore;
    }
  }
  increase() {
    if (this.myQuantity == null) this.myQuantity = this.umMinFattore;
    else this.myQuantity = Number.parseInt(this.myQuantity as any) + this.umMinFattore;
    // if (this.myQuantity == null) this.myQuantity = 1;
    // else this.myQuantity++;
    this.showWarningQuantity = false;
  }
  decrease() {
    if (this.myQuantity == null) this.myQuantity = this.umMinFattore;
    else this.myQuantity = Number.parseInt(this.myQuantity as any) - this.umMinFattore;
    // if (this.myQuantity == null) this.myQuantity = 1;
    // else this.myQuantity--;
    this.showWarningQuantity = false;
  }

  get totalForPice() {
    var instance = this;
    if (instance.myQuantity == null || instance.myQuantity == 0) return 0;

    let total = instance.total;
    if (total == null || total == 0) return 0;
    return total / instance.myQuantity;
  }
  get total() {
    var instance = this;
    if (instance.myQuantity == null || instance.myQuantity == 0) return 0;

    let total = 0;
    instance.umsDefinitions.forEach(
      // d => (total += (d.quantity || 0) * (d.prezzoUM || 0))
      d => (total += (instance.getQuantity(d) || 0) * (d.prezzoUM || 0))
    );
    return total;
  }
  get totalOriginale() {
    var instance = this;
    if (instance.myQuantity == null || instance.myQuantity == 0) return 0;

    let total = 0;
    instance.umsDefinitions.forEach(
      // d => (total += (d.quantity || 0) * (d.prezzoOriginaleUM || 0))
      d => (total += (instance.getQuantity(d) || 0) * (d.prezzoOriginaleUM || 0))
    );
    return total;
  }
  get anySconto(): boolean {
    var instance = this;
    return (
      instance.umsDefinitions &&
      instance.umsDefinitions.find(x => x.hasSconto) != null
    );
  }
  get hasRisparmio(): boolean {
    var instance = this;
    return instance.risparmio > 0;
  }
  get risparmio(): number {
    var instance = this;
    if (instance.umsDefinitions == null) return 0;
    var um = instance.umsDefinitions.find(x => x.fattore == 1);
    if (um == null) return 0;
    var prezzo = um.prezzoUM * instance.myQuantity;
    if (prezzo == instance.total) return 0;
    return ((prezzo - instance.total) * 100) / prezzo;
    // return 100 - ((instance.total * 100) / prezzo);
  }
  get hasListino() {
    var instance = this;
    return instance.item == null ||
      this.item.listini == null ||
      this.item.listini.length == 0
      ? false
      : true;
  }

  getQuantitaUM(um: string) {
    return this.myQuantity;
  }
  beforeMount() { }
  mounted() {
    var instance = this;

  }
  created() {
    var instance = this;

    this.initQuantity();
  }
  initQuantity() {

    this.myQuantity = this.umMinFattore;
  }
  alertNonSelezionatoClick() {
    var instance = this;
    instance.$emit('noProductToAdd');
    // instance.$notify({
    //   title: "Nessun prodotto selezionato",
    //   text: "Seleziona la configurazione",
    //   type: "warn",
    //   group: "msg"
    // });
  }
  addToCartClick() {
    var instance = this;
    if (instance.isValidQuantity && instance.item != null) {
      instance.isAddingProduct = true;
      axios
        .post<ShoppingCartModel>(`cart/addproduct`, {
          customerId: instance.getCurrentCustomerId,
          productId: instance.item.id,
          quantity: instance.myQuantity,
          note: instance.note
        })
        .then(res => {
          instance.setCurrentCart(res.data);
          eventHub.$emit(eventHub.events.CART_UPDATED, instance.addedProdutcs);
          // instance.$notify({
          //   title: "",
          //   text: "Il prodotto è stato aggiunto al carrello",
          //   type: "success",
          //   group: "msg"
          // });
          instance.isAddingProduct = false;
          instance.$emit("added", instance.addedProdutcs);
        })
        .catch(error => {
          console.error(error);
          instance.isAddingProduct = false;
        }).finally(() => instance.showWarningQuantity = false);
    }
  }
  get showPrezzoFooter(): boolean {
    return false;
    //return this.umsDefinitions != null && this.umsDefinitions.length > 1;
  }
}

import Vue from "vue";

import { debug } from "util";

declare var $: any;

export default {
  emitDrowpdown: function(el, binding, vnode, isAppCollapsed) {
    if (vnode.componentInstance && vnode.componentInstance.$emit) {
      vnode.componentInstance.$emit("app-dropdwon", isAppCollapsed);
    } else if (vnode.data && vnode.data.on && vnode.data.on["app-dropdwon"]) {
      vnode.data.on["app-dropdwon"](isAppCollapsed);
    }
  },

  bind: function(el, binding, vnode) {
    // This occurs once the directive is attached to the element.
  },
  inserted: function(el, binding, vnode) {
    debugger;
    var target =
      vnode.context.$refs[binding.value].$el == null
        ? vnode.context.$refs[binding.value]
        : vnode.context.$refs[binding.value].$el;
    //if (target.classList.contains('collapse') == false)
    //    target.classList.add('collapse');

    //if (binding.modifiers.collapsed) {
    //    $(target).css("display", 'none');
    //}

    //$(el).off('click').on("click", (e) => {
    //    if (e.preventDefault) {
    //        e.preventDefault();
    //    }
    //    var isEpsCollapsed = $(target).css("display") != "none";
    //    if (vnode.componentInstance && vnode.componentInstance.$emit) {
    //        vnode.componentInstance.$emit('eps-collapse', isEpsCollapsed);
    //    }
    //    else if (vnode.data && vnode.data.on && vnode.data.on['eps-collapse']) {
    //        vnode.data.on['eps-collapse'](isEpsCollapsed);
    //    }

    //    $(target).slideToggle(200);

    //});

    $(target).addClass("dropdown-menu");
    if (binding.modifiers.opened) {
      $(target).addClass("show");
    } else {
      $(target).removeClass("show");
    }

    //var accordion = $(target).data('accordion');

    $(target).dropdown({
      //toggle: false
      // parent: accordion
    });
    var instance = binding.def;
    $(el)
      .off("click")
      .on("click", e => {
        //if (e.preventDefault) {
        //    e.preventDefault();
        //}
        $(target).dropdown("toggle");
      });
    $(target)
      .off("shown.bs.dropdown")
      .on("shown.bs.dropdown", function() {
        instance.emitDrowpdown(el, binding, vnode, false);
      });
    $(target)
      .off("hidden.bs.dropdown")
      .on("hidden.bs.dropdown", function() {
        instance.emitDrowpdown(el, binding, vnode, true);
      });

    // This hook occurs once the element is inserted into the parent DOM.
  },
  update: function(el, binding, vnode, oldVnode) {
    //This hook is called when the element updates, but children haven't been updated yet.
  },
  componentUpdated: function(el, binding, vnode, oldVnode) {
    // This hook is called once the component and the children have been updated.
  },
  unbind: function(el, binding, vnode) {
    // This hook is called once the directive is removed.
  }
};

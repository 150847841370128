import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { pagedList } from "@/models/pagedList";
import axios from "axios";
import { productModel } from "@/models/productModel";
import { productFeed } from "@/models/productFeed";
import productSearchService from "@/services/productSearchService";
@Component({})
export default class productsQuickSearch extends Vue {
  isSearching: boolean = false;
  show: boolean = false;
  inputFocused: boolean = false;
  buttonFocused: boolean = false;
  items: productFeed[] = [];
  search: string = "";
  slimScrollOption(): any {
    if (this.items != null && this.items.length > 7)
      return {
        height: "600px"
      };
    var height = 78 * this.items.length + 2;
    return {
      height: height + "px"
    };
  }
  // searchList: pagedList<productModel> = new pagedList();
  // searchParams: any = {
  //   pageSize: 6,
  //   pageIndex: 0,
  //   orderField: "name",
  //   direction: "ASC",
  //   search: null,
  //   categoryId: null,
  //   customerId: null
  // };

  // get items(): productModel[] {
  //   return this.searchList == null || this.searchList.items == null
  //     ? []
  //     : this.searchList.items;
  // }

  get showResult(): boolean {
    return this.show;
  }
  onSelect(product: productModel) {
    this.$emit("selected", product);
  }
  checkForm(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    if (
      this.$refs.searchInput != null &&
      (this.$refs.searchInput as any).$el != null
    )
      (this.$refs.searchInput as any).$el.focus();
    // this.show = true;
    this.onSearchKeyUp(null);
  }
  timeoutFunction: any = null;

  allowKeyCodes = [144, 33, 34, 35, 36, 37, 38, 39, 40, 45, 8, 9, 16, 17, 20];
  onSearchKeyUp($event: KeyboardEvent) {
    if ($event != null && this.allowKeyCodes.indexOf($event.keyCode) != -1)
      return;

    var instance = this;
    if (instance.timeoutFunction) {
      clearTimeout(instance.timeoutFunction);
    }
    instance.timeoutFunction = setTimeout(() => {
      instance.fetchSearch();
      console.log("onSearchKeyUp");
    }, 300);
  }
  onSearchChange($event: KeyboardEvent) {
    console.log("onSearchChange");
  }
  onSearchBlur() {}
  close() {
    var instance = this;

    setTimeout(() => {
      instance.show = false;
      // instance.searchParams.search = "";
      // instance.searchList = new pagedList();
      instance.search = "";
      instance.items = [];
    }, 300);
  }
  // fetchSearch(): void {
  //   var instance = this;
  //   instance.isSearching = true;
  //   axios
  //     .get<pagedList<productModel>>("/product/find", {
  //       params: instance.searchParams
  //     })
  //     .then(res => {
  //       instance.searchList = res.data ? res.data : new pagedList();
  //       instance.isSearching = false;
  //     })
  //     .catch(error => {
  //       console.error(error);
  //       instance.isSearching = false;
  //     });
  // }
  fetchSearch(): void {
    var instance = this;
    instance.isSearching = true;
    productSearchService
      .search(instance.search)
      .then(res => {
        instance.items = res ? res : [];
        instance.isSearching = false;
        this.show = true;
      })
      .catch(error => {
        console.error(error);
        instance.isSearching = false;
      });
  }
}

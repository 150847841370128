import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";
import baseUserContextComponent from "./baseUserContextComponent";
@Component({})
export default class searchInputHeader extends baseUserContextComponent {
  @Prop({ default: () => '' })
  title: string;
  @Prop({ default: () => 'Cerca...' })
  placeholder: string;
  @Prop({ default: () => '' })
  value: string;
  get search() {
    return this.value;
  }
  set search(val) {
    this.$emit('input', val);
  }
  onSearch(val) {
    this.$emit('change', val);
  }
  onButtonClick() {

    // this.$emit('change', this.search);
  }
}

import { Component } from "vue-property-decorator";
import baseUserContextPage from "../../pages/baseUserContextPage";
import splashScreen from "../splashScreen.vue";
import { sisasListiniModel } from "@/models/sisasPimListiniModel";
import { productModel } from "@/models/productModel";
import { pagedList } from "@/models/pagedList";
import { orderDetailModel } from "@/models/orderDetailModel";
import appConfiguration from "@/AppConfig";
import axios from "axios";
import { CustomerInfoModel } from "@/models/customerModels";

import eventHub from "../../eventHub";

@Component({
  components: {
    "app-splash-screen": splashScreen
  }
})
export default class dashboardEmpty extends baseUserContextPage {
  customerId: number = null;
  isLoadingListini: boolean = false;
  isLoadingTopProducts: boolean = false;
  topProducts: pagedList<orderDetailModel> = new pagedList();
  get products(): orderDetailModel[] {
    var instance = this;
    if (instance.topProducts == null || instance.topProducts.items == null)
      return [];
    return instance.topProducts.items;
  }
  fetchProducts() {
    var instance = this;

    instance.isLoadingTopProducts = true;
    axios
      .get<pagedList<orderDetailModel>>(
        `order/topproducts?pageSize=6&defaultifempty=true${
          instance.customerId == null
            ? ""
            : "&customerId=" + instance.customerId
        }`
      )
      .then(res => {
        instance.topProducts = res.data ? res.data : new pagedList();
        instance.fetchListini();
        instance.isLoadingTopProducts = false;
      })
      .catch(error => {
        console.error(error);
        instance.isLoadingTopProducts = false;
      });
  }
  fetchListini(): void {
    var instance = this;
    instance.isLoadingListini = true;

    if (instance.skus != null && instance.skus.length > 0) {
      axios
        .post<sisasListiniModel[]>("/product/listini", {
          customerId: this.getCurrentCustomerId,
          skus: instance.skus
        })
        .then(res => {
          if (res.data) {
            instance.topProducts.items.forEach(p => {
              var listini = res.data.filter(x => x.articolo == p.sku);
              if (listini != null) {
                if (p.product == null) p.product = new productModel();
                p.product.listini = listini;
              }
            });
          }
          instance.isLoadingListini = false;
        })
        .catch(error => {
          console.error(error);
          instance.isLoadingListini = false;
        });
    }
  }
  get skus(): string[] {
    var instance = this;
    if (
      instance.topProducts == null ||
      instance.topProducts.items == null ||
      instance.topProducts.items.length == 0
    )
      return [];

    var skus = [];

    instance.topProducts.items.forEach(p => {
      skus.push(p.sku);
    });

    return skus;
  }
  fetchCustomerAndProducts(): void {
    var instance = this;
    instance.isLoadingTopProducts = true;
    axios
      .get<CustomerInfoModel>("customer/me")
      .then(res => {
        instance.customerId = res.data ? res.data.id : null;
        instance.fetchProducts();
      })
      .catch(error => {
        console.error(error);
        instance.isLoadingTopProducts = false;
      });
  }
  mounted() {
    var instance = this;
    if (instance.isUserCustomer) instance.fetchCustomerAndProducts();
    else instance.fetchProducts();

    eventHub.$on(
      eventHub.events.CURRENT_CUSTOMER_CHANGED,
      instance.fetchListini
    );
  }
  beforeDestroy() {
    var instance = this;
    eventHub.$off(
      eventHub.events.CURRENT_CUSTOMER_CHANGED,
      instance.fetchListini
    );
  }
}

import { Component } from "vue-property-decorator";
import Vue from "vue";
import { CustomerInfoModel } from "@/models/customerModels";
import axios from "axios";

@Component({})
export default class dashboardCustomer extends Vue {
  item: CustomerInfoModel = new CustomerInfoModel();
  isLoading: boolean = false;
  // lastOrders: pagedList<orderModel> = new pagedList();
  // topProducts: pagedList<orderDetailModel> = new pagedList();

  // isLoadingOrders: boolean = false;
  // isLoadingTopProducts: boolean = false;

  fetchCustomer(): void {
    var instance = this;
    instance.isLoading = true;
    axios
      .get<CustomerInfoModel>("customer/me")
      .then(res => {
        
        instance.item = res.data ? res.data : new CustomerInfoModel();
        instance.isLoading = false;
      })
      .catch(error => {
        console.error(error);
        instance.isLoading = false;
      });
  }
  mounted() {
    var instance = this;

    instance.fetchCustomer();
  }
}

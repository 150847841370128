// import Vue from 'vue'
import { Component, Watch, Prop } from "vue-property-decorator";
import axios, { AxiosPromise } from "axios";
import { pagedList, sortDirection } from "@/models/pagedList";
import { CustomerInfoModel } from "@/models/customerModels";
import baseUserContextComponent from "../baseUserContextComponent";
import { __values } from "tslib";

@Component({})
export default class customerModalSelector extends baseUserContextComponent {
  @Prop({ default: true })
  lazy: boolean;
  @Prop({ default: false })
  value: boolean;
  @Watch("value")
  onValueChanged(val: boolean, oldVal: boolean) {
    if (val) {
      this.searchParams.search = "";
      this.fetch();
    } else {
      this.list = new pagedList();
    }
  }
  get show(): boolean {
    return this.value;
  }
  set show(event) {
    // if (event == false) this.searchParams.search = "";
    this.$emit("input", event);
  }
  @Prop({ default: "Seleziona un cliente" })
  title: string;
  isLoading: boolean = false;
  list: pagedList<CustomerInfoModel> = new pagedList();
  searchParams: any = {
    pageSize: 10,
    pageIndex: 0,
    search: null,
    orderField: "company",
    direction: sortDirection.ASC
  };
  onInput(event) {
    this.$emit("input", event);
  }
  onSelect(customer: CustomerInfoModel) {
    this.$emit("selected", customer);
  }
  pageChanged(page: number) {
    this.searchParams.pageIndex = page;
    this.fetch();
  }
  onSearch() {
    this.searchParams.pageIndex = 0;
    this.fetch();
  }
  onSort(field: string) {
    var instance = this;
    if (instance.searchParams.orderField != field) {
      instance.searchParams.orderField = field;
      instance.searchParams.direction = sortDirection.ASC;
    } else {
      instance.searchParams.direction =
        instance.searchParams.direction == sortDirection.ASC
          ? sortDirection.DESC
          : sortDirection.ASC;
    }
    instance.fetch();
  }
  fetch(): void {
    var instance = this;
    instance.isLoading = true;
    axios
      .get<pagedList<CustomerInfoModel>>("/customer/search", {
        params: instance.searchParams
      })
      .then(res => {
        instance.list = res.data ? res.data : new pagedList();
        instance.isLoading = false;
      })
      .catch(error => {
        console.error(error);
        instance.isLoading = false;
      });
  }
  mounted() {
    var instance = this;

    //instance.fetch();
  }
}

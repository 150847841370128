export enum mediaType {
  Image = 'Image',
  Video = 'Video'
}

export class mediaModel {
  path: string;

  mediaType: mediaType;

  displayOrder: number | null;

  languageId: string;

  title: string;

  description: string;
}

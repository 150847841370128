// import Vue from 'vue'
import { Component, Watch, Prop } from "vue-property-decorator";
import axios, { AxiosPromise } from "axios";
import { pagedList, sortDirection } from "@/models/pagedList";
import baseUserContextComponent from "../baseUserContextComponent";
import { Permissions } from "../../AppConfig";
import { categoryModel } from "@/models/categoryModel";
import domainService from "@/services/domainService";
import { mediaType } from "@/models/mediaModel";
import categoryPanelNavBar from "../categories/category-panel-nav-bar.vue";

@Component({})
export default class headerNavSidebar extends baseUserContextComponent {


  categories: categoryModel[] = [];
  // selectedCategory: categoryModel = new categoryModel();

 
  // onCategorySelected(c: categoryModel): void {
  //   var instance = this;
  //   instance.onCategoryClick(c);
  // }
  // onCategoryClick(c: categoryModel): void {
  //   var instance = this;
  //   if (
  //     instance.$route.name != "catalog" ||
  //     instance.$route.query.categoryId != (c == null ? null : c.id.toString())
  //   )
  //     instance.$router.push({
  //       name: "catalog",
  //       query: {
  //         categoryId: c == null ? null : c.id.toString()
  //       }
  //     });
  // }

  checkPermissions(path: string): boolean {
    var instance = this;
    if (path == "employees")
      return instance.hasAnyPermissions([
        Permissions.B2BADMISTRATOR,
        Permissions.REF_EMPLOYEE
      ]);
    if (path == "customers")
      return instance.hasAnyPermissions([
        Permissions.B2BADMISTRATOR,
        Permissions.REF_EMPLOYEE,
        Permissions.EMPLOYEE
      ]);

    return true;
  }
  isActiveRoute(key: string): boolean {
    var instance = this;
    if (instance.$route.meta == null || instance.$route.meta.moduleKey == null)
      return false;
    return instance.$route.meta.moduleKey == key;
  }
  fetch(): void {
    var instance = this;
    // instance.isLoading = true;
    // axios
    //   .get<pagedList<CustomerInfoModel>>("/customer/search", {
    //     params: instance.searchParams
    //   })
    //   .then(res => {
    //     instance.list = res.data ? res.data : new pagedList();
    //     instance.isLoading = false;
    //   })
    //   .catch(error => {
    //     console.error(error);
    //     instance.isLoading = false;
    //   });
  }
  mounted() {
    var instance = this;

    //instance.fetch();
  }

  beforeMount() {
    // var instance = this;
    // domainService.getCategories().then(res => {
    //   instance.categories = res == null ? [] : res;
    //   // if (instance.categories != null && instance.categories.length > 0)
    //   //   instance.selectedCategory = instance.categoriesSorted[0];
    // });
  }
  // getPropery(category: categoryModel, property: string) {
  //   if (category == null || category.locales == null) return "";
  //   var l = category.locales["it-IT"];
  //   return l == null ? "" : l[property];
  // }
  // getImageUrl(category: categoryModel) {
  //   if (
  //     category == null ||
  //     category.media == null ||
  //     category.media.length == 0
  //   )
  //     return null;

  //   var media = category.media
  //     //.sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1))
  //     .find(x => x.mediaType == mediaType.Image && x.languageId == "it-IT");

  //   return media == null ? null : media.path;
  // }
  // hasSubcategories(category: categoryModel): boolean {
  //   return (
  //     category != null &&
  //     category.subCategories != null &&
  //     category.subCategories.length > 0
  //   );
  // }
  // get categoriesSorted(): categoryModel[] {
  //   var instance = this;
  //   var cat = [];
  //   if (instance.categories != null) {
  //     cat = instance.categories.sort((a, b) =>
  //       a.displayOrder > b.displayOrder ? 1 : -1
  //     );
  //   }
  //   return cat;
  // }
  // get selectedCategory(): categoryModel {
  //   var instance = this;
  //   if (instance.categories == null || instance.categories.length == 0)
  //     return new categoryModel();
  //   var c = instance.categories.find(x => instance.isCategorySelected(x));
  //   return c == null ? new categoryModel() : c;
  // }
  // get currentCategoryToShow(): categoryModel {
  //   var instance = this;
  //   if (instance.categories == null || instance.categories.length == 0)
  //     return new categoryModel();
  //   var c = instance.categories.find(
  //     x => instance.currentCategoryIdToShow == x.id
  //   );
  //   return c == null ? new categoryModel() : c;
  // }

  // get currentCategoryToShowSubCategoriesSorted(): categoryModel[] {
  //   var instance = this;
  //   var cat = [];
  //   if (
  //     instance.currentCategoryToShow != null &&
  //     instance.currentCategoryToShow.subCategories != null
  //   ) {
  //     cat = instance.currentCategoryToShow.subCategories.sort((a, b) =>
  //       a.displayOrder >= b.displayOrder ? 1 : -1
  //     );
  //   }
  //   return cat;
  // }
}

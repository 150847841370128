export class SisasCommercialCondiction {
  constructor() {
    this.id = null;
    this.codicE_CLIENTE = null;
    this.chiavE_LISTINO = null;
    this.codicE_CONTRATTO = null;
    this.decrizionE_CONTRATTO = null;
    this.codicE_LISTINO = null;
    this.decrizionE_LISTINO = null;
    this.codicE_SCONTI = null;
    this.decrizionE_SCONTI = null;
  }
  id: number;
  codicE_CLIENTE: string;
  chiavE_LISTINO: string;
  codicE_CONTRATTO: string;
  decrizionE_CONTRATTO: string;
  codicE_LISTINO: string;
  decrizionE_LISTINO: string;
  codicE_SCONTI: string;
  decrizionE_SCONTI: string;
}

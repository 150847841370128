import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { orderDetailModel } from "@/models/orderDetailModel";
import { pagedList } from "@/models/pagedList";
import { productModel, productType } from "@/models/productModel";
@Component({})
export default class productsListPanel extends Vue {
  @Prop({ default: "Lista prodotti" })
  title: string;
  @Prop({ default: false })
  isLoading: boolean;

  @Prop({ default: true })
  selectable: boolean;

  onSelect(product: orderDetailModel) {
    if (this.selectable && product.product != null)
      this.$emit("selected", product.product);
  }

  @Prop({ default: () => new pagedList<orderDetailModel>() })
  pagedList: pagedList<orderDetailModel>;

  @Prop({ default: () => new Array<orderDetailModel>() })
  list: Array<orderDetailModel>;

  get products(): Array<orderDetailModel> {
    if (this.pagedList == null || this.pagedList.items == null)
      return this.list == null ? [] : this.list;
    return this.pagedList.items;
  }

  getDetailUrl(product: orderDetailModel): string {
    if (product != null) return "/product/" + product.productId;
    return "";
  }
}

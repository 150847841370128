import { Component, Prop, Watch } from "vue-property-decorator";
import axios, { AxiosPromise } from "axios";
import { mapActions, mapGetters } from "vuex";
import baseUserContextComponent from "../baseUserContextComponent";
import eventHub from "../../eventHub";
import {
  ShoppingCartModel,
  CartProductModel
} from "@/models/shoppingCartModel";
import { sisasListiniModel } from "@/models/sisasPimListiniModel";
import { umDefinition } from '@/models/umDefinition';
import baseUserContextProductComponent from '../baseUserContextProductComponent';
@Component({})
export default class cartProductListItemGroup extends baseUserContextProductComponent {
  listafiles = [];
  files = null;
  @Prop({ default: () => false })
  readonly: boolean;
  @Prop({ default: () => '' })
  size: string;
  @Prop({ default: () => [] })
  products: CartProductModel[];
  @Prop({ default: () => false })
  showNoteStato: boolean;
  @Prop({ default: () => true })
  showImage: boolean;
  @Prop({ default: () => [] })
  listini: sisasListiniModel[];
  @Watch("product", { deep: true })
  onProductChange(val: CartProductModel, oldVal: CartProductModel) {
    this.note = this.product.code2;
    this.savedNote = this.note;
    this.fetchListFile();
  }
  get product(): CartProductModel {
    return this.products == null || this.products.length == 0
      ? new CartProductModel()
      : this.products[0];
  }
  get productId(): number {
    return this.product == null || this.product.product == null
      ? null
      : this.product.product.id;
  }
  get umsDefinitions(): umDefinition[] {
    if (this.listini == null || this.listini.length == 0)
      return [];
    return this.getListiniToUmDefinitions(this.listini, (this.product == null ? null : this.product.productId));
  }
  get numeroUmDefinition(): number {
    return this.umsDefinitions == null ? 0 : this.umsDefinitions.length;
  }
  get totalQuantity(): number {
    if (this.products == null || this.products.length == 0)
      return 0;
    return this.products.reduce((a, b) => a + b.quantity, 0);
  }
  get noteSate(): boolean | null {
    var self = this;
    if (self.showNoteStato == true) {
      var n = self.savedNote;
      if (n == null || n.length === 0 || n.trim() == "")
        return false
      else return true;
    }
    return null;
  }
  isInEditQuantity: boolean = false;
  isSendingQuantity: boolean = false;
  isInEditNote: boolean = false;
  isSendingNote: boolean = false;
  isSendingDelete: boolean = false;
  get isSendingData(): boolean {
    return this.isSendingDelete || this.isSendingNote || this.isSendingQuantity;
  }
  note: string = null;
  savedNote: string | null = null;

  quantity: number = null;

  getProductByUM(um: string): CartProductModel {
    if (this.products == null || this.products.length == 0)
      return new CartProductModel();
    var p = this.products.find(x => x.code1 == um);
    return p == null ? new CartProductModel() : p;
  }

  get cart(): ShoppingCartModel {
    return this.getCurrentCart;
  }
  getDetailUrl(): string {
    return "/product/" + this.product.productId;
  }
  onRemove() {
    var instance = this;
    this.isSendingDelete = true;
    // axios
    //   .post<ShoppingCartModel>(`cart/addproduct`, {
    //     customerId: instance.getCurrentCustomerId,
    //     productId: this.product.productId,
    //     quantity: 0,
    //     um: this.product.code1,
    //     note: this.product.code2
    //   })
    //   .then(res => {
    //     instance.setCurrentCart(res.data);
    //     instance.$notify({
    //       title: "",
    //       text: "Il prodotto è stato rimosso dal carrello",
    //       type: "success",
    //       group: "msg"
    //     });
    //     this.isSendingDelete = false;
    //   })
    //   .catch(error => {
    //     console.error(error);
    //     this.isSendingDelete = false;
    //   });
    this.$emit("sendingData", this.isSendingData);
    instance.setPageLoading(true);
    var name = instance.product.name;
    axios
      .get(
        `cart/removeProduct?customerId=${instance.getCurrentCustomerId}&productId=${this.product.productId}`
      )
      .then(res => {

        instance.setCurrentCart(res.data);
        instance.$notify({
          title: "",
          text: `${name} è stato rimosso dal carrello`,
          type: "success",
          group: "msg"
        });
      })
      .catch(error => {
        console.error(error);
      }).finally(() => {

        this.isSendingDelete = false;
        this.$emit("sendingData", this.isSendingData);
        instance.setPageLoading(false);
      });
  }

  mounted() { }

  setEditNote() {
    this.isInEditNote = true;
    this.$emit("edit", this.isInEditQuantity || this.isInEditNote);
  }
  canelEditNote() {
    this.isInEditNote = false;
    this.note = this.product.code2;
    this.$emit("edit", this.isInEditQuantity || this.isInEditNote);
  }
  confirmEditNote() {
    var instance = this;
    instance.isSendingNote = true;
    this.$emit("sendingData", this.isSendingData);
    axios
      .post<ShoppingCartModel>(`cart/setnoteproduct`, {
        customerId: instance.getCurrentCustomerId,
        productId: instance.productId,
        // quantity: this.product.quantity,
        // um: instance.listino.um,
        note: this.note
      })
      .then(res => {
        instance.setCurrentCart(res.data);
        instance.$notify({
          title: "",
          text: "Le note sono state modificate",
          type: "success",
          group: "msg"
        });

        instance.isSendingNote = false;
        instance.isInEditNote = false;
        instance.$emit("edit", this.isInEditQuantity || this.isInEditNote);
        this.$emit("sendingData", this.isSendingData);
      })
      .catch(error => {
        console.error(error);
        instance.isSendingNote = false;
        instance.canelEditNote();
        this.$emit("sendingData", this.isSendingData);
      });
  }
  onSendingQuantity(sending: boolean) {
    this.isSendingQuantity = sending;
    this.$emit("sendingData", this.isSendingData);

  }

  get showUploadFiles(): boolean {
    var self = this;
    return self.isFileRichiesto((self.product || new CartProductModel()).product);
  }
  get showSetNote(): boolean {
    var self = this;
    return self.isNoteRichieste((self.product || new CartProductModel()).product);
  }
  get showLabelNote(): string {
    var self = this;
    return self.getLabelNoteRichieste((self.product || new CartProductModel()).product);
  }
  onFilesSelected(values) {
    var instance = this;
    var formData = new FormData();

    formData.append("file", values);

    axios.post<any>(`cart/setallegati/${instance.getCurrentCustomerId}/${this.productId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
      .then(res => {
        instance.listafiles = res.data || [];
      })
      .catch(err => {

      });

  }
  onRemoveFile(f) {
    var instance = this;
    axios.delete(`cart/deleteallegato/${instance.getCurrentCustomerId}/${this.productId}?file=${f.name}`)
      .then(res => {

        instance.listafiles = res.data || [];
      })
      .catch(err => {

      });
  }
  onDownloadFile(f) {
    var instance = this;

    axios.get(`cart/downloadallegato/${instance.getCurrentCustomerId}/${this.productId}?file=${f.name}`,
      { responseType: "blob" },
    ).then((response) => {

      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement('a');

      fileLink.href = fileURL;
      fileLink.setAttribute('download', f.name);
      document.body.appendChild(fileLink);

      fileLink.click();

      URL.revokeObjectURL(fileLink.href);
      document.body.removeChild(fileLink);
    });
  }
  getFileName(f) {
    if (f && f.name) {

      var n = (f.name as string).split('_');
      if (n.length < 3)
        return f.name;

      return (f.name as String).substring((n[0].length + n[1].length + n[2].length + 3));;
    }
    return '';
  }
  getPosition(string, subString, index) {
    return string.split(subString, index).join(subString).length;
  }
  fetchListFile() {
    var instance = this;
    if (instance.showUploadFiles) {
      axios.get<any>(`cart/getalleati/${instance.getCurrentCustomerId}/${this.productId}`)
        .then(res => {

          instance.listafiles = res.data || [];
        })
        .catch(err => {

        });
    }
    else {
      instance.listafiles = [];
    }
  }
  onCaricaFileClick() {
    // debugger;
    var f = this.$refs["fileInput"];
    // (f as any).$el.childNodes[0].click();
    (this.$refs.fileInput as any).$el.click()
  }
}

import Vue from "vue";
import { Component } from "vue-property-decorator";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { UserLoggedInfoModel } from "@/models/userModels";
import { Permissions } from "@/AppConfig";
import { CustomerInfoModel } from "@/models/customerModels";
import { ShoppingCartModel } from "@/models/shoppingCartModel";
import eventHub from "../eventHub";

@Component({
  computed: {
    ...mapGetters([
      "getUser",
      "getCurrentCustomer",
      "getCurrentCustomerId",
      "getIsAuthenticated",
      "getUserFirstName",
      "getUserLastName",
      "getUserId",
      "getUserRole",
      "isUserEmployee",
      "isUserCustomer",
      "isUserRefEmployee",
      "isUserB2BAdmim",
      "getCurrentCart",

      "xs",
      "xsOnly",
      "xsAndUp",
      "xsAndDown",
      "sm",
      "smOnly",
      "smAndUp",
      "smAndDown",
      "md",
      "mdOnly",
      "mdAndUp",
      "mdAndDown",
      "lg",
      "lgOnly",
      "lgAndUp",
      "lgAndDown",
      "xl",
      "xlOnly",
      "xlAndUp",
      "xlAndDown",
      "isMobile",

      "getShowInfoCustomerTopBar",
      "getShowBreadcrumbsCategories"
    ])
  },
  methods: {
    // ...mapMutations(["setCurrentCart", "setCurrentCustomer", "setPageLoading"])
    ...mapMutations(["setCurrentCart", "setCurrentCustomer"])
  }
})
export default class baseUserContextComponent extends Vue {
  // setPageLoading!: (loading: boolean) => void;
  setPageLoading(loading: boolean) { eventHub.$emit(eventHub.events.PAGE_LOADING, loading); }
  setCurrentCustomer!: (customer: CustomerInfoModel | null) => void;
  setCurrentCart!: (cart: ShoppingCartModel | null) => void;
  getUser!: UserLoggedInfoModel;
  getCurrentCart!: ShoppingCartModel;
  getIsAuthenticated: boolean;
  getCurrentCustomer!: CustomerInfoModel;
  getCurrentCustomerId: number | null;
  getUserFirstName: string;
  getUserLastName: number | null;
  getUserId: string;
  getUserRole: string;
  isUserB2BAdmim: boolean;
  isUserRefEmployee: boolean;
  isUserCustomer: boolean;
  isUserEmployee: boolean;

  xs: boolean;
  xsOnly: boolean;
  xsAndUp: boolean;
  xsAndDown: boolean;
  sm: boolean;
  smOnly: boolean;
  smAndUp: boolean;
  smAndDown: boolean;
  md: boolean;
  mdOnly: boolean;
  mdAndUp: boolean;
  mdAndDown: boolean;
  lg: boolean;
  lgOnly: boolean;
  lgAndUp: boolean;
  lgAndDown: boolean;
  xl: boolean;
  xlOnly: boolean;
  xlAndUp: boolean;
  xlAndDown: boolean;
  isMobile: boolean;

  getShowInfoCustomerTopBar: boolean;
  getShowBreadcrumbsCategories: boolean;
  scrollToTop() {
    window.scrollTo(0, 0);
  }
  routerGoBack() {
    this.$router.go(-1);
  }
  hasPermission(permission: Permissions): boolean {
    if (permission == null) return true;
    switch (permission) {
      case Permissions.B2BADMISTRATOR:
        return this.isUserB2BAdmim;
      case Permissions.REF_EMPLOYEE:
        return this.isUserRefEmployee;
      case Permissions.EMPLOYEE:
        return this.isUserEmployee;
      case Permissions.CUSTOMER:
        return this.isUserCustomer;

      default:
        return false;
    }
  }
  hasAnyPermissions(permissions: Permissions[]): boolean {
    var instance = this;
    if (permissions == null || permissions.length == 0) return true;
    var hasPermission =
      permissions.find(x => {
        return instance.hasPermission(x);
      }) != null;
    return hasPermission;
  }
}

import Vue from "vue";
import Vuex, { ActionContext } from "vuex";
import axios, { AxiosPromise } from "axios";
import { UserLoggedInfoModel } from "./models/userModels";
import AppConfig, { Permissions } from "./AppConfig";
import { CustomerInfoModel } from "./models/customerModels";
import { ShoppingCartModel } from "./models/shoppingCartModel";
import eventHub from "./eventHub";
import { categoryModel } from "./models/categoryModel";
import { optionModel } from "./models/optionModel";

Vue.use(Vuex);

var VERSION = "0.0.0.12";

class brkpoints {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
}

// copied
// $grid-breakpoints: (
//   xs: 0,
//   sm: 576px,
//   md: 768px,
//   lg: 992px,
//   xl: 1200px
// ) !default;
// const bootstrap4Breakpoints = {
//   xs: 0,
//   sm: 576,
//   md: 768,
//   lg: 992,
//   xl: 1200
// };
// const ecommerceCopied = {
//   xs: 0,
//   sm: 576,
//   md: 768,
//   lg: 992,
//   xl: 1200
// };
const uikitbrackpoint = {
  xs: 0,
  sm: 640,
  md: 960,
  lg: 1200,
  xl: 1600
};

const brkp: brkpoints = uikitbrackpoint;

class Store {
  constructor() {
    this.user = null;
    this.currentCustomer = null;
    this.currentCart = null;
    this.appCulture = "it-IT";
    // this.appCulture = localStorage.getItem("app-culutre")
    //   ?  localStorage.getItem("app-culutre")
    //   : "it-IT";
    this.version = VERSION;
    this.width = 0;
    this.height = 0;

    this.showInfoCustomerTopBar = false;
    this.showBreadcrumbsCategories = true;
    this.searchLayout = "grid";

    // this.pageLoading = false;
  }
  public width: number;
  public height: number;
  public version: string;
  public appCulture: string;
  public user: UserLoggedInfoModel | null;
  public currentCart: ShoppingCartModel | null;
  public currentCustomer: CustomerInfoModel | null;

  // public pageLoading: boolean;

  public showInfoCustomerTopBar: boolean;
  public showBreadcrumbsCategories: boolean;
  public searchLayout: string;

  public hasRole(role: string): boolean {
    if (this.user == null || this.user.roles == null) return false;
    return this.user.roles.find(x => x == role) != null;
  }
  public isUserB2BAdmim(): boolean {
    return this.hasRole(AppConfig.ROLES.B2BADMISTRATOR);
  }
  public isUserRefEmployee(): boolean {
    return this.hasRole(AppConfig.ROLES.CAPOAREA);
  }
  public isUserCustomer(): boolean {
    return this.hasRole(AppConfig.ROLES.CUSTOMER);
  }
  public isUserEmployee(): boolean {
    return this.hasRole(AppConfig.ROLES.EMPLOYEE);
  }
}

export default new Vuex.Store({
  state: new Store(),
  getters: {
    // isPageLoading: state => state.pageLoading,
    getCurrentCustomerId: state => {
      return state.currentCustomer != null ? state.currentCustomer.id : null;
    },
    getCurrentCart: state => {
      return state.currentCart;
    },
    getCurrentCustomer: state => {
      return state.currentCustomer;
    },
    getAppCulture: state => {
      return state.appCulture;
    },
    getUser: state => {
      return state.user;
    },
    getIsAuthenticated: state => {
      return state.user != null;
    },
    getUserFirstName: state => {
      return state.user != null ? state.user.firstName : "";
    },
    getUserId: state => {
      return state.user != null ? state.user.id : null;
    },
    getUserLastName: state => {
      return state.user != null && state.user.lastName != state.user.firstName ? state.user.lastName : "";
    },
    getUserRole: state => {
      if (state.user == null || state.user.roles == null) return "";
      return state.user.roles[0];
    },
    //to change using no roles
    isUserB2BAdmim: state => {
      return state.isUserB2BAdmim();
    },
    isUserRefEmployee: state => {
      return state.isUserRefEmployee();
    },
    isUserCustomer: state => {
      return state.isUserCustomer();
    },
    isUserEmployee: state => {
      return state.isUserEmployee();
    },
    xs: state => {
      return true;
    },
    xsOnly: state => {
      return state.width < brkp.sm;
    },
    xsAndUp: state => {
      return true;
    },
    xsAndDown: state => {
      return state.width < brkp.sm;
    },
    sm: state => {
      return state.width >= brkp.sm;
    },
    smOnly: state => {
      return state.width >= brkp.sm && state.width < brkp.md;
    },
    smAndUp: state => {
      return state.width >= brkp.sm;
    },
    smAndDown: state => {
      return state.width < brkp.md;
    },
    md: state => {
      return state.width >= brkp.md;
    },
    mdOnly: state => {
      return state.width >= brkp.md && state.width < brkp.lg;
    },
    mdAndUp: state => {
      return state.width >= brkp.md;
    },
    mdAndDown: state => {
      return state.width < brkp.lg;
    },
    lg: state => {
      return state.width >= brkp.lg;
    },
    lgOnly: state => {
      return state.width >= brkp.lg && state.width < brkp.xl;
    },
    lgAndUp: state => {
      return state.width >= brkp.lg;
    },
    lgAndDown: state => {
      return state.width < brkp.xl;
    },
    xl: state => {
      return state.width >= brkp.xl;
    },
    xlOnly: state => {
      return state.width >= brkp.xl;
    },
    xlAndUp: state => {
      return state.width >= brkp.xl;
    },
    xlAndDown: state => {
      return true;
    },
    isMobile: state => {
      return state.width < brkp.lg;
    },
    getShowInfoCustomerTopBar: state => {
      return state.showInfoCustomerTopBar;
    },
    getShowBreadcrumbsCategories: state => {
      return state.showBreadcrumbsCategories;
    },
    getSearchLayout: state => {
      return state.searchLayout; // == null || state.searchLayout == "" ? "list" : state.searchLayout;
    }
  },
  mutations: {
    initialiseStore(state) {
      // Check if the store exists
      if (localStorage.getItem("store")) {
        let store = JSON.parse(localStorage.getItem("store"));

        // Check the version stored against current. If different, don't
        // load the cached version
        if (store.version == VERSION) {
          this.replaceState(Object.assign(state, store));
        } else {
          state.version = VERSION;
        }
      }
    },
    setUser(state, userInfo: UserLoggedInfoModel) {
      state.user = userInfo;
      if (this.getters.isUserCustomer) {
        axios
          .get<CustomerInfoModel>(`customer/me`)
          .then(res => {
            this.commit("setCurrentCustomer", res.data ? res.data : null);
          })
          .catch(error => {
            console.error(error);
          });
      }
    },
    // setPageLoading(state, loading: boolean) {
    //   state.pageLoading = loading;
    // },
    setCurrentCustomer(state, customer: CustomerInfoModel) {
      state.currentCustomer = customer;

      eventHub.$emit(eventHub.events.CURRENT_CUSTOMER_CHANGED);
      this.dispatch("updateCurrentCart");
    },
    setCurrentCart(state, cart: ShoppingCartModel) {
      state.currentCart = cart;
    },
    setWindowSize(state, size: any) {
      state.width = window.innerWidth;
      state.height = window.innerHeight;
    },
    setShowInfoCustomerTopBar(state, show: boolean) {
      state.showInfoCustomerTopBar = show;
    },
    setShowBreadcrumbsCategories(state, show: boolean) {
      state.showBreadcrumbsCategories = show;
    },
    setSearchLayout(state, layout: string) {
      state.searchLayout = layout;
    }
  },
  actions: {
    updateUserInfo(context) {
      return axios
        .get<UserLoggedInfoModel>("account/me")
        .then(res => {
          context.commit("setUser", res.data);
          return res;
        })
        .catch(error => {
          console.error(error);
        });
    },
    updateCurrentCart(context) {
      return new Promise((resolve, reject) => {
        if (context.getters.getCurrentCustomerId != null) {
          axios
            .get<ShoppingCartModel>(`cart/${context.getters.getCurrentCustomerId}`)
            .then(res => {
              context.commit("setCurrentCart", res.data);
              resolve(res);
            })
            .catch(error => {
              console.error(error);
              reject(error);
            });
        } else {
          context.commit("setCurrentCart", null);
          resolve(null);
        }
      });
    },
    loginUser(context, token) {
      localStorage.setItem("ConvPortal-Token", token);
      eventHub.$emit(eventHub.events.USER_LOGIN);
      return context.dispatch("updateUserInfo");
    },
    logoutUser(context) {
      return axios
        .post<boolean>("/account/logout")
        .then(res => {
          localStorage.clear();
          // localStorage.setItem("store", JSON.stringify(new Store()));
          // this.dispatch("initialiseStore");

          this.replaceState(Object.assign(this.state, new Store()));

          // context.commit("setUser", null);
          // context.commit("setCurrentCustomer", null);
        })
        .catch(error => {
          console.error(error);
        });
    }
    // hasRole(context, role: string): boolean {
    //   return context.state.hasRole(role);
    // }
  }
});

export class EmployeeLightModel {
  constructor() {
    this.id = null;
    this.sourceId = null;
    this.firstName = null;
    this.middleName = null;
    this.lastName = null;
    this.name = null;
  }
  id: number;
  sourceId: string;
  firstName: string;
  middleName: string;
  lastName: string;
  name: string;
}

export class EmployeeModel extends EmployeeLightModel {
  constructor() {
    super();
    // this.firstName = null;
    // this.middleName = null;
    // this.lastName = null;
    // this.name = null;
    this.refEmployee = null;
    this.isAreaReferent = false;
  }
  // firstName: string;
  // middleName: string;
  // lastName: string;
  // name: string;
  refEmployee: EmployeeLightModel;
  isAreaReferent: boolean;
}

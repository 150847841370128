import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";
import { orderModel, orderState } from "@/models/orderModel";
@Component({})
export default class labelOrderState extends Vue {
  @Prop({ default: ()=> new orderModel()})
  order: orderModel;
  get description(): string {
    var instance = this;
    if (instance.order != null && instance.order.orderState == null)
      return "";
    if (instance.order.orderState == orderState.New) return "Invio non riuscito";
    if (instance.order.orderState == orderState.Processing) return "In attesa di conferma";
    if (instance.order.orderState == orderState.Completed) return "Completato";
    if (instance.order.orderState == orderState.Cancelled) return "Annullato";
    return "";
  }
  get css(): string {
    var instance = this;
    if (instance.order != null && instance.order.orderState == null)
      return "";
    // if (instance.order.paymentState == "Completed") return "label-success";
    // if (instance.order.paymentState == "Received") return "label-danger";
    // if (instance.order.paymentState == "Pending") return "label-warning";
    if (instance.order.orderState == orderState.New) return "label-sisas-order-state-new";
    if (instance.order.orderState == orderState.Processing) return "label-sisas-order-state-processing";
    if (instance.order.orderState == orderState.Completed) return "label-sisas-order-state-completed";
    if (instance.order.orderState == orderState.Cancelled) return "label-sisas-order-state-cancelled";
    return "";
  }
}

import { Component } from "vue-property-decorator";
import PageOptions from "../config/PageOptions";
import { UserLoggedInfoModel } from "@/models/userModels";
import baseUserContextComponent from '@/components/baseUserContextComponent';
import { Route } from "vue-router";

@Component({
  beforeRouteLeave(to, from, next) {

    next((vm) => {

      if (vm)
        (vm as baseUserContextPage).prevRoute = from || null;
    });
  }
})
export default class baseUserContextPage extends baseUserContextComponent {
  prevRoute: Route = null;
  //getUser!: UserLoggedInfoModel;
  pageOptions = PageOptions;
}










































import { Component, Prop } from "vue-property-decorator";
import { productModel } from "@/models/productModel";
import _ from "lodash";
import baseUserContextProductComponent from "../baseUserContextProductComponent";
import { umDefinition } from "@/models/umDefinition";
//var Qs = require("qs");

@Component({})
export default class productPriceTableEmpty extends baseUserContextProductComponent {
	mounted() {}
}

























































import { Component, Prop } from "vue-property-decorator";
import { productModel } from "@/models/productModel";
import _ from "lodash";
import baseUserContextProductComponent from "../baseUserContextProductComponent";
import { umDefinition } from "@/models/umDefinition";
//var Qs = require("qs");

@Component({})
export default class productPricePanel extends baseUserContextProductComponent {
	addToCartIsOpen: boolean = false;
	@Prop({ default: () => new productModel() })
	product: productModel;
	get umsDefinitions(): umDefinition[] {
		return this.getUmsDefinition(this.product);
	}

	@Prop({ default: () => false })
	isLoadingListini: boolean;

	mounted() {}
}

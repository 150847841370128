import { Component, Prop, Watch } from "vue-property-decorator";
import axios, { AxiosPromise } from "axios";
import { mapActions, mapGetters } from "vuex";
import baseUserContextComponent from "../baseUserContextComponent";
import eventHub from "../../eventHub";
import {
  ShoppingCartModel,
  CartProductModel
} from "@/models/shoppingCartModel";
import _ from "lodash";
@Component({})
export default class cartNavbar extends baseUserContextComponent {
  // @Watch("$route")
  // onRouteChange(to, from) {
  //   var instance = this;
  //   if (instance.$refs.cartdrop != null && (instance.$refs.cartdrop as any).hide)
  //     (instance.$refs.cartdrop as any).hide(true);
  // }
  get cart(): ShoppingCartModel {
    return this.getCurrentCart;
  }
  get skus() {
    if (this.cart == null || this.cart.products == null) return [];
    //return _.uniq(_.map(this.cart.products, "sku"));
    var result = [];
    var products = JSON.parse(JSON.stringify(this.cart.products));
    products.sort((a, b) => a.createDate > b.createDate ? 1 : -1).forEach(x => {
      if (result.indexOf(x.sku) == -1)
        result.push(x.sku);
    })
    return result;
  }
  getProducts(sku: string): CartProductModel[] {
    if (this.cart == null || this.cart.products == null) return [];
    var products = this.cart.products.filter(x => x.sku == sku);
    return products == null ? [] : products;
  }
  goToCart() {
    this.$router.push({ name: "cart" });
  }
  get productsOnCart(): number {
    // if (this.cart == null || this.cart.products == null) return null;

    // return _.uniq(_.map(this.cart.products, "sku")).length;
    return (this.skus || []).length;
  }
  // getDetailUrl(product: CartProductModel): string {
  //   return "/product/" + product.productId;
  // }
  // get showCustomerDescription(): boolean {
  //   return this.isUserB2BAdmim || this.isUserRefEmployee || this.isUserEmployee;
  // }
  // get isCustomerSelected() {
  //   return this.getCurrentCustomer != null && this.getCurrentCustomerId != null;
  // }
  // get customerDescription(): string {
  //   var instance = this;
  //   if (
  //     instance.getCurrentCustomerId == null ||
  //     instance.getCurrentCustomer == null
  //   )
  //     return "";
  //   if (instance.getCurrentCustomer.type == "Company")
  //     return instance.getCurrentCustomer.company;
  //   return instance.getCurrentCustomer.lastName;
  // }
  // mounted() {
  //   var instance = this;
  //   // this.myQuantity =
  //   //   this.quantity != null && this.quantity > 0 ? this.quantity : 1;
  //   eventHub.$off("cart-updated").$on("cart-updated", () => {
  //     if ((instance.$refs.cartdrop as any).show)
  //       (instance.$refs.cartdrop as any).show(true);
  //   });
  // }


  get cartTotal(): number {
    if (this.cart == null || this.cart.products == null) return 0;
    return this.cart.products.reduce(function (total, item) {
      return total + item.rowTotal;
    }, 0);
  }
}

import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";
import { categoryModel } from "@/models/categoryModel";
import { mediaType } from "@/models/mediaModel";
import { facetModel } from "@/models/facetModel";
import baseUserContextComponent from '../baseUserContextComponent';
@Component({})
export default class categoryFilter extends baseUserContextComponent {
  @Prop({ default: () => [] })
  categories: categoryModel[];
  @Prop({ default: () => null })
  categoryId: number;

  @Prop({ default: () => '' })
  title: string | null;
  @Prop({ default: () => '' })
  cssClass: string | null;
  @Prop({ default: () => [] })
  facets: facetModel[] | null;
  @Prop({ default: () => [] })
  facetsForCount: facetModel[] | null;
  @Prop({ default: () => true })
  showCount: boolean;

  visible: boolean = true;
  get showCategories() {
    return this.categories != null && this.categories.length > 0;
  }
  getCount(c: categoryModel): number | null {
    var instance = this;
    if (instance.facets == null || c == null) return 0;
    var f = instance.facets.find(x => x.code == "CategoryId");
    if (f == null) return 0;
    var count = f.values[c.id];
    return count != null ? count : 0;
  }
  getCountAgg(c: categoryModel): number | null {
    var instance = this;
    if (instance.facetsForCount == null || c == null) return 0;
    var f = instance.facetsForCount.find(x => x.code == "CategoryId");
    if (f == null) return 0;
    var count = f.values[c.id];
    return count != null ? count : 0;
  }
  onClick(c: categoryModel) {
    this.$emit("select", c);
  }

  // get subCategoriesSorted(): categoryModel[] {
  //   var instance = this;
  //   var cat = [];
  //   if (instance.categories) {
  //     cat = instance.categories.sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1));
  //   }
  //   return cat;
  // }

  getPropery(category: categoryModel, property: string) {
    if (category == null || category.locales == null) return "";
    var l = category.locales["it-IT"];
    return l == null ? "" : l[property];
  }
  getImageUrl(category: categoryModel) {
    if (
      category == null ||
      category.media == null ||
      category.media.length == 0
    )
      return null;

    var media = category.media
      //.sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1))
      .find(x => x.mediaType == mediaType.Image && x.languageId == "it-IT");

    return media == null ? null : media.path;
  }
}

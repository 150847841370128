import { mediaModel } from "./mediaModel";
import { sisasListiniModel } from "./sisasPimListiniModel";
import { iProductOption } from "./optionModel";
import { familyOptionViewModel } from "./familyOptionModel";
import { ShoppingCartModel } from "./shoppingCartModel";
export enum productType {
  Configurable = "Configurable",
  Configured = "Configured",
  Simple = "Simple"
}
export class productSelectorResult {
  constructor() {
    this.allFiltersSelected = false;
    this.product = null;
  }
  allFiltersSelected: boolean;
  product: productModel;
}
export class productModel {
  constructor() {
    this.options = [];
    this.id = null;
    this.sku = null;
    this.mainImage = null;
    this.masterId = null;
    this.familyOptions = [];
    this.name = {};
    this.listini = [];
    this.type = null;
    this.skus = [];
    this.media = [];
    this.categoriesIdDefault = null;
    this.categoryIds = [];

    this.variantsLocalized = {};
    this.variantsValues = {};
    this.isTopProduct = false;
  }
  options: iProductOption[];
  id: number | null;
  sku: string | null;
  mainImage: string | null;
  masterId: number | null;
  familyOptions: Array<familyOptionViewModel>;
  name: any;
  listini: sisasListiniModel[];
  type: productType | null;
  skus: productModel[];
  media: mediaModel[];
  categoriesIdDefault: number | null;
  categoryIds: number[];

  variantsLocalized: {
    [key: string]: string;
  };
  variantsValues: {
    [key: string]: string;
  };
  isTopProduct: boolean;
}
export class productAddedCartModel {
  constructor() {
    this.sku = null;
    this.product = null;
    this.quantity = null;
    this.source = null;
    this.added = false;
    this.message = null;
  }
  sku: string;
  product: productModel;
  quantity: number;
  source: string;
  added: boolean;
  message: string;
}
export class AddedSkuToCartResponse {
  constructor() {
    this.products = null;
    this.cart = null;
  }
  products: productAddedCartModel[];
  cart: ShoppingCartModel;
}
export class QuickAddSkuToCart {
  /**
   *
   */
  constructor() {
    this.customerId = null;
    this.productId = null;
    this.sku = null;
  }
  customerId: number;
  productId: number;
  sku: string;
}

export class AddSkuToCart extends QuickAddSkuToCart {
  constructor() {
    super();
    this.quantity = null;
    this.um = null;
    this.note = null;
  }
  quantity: number;
  um: string;
  note: string;
}
export class AddSkuToCartRequest {
  constructor() {
    this.products = [];
  }
  products: AddSkuToCart[];
}

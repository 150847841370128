import { Component, Watch } from "vue-property-decorator";
import { mapActions, mapMutations } from "vuex";
import axios, { AxiosPromise } from "axios";
import baseUserContextPage from "@/pages/baseUserContextPage";
import { pagedList } from "@/models/pagedList";
import { productModel, productType } from "@/models/productModel";
import { productFeed } from "@/models/productFeed";
import { ShoppingCartModel } from "@/models/shoppingCartModel";
import { CustomerInfoModel } from "@/models/customerModels";
import { productSearchResult } from "@/models/productSearchModel";
import { facetModel } from "@/models/facetModel";
import { categoryModel } from "@/models/categoryModel";
import domainService from "@/services/domainService";
import { sisasListiniModel } from "@/models/sisasPimListiniModel";
import _ from "lodash";
import Vue from "vue";

@Component({
  methods: {
    ...mapActions(["logoutUser"]),
    ...mapMutations([
    ])
  }
})
export default class headerCheckout extends baseUserContextPage /* user baseUserContextPage insted of baseUserContextComponent to access to common layout page properties */ {


}

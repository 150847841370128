// import Vue from 'vue'
import { Component, Watch, Prop } from "vue-property-decorator";
import { productModel, productType } from "@/models/productModel";
import { mediaType, mediaModel } from "@/models/mediaModel";
import { sisasListiniModel } from "@/models/sisasPimListiniModel";
import { optionModel } from "@/models/optionModel";
import domainService from "@/services/domainService";
import { CustomerInfoModel } from "@/models/customerModels";
import baseUserContexetProductComponent from "@/components/baseUserContextProductComponent";
import { productRelation } from "@/models/productRelation";
var Qs = require("qs");

class variantDef {
  constructor() {
    this.code = "";
    this.label = "";
  }
  code: string;
  label: string;
}
@Component({})
export default class productDetailSimple extends baseUserContexetProductComponent {
  @Prop({ default: false })
  isLoading: boolean;
  @Prop({ default: false })
  isLoadingListini: boolean;
  // @Prop({ default: false })
  // isLoadingRelations: boolean;

  selectedProduct: productModel = null;

  // @Prop({ default: () => [] })
  // relations: productRelation[];
  @Prop({ default: () => new productModel() })
  item: productModel;
  @Watch("item", { deep: true })
  onProductChange(newVal: any) {
    var instance = this;
    instance.setDefaultImageIndex();
  }
  setDefaultImageIndex() {
    var instance = this;
    if (instance.item.mainImage) {
      for (var index = 0; index < instance.images.length; index++) {
        var mainImg = instance.images[index];
        if (mainImg.path == instance.item.mainImage) {
          instance.setImage(mainImg, index);
          return;
        }
      }
    }
    instance.imageIndex = 0;
  }
  //item: productModel = new productModel();
  imageIndex: number = 0;
  filters: optionModel[] = [];

  showCustomerModal: boolean = false;
  showAddToCartModal: boolean = false;
  onSelect(customer: CustomerInfoModel) {
    var instance = this;

    instance.showCustomerModal = false;
    instance.setCurrentCustomer(customer);
    //   instance.$emit("customerSelected", customer);

    // instance.$emit("isOpen", instance.showModal || instance.showCustomerModal);
    // Vue.nextTick(function() {
    // do something cool
    this.$emit("customerSelected", customer);
    // instance.showAddToCartModal = true;
    // });
  }
  onSelectProduct(p: productModel) {
    this.$emit("relatedProductSelected", p);
  }
  addToCartClick() {
    if (this.getCurrentCustomer == null) this.showCustomerModal = true;
    else if (this.hasAnyListino(this.item)) this.showAddToCartModal = true;
  }
  showModalCustomer() {
    if (this.getCurrentCustomer == null) this.showCustomerModal = true;
  }

  get imgWidth() {
    return this.xl ? 258 : this.lg ? 230 : this.md ? 600 : this.sm ? 300 : 258;
  }
  get imgHeight(): number {
    return this.xl ? 380 : this.lg ? 330 : this.md ? 240 : 240;
  }
  get imgDivWidth() {
    return this.xl ? `${this.imgWidth + 60}px` : "100%"; //this.md ? 658 : 448;
  }
  get imgDivHeight(): string {
    return `${this.imgHeight + (this.lg ? 60 : 30)}px`;
  }

  get categoryId(): number {
    var instance = this;
    var categoryId = null;
    if (instance.item == null) return null;

    if (instance.$route.query && instance.$route.query.categoryId)
      categoryId = instance.$route.query.categoryId as any;

    categoryId = Number.parseInt(categoryId);
    if (Number.isNaN(categoryId)) categoryId = null;

    if (
      categoryId != null &&
      instance.item.categoryIds.indexOf(categoryId) != -1
    )
      return categoryId;

    return instance.item.categoriesIdDefault != null
      ? instance.item.categoriesIdDefault
      : instance.item.categoryIds.length > 0
        ? instance.item.categoryIds[0]
        : null;
  }

  // @Watch("$route.params.id", { immediate: true })
  // onUrlIdChange(newVal: any) {
  //   // Some action

  //   this.fetch();
  // }

  get id(): number {
    return this.$route && this.$route.params && this.$route.params.id
      ? Number.parseInt(this.$route.params.id)
      : null;
  }
  get images() {
    var instance = this;
    var images = [];

    if (instance.item != null && instance.item.media != null) {
      images = instance.item.media.filter(x => x.mediaType == mediaType.Image);
    }

    return images.sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1));
  }
  get selectedImage(): mediaModel {
    var instance = this;
    if (
      instance.item == null ||
      instance.images.length == 0 ||
      instance.images.length < instance.imageIndex
    )
      return new mediaModel();
    else return instance.images[instance.imageIndex];
  }
  isSelected(image: mediaModel, index: number) {
    return this.imageIndex == index;
  }
  setImage(image: mediaModel, index: number) {
    return (this.imageIndex = index);
  }

  get first_listino_with_discount(): sisasListiniModel {
    if (this.item == null || this.item.listini == null) return null;

    var listino = this.item.listini.find(
      l => l.sconto1 != null && l.sconto1 != 0
    );
    return listino;
  }

  get skus(): string[] {
    var instance = this;
    if (instance.item == null) return [];

    var skus = [];
    skus.push(instance.item.sku);
    return skus;
  }
  onConfiguredClick(product: productModel) {
    var instance = this;
    if (product != null && product.id != this.item.id) {
      instance.item = product;
      instance.$router.replace({
        path: `/product/${product.id}`,
        query: instance.queryParams
      });
    }
  }
  get queryParams(): any {
    var instance = this;
    var params = instance.$route.query;
    var q = {};
    if (params && params.categoryId != undefined && params.categoryId != null) {
      q["categoryId"] = params.categoryId;
    }
    if (params && params.filters != undefined && params.filters != null) {
      q["filters"] = params.filters;
    }
    return q;
  }
  beforeMount() {
    domainService
      .getOptinFilters()
      .then(res => (this.filters = res == null ? [] : res));
  }
  mounted() {
    var instance = this;
    instance.setDefaultImageIndex();
  }
}

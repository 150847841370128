import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";
import { categoryModel } from "@/models/categoryModel";
import { mediaType } from "@/models/mediaModel";
@Component({})
export default class TopMenuCategorySm extends Vue {
  @Prop({ default: () => new categoryModel() })
  category: categoryModel;
  @Prop({ default: () => null })
  categoryId: number | null;

  visible: boolean = false;

  isAnyChildrenCategorySelected(categories: categoryModel[]): boolean {
    var instance = this;
    if (instance.categoryId == null) return false;
    if (categories == null || categories.length == 0) return false;
    else {
      return (
        categories.find(
          x =>
            x.id == instance.categoryId ||
            this.isAnyChildrenCategorySelected(x.subCategories)
        ) != null
      );
    }
  }
  isCategorySelected(c: categoryModel): boolean {
    var instace = this;
    return (
      c != null &&
      (c.id == instace.categoryId ||
        this.isAnyChildrenCategorySelected(c.subCategories))
    );
  }
  getPropery(category: categoryModel, property: string) {
    if (category == null || category.locales == null) return "";
    var l = category.locales["it-IT"];
    return l == null ? "" : l[property];
  }

  onCategorySelected(c: categoryModel): void {
    var instance = this;
    instance.onCategoryClick(c);
  }
  onCategoryClick(c: categoryModel): void {
    var instance = this;
    if (
      instance.$route.name != "catalog" ||
      instance.$route.query.categoryId != (c == null ? null : c.id.toString())
    )
      instance.$router.push({
        name: "catalog",
        query: {
          categoryId: c == null ? null : c.id.toString()
        }
      });
  }

  hasSubcategories(category: categoryModel): boolean {
    return (
      category != null &&
      category.subCategories != null &&
      category.subCategories.length > 0
    );
  }
}

export class umDefinition {
    constructor() {
      this.productId = null;
      this.um = "";
      // this.quantity = null;
      this.hasSconto = null;
      this.prezzoOriginaleUnitario = null;
      this.prezzoOriginaleUM = null;
      this.prezzoUnitario = null;
      this.prezzoUM = null;
      this.fattore = null;
      this.conversione = null;
      this.principale = false;
      this.secondaria = false;
      this.proporzione = null;
    }
    productId: number | null;
    um: string;
    // quantity: number;
    hasSconto: boolean;
    prezzoOriginaleUnitario: number;
    prezzoUnitario: number;
    prezzoUM: number;
    prezzoOriginaleUM: number;
    fattore: number;
    conversione: string;
    principale: boolean;
    secondaria: boolean;
    proporzione: number;
  }
  export class umQuantity{
    constructor() {
      this.productId = null;
      this.um = "";
      this.quantity = null;
    }
    productId: number;
    um: string;
    quantity: number;
  }
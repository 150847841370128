import { Component, Prop, Watch } from "vue-property-decorator";
import baseUserContextComponent from "../baseUserContextComponent";
import { categoryModel } from "@/models/categoryModel";
@Component({})
export default class categoryBreaddcrumb extends baseUserContextComponent {
  @Prop({ default: () => new Array<categoryModel>() })
  categories: categoryModel[];
  @Prop({ default: null })
  selectedId: number | null;
  @Prop({ default: false })
  selectedActive: boolean;
  @Prop({ default: () => null })
  type: string | null;

  @Prop({ default: () => 'no-child' })
  hideSelectedMode: boolean | string;

  hideSelected(c: categoryModel): boolean {
    if (this.hideSelectedMode === 'no-child') {
      if (c == null)
        return false;
      return c.subCategories == null || c.subCategories.length == 0;
    }
    else if (this.hideSelectedMode === 'true' || this.hideSelectedMode === true)
      return true;
    return false;
  }

  get items(): categoryModel[] {
    var instance = this;
    if (
      instance.selectedId == null ||
      instance.categories == null ||
      instance.categories.length == 0
    )
      return [];
    var result = [];
    instance.buildItems(instance.categories, result);
    return result;
  }

  getPropery(category: categoryModel, property: string) {
    if (category == null || category.locales == null) return "";
    var l = category.locales["it-IT"];
    return l == null ? "" : l[property];
  }
  buildItems(
    categories: categoryModel[],
    result: categoryModel[]
  ): categoryModel[] {
    var instace = this;
    if (result == null) result = [];
    if (categories == null || categories.length == 0) return result;

    var c = categories.find(
      x =>
        x.id == instace.selectedId ||
        this.isAnyChildrenSelected(x.subCategories)
    );
    if (c != null) {
      result.push(c);
      instace.buildItems(c.subCategories, result);
    }
    return result;
  }
  isAnyChildrenSelected(categories: categoryModel[]): boolean {
    var instance = this;
    if (instance.selectedId == null) return false;
    if (categories == null || categories.length == 0) return false;
    else {
      return (
        categories.find(
          x =>
            x.id == instance.selectedId ||
            this.isAnyChildrenSelected(x.subCategories)
        ) != null
      );
    }
  }
  onSelect(c: categoryModel) {
    if (c == null || c.id != this.selectedId || this.selectedActive) this.$emit("select", c);
  }
  onSelectCatalogo(c: categoryModel) {
    if (this.selectedId != null) this.$emit("select", c);
  }
}


const sidebarMenu = [
  { path: '/', icon: 'fa fa-th text-sisas', title: 'Dashboard' },
  { path: '/orders', icon: 'fa fa-clipboard text-sisas', title: 'I miei ordini' },
  { path: '/catalog', icon: 'fa fa-list text-sisas', title: 'Catalogo' },
  { path: '/customers', icon: 'fa fa-address-book text-sisas', title: 'Clienti' },
  { path: '/employees', icon: 'fa fa-user-tie text-sisas', title: 'Agenti' },
]

export default sidebarMenu;

import { Component, Watch } from "vue-property-decorator";
import PageOptions from "../../config/PageOptions";
import baseUserContextPage from "../baseUserContextPage";
import axios, { AxiosPromise } from "axios";
import {
  ShoppingCartModel,
  CartProductModel
} from "@/models/shoppingCartModel";
import { addressModel } from "@/models/addressModel";
import eventHub from "@/eventHub";
import { sisasPlaceOrderResult } from "@/models/sisasPlaceOrderResult";
import { pagedList } from "@/models/pagedList";
import { productModel } from "@/models/productModel";
import _ from "lodash";
import { sisasListiniModel } from "@/models/sisasPimListiniModel";
import { CustomerInfoModel } from "@/models/customerModels";
import baseUserContextProductComponent from "@/components/baseUserContextProductComponent";
import { UserLoggedInfoModel } from "@/models/userModels";

@Component({})
export default class cartPage extends baseUserContextProductComponent {
  showNoteStato: boolean = false;
  isLoadingProduct: boolean = false;
  showAddToCartModal: boolean = false;
  selectedProduct: productModel = null;
  @Watch("getCurrentCustomer", { deep: true })
  onCurrentCustomerChange(newValue, oldValue) {
    this.fetchListini();
  }
  @Watch("cart", { deep: true })
  onCartChange(newValue, oldValue) {
    this.fetchListini();
  }
  onSelectProduct(p: productModel) {
    var instance = this;
    instance.selectedProduct = null;
    instance.isLoadingProduct = true;
    instance.showAddToCartModal = true;
    axios
      .get<productModel>("/product/masterorproduct", {
        params: {
          productId: p.id,
          loadListini: true,
          customerId: instance.getCurrentCustomerId
        }
      })
      .then(res => {
        if (res) {
          let prod = res.data || new productModel();
          if (p.id != prod.id) {
            var sku = (prod.skus || new Array<productModel>()).find(
              x => x.id == p.id
            );
            // if (sku != null && sku.variantsValues != null) {
            //   for (const [key, value] of Object.entries(sku.variantsValues)) {
            //     instance.selectedFilters[key] = [];
            //     instance.selectedFilters[key].push(value);
            //   }
            // }
          }
          instance.selectedProduct = prod;
        }
        instance.isLoadingProduct = false;
      })
      .catch(error => {
        console.error(error);
        instance.isLoadingProduct = false;
      });
  }
  get productsOnCart(): number {
    if (this.cart == null || this.cart.products == null) return null;

    return _.uniq(_.map(this.cart.products, "sku")).length;
  }
  // getUser!: UserLoggedInfoModel;
  pageOptions = PageOptions;

  isLoading: boolean = false;
  showAddressModal: boolean = false;
  note: string = null;
  isInEditNote: boolean = false;
  isSendingNote: boolean = false;
  isProcessingOrder: boolean = false;
  isOrderCreated: boolean = false;
  isLoadingLisini: boolean = false;
  listini: sisasListiniModel[] = [];

  showCustomerModal: boolean = false;
  isChangingAddress: boolean = false;

  get showCustomerDescription(): boolean {
    return this.isUserB2BAdmim || this.isUserRefEmployee || this.isUserEmployee;
  }

  onSelectCustomer(customer: CustomerInfoModel) {
    var instance = this;
    instance.setCurrentCustomer(customer);
    instance.showCustomerModal = false;
  }
  get cart(): ShoppingCartModel {
    return this.getCurrentCart;
  }
  get isCurrentCustomerSelected(): boolean {
    return this.getCurrentCustomer != null;
  }
  // get products() {
  //   if (this.cart == null || this.cart.products == null) return [];

  //   var products = _.groupBy(this.cart.products, "product.id");
  //   return products == null
  //     ? []
  //     : _.mapKeys(products, (key, value) => {
  //         return value;
  //       });
  // }
  get skus() {
    if (this.cart == null || this.cart.products == null) return [];
    //return _.uniq(_.map(this.cart.products, "sku"));
    var result = [];
    var products = JSON.parse(JSON.stringify(this.cart.products));
    products.sort((a, b) => a.createDate > b.createDate ? 1 : -1).forEach(x => {
      if (result.indexOf(x.sku) == -1)
        result.push(x.sku);
    })
    return result;
  }
  get addresses(): addressModel[] {
    return this.getCurrentCustomer == null
      ? []
      : this.getCurrentCustomer.shippingAddresses;
  }
  get cartTotal(): number {
    if (this.cart == null || this.cart.products == null) return 0;
    return this.cart.products.reduce(function (total, item) {
      return total + item.rowTotal;
    }, 0);
  }
  get shippingAddress(): addressModel {
    return this.cart == null && this.cart.shipping == null
      ? null
      : this.cart.shipping[0];
  }
  getProducts(sku: string): CartProductModel[] {
    if (this.cart == null || this.cart.products == null) return [];
    var products = this.cart.products.filter(x => x.sku == sku);
    return products == null ? [] : products;
  }
  getListini(sku: string): sisasListiniModel[] {
    if (this.listini == null) return [];
    var listini = this.listini.filter(x => x.articolo == sku);
    if (listini == null) return [];
    return listini;
  }
  // onQuickAddToCart(product: productModel) {
  //   var instance = this;
  //   axios
  //     .post<ShoppingCartModel>(`cart/quickaddproduct`, {
  //       customerId: instance.getCurrentCustomerId,
  //       productId: product.id
  //     })
  //     .then(res => {
  //       if (res.data) {
  //         instance.setCurrentCart(res.data);
  //         instance.$notify({
  //           title: "",
  //           text: "Il prodotto è stato aggiunto al carrello",
  //           type: "success",
  //           group: "msg"
  //         });
  //         this.fetchListini();
  //       }
  //     })
  //     .catch(error => {
  //       console.error(error);
  //     });
  // }
  setEditNote() {
    this.isInEditNote = true;
  }
  canelEditNote() {
    this.isInEditNote = false;
    this.note = this.cart.notes;
  }
  confirmEditNote() {
    var instance = this;
    instance.isSendingNote = true;
    axios
      .post<ShoppingCartModel>(`cart/setnotes`, {
        customerId: instance.getCurrentCustomerId,
        notes: this.note
      })
      .then(res => {
        instance.setCurrentCart(res.data);
        instance.note = instance.cart.notes;
        instance.$notify({
          title: "",
          text: "Le note sono state modificate",
          type: "success",
          group: "msg"
        });

        instance.isSendingNote = false;
        instance.isInEditNote = false;
      })
      .catch(error => {
        console.error(error);
        instance.isSendingNote = false;
        instance.canelEditNote();
      });
  }
  onAddressSelect(address: addressModel) {
    var instance = this;
    var isNew = address == null;
    instance.isChangingAddress = true;
    var text = isNew ? "L'indirizzo di spedizione è stato rimosso, ricordarsi di inserirlo manualmente nelle note dell'ordine" : "L'indirizzo di spedizione è stato impostato";
    if (isNew)
      address = new addressModel();
    axios
      .post<ShoppingCartModel>(
        `cart/setaddress/${this.getCurrentCustomerId}`,
        address
      )
      .then(res => {
        instance.setCurrentCart(res.data);
        instance.$notify({
          title: "",
          text: text,
          type: "success",
          group: "msg"
        });
        instance.isChangingAddress = false;
      })
      .catch(error => {
        console.error(error);
        instance.isChangingAddress = false;
      });

    instance.showAddressModal = false;
  }
  onComplete() {
    var instance = this;
    var mostraAlert = (instance.cart.products || []).some(x => {
      var p = (x || new CartProductModel());
      return instance.isNoteRichieste((p.product || new productModel())) && (p.code2 == null || p.code2.length === 0 || p.code2.trim() == "")
    });
    if (mostraAlert) {
      this.$bvModal.msgBoxConfirm('Non tutte le note dei prodotti che le necessitano sono state inserite. Inviare comunque l\'ordine?', {
        contentClass: 'border-warning',
        headerBgVariant: 'warning',
        title: 'Attenzione',
        size: 'md',
        buttonSize: 'sm',
        okVariant: 'warning',
        okTitle: 'SI',
        cancelTitle: 'NO',
        hideHeaderClose: false,
        centered: true
      })
        .then(value => {

          if (value == true) {
            instance.completaOrdine();
          } else
            instance.showNoteStato = true;
        })
        .catch(err => {
          // An error occurred
        });
    }
    else {
      instance.completaOrdine();
    }
  }
  goToCheckout() {
    var instance = this;
    instance.$router.push({ path: `/checkout` });
  }
  completaOrdine() {
    var instance = this;
    // instance.$router.push({ path: `/checkout` });
    instance.isProcessingOrder = true;
    axios
      .post<sisasPlaceOrderResult>(
        `cart/compleate/${this.getCurrentCustomerId}`
      )
      .then(res => {
        if (res != null && res.data.success) {
          instance.setCurrentCart(res.data.cart);
          instance.isProcessingOrder = false;
          instance.$router.push({ path: `/order/${res.data.createdOrder.id}` });
        } else {
          (instance as any).$notify({
            group: "msg",
            type: "error",
            title: "Invio dell'ordine non riuscito",
            text: "Riprovare più tardi o contattare il servizio clienti"
          });
          instance.setCurrentCart(res.data.cart);
          instance.isProcessingOrder = false;
        }
      })
      .catch(error => {
        console.error(error);
        instance.isProcessingOrder = false;
      });
  }
  created() {
    var instance = this;
    instance.note = this.cart != null ? this.cart.notes || null : null;
    instance.fetchListini();
  }
  fetchListini() {
    var instance = this;
    instance.isLoadingLisini = true;
    axios
      .post<sisasListiniModel[]>("/product/listini", {
        customerId: instance.getCurrentCustomerId,
        skus: instance.skus
      })
      .then(res => {
        instance.listini = res.data ? res.data : [];
        instance.isLoadingLisini = false;
      })
      .catch(error => {
        console.error(error);
        instance.isLoading = false;
      });
  }
}

export enum Permissions {
  B2BADMISTRATOR,
  REF_EMPLOYEE,
  EMPLOYEE,
  CUSTOMER
}
class AppConfiguration {
  public readonly ROLES = {
    B2BADMISTRATOR: "Amministrazione B2B",
    EMPLOYEE: "Agente",
    CUSTOMER: "Cliente",
    CAPOAREA: "Capo Area"
  };
}

const appConfiguration = new AppConfiguration();
export default appConfiguration;








































































































































































































































































































































































// import Vue from 'vue'
import { Component, Watch } from "vue-property-decorator";
import baseUserContextComponent from "../baseUserContextComponent";
import { Permissions } from "../../AppConfig";
import { categoryModel } from "@/models/categoryModel";
import domainService from "@/services/domainService";
import { mediaType } from "@/models/mediaModel";
import categoryPanelNavBar from "../categories/category-panel-nav-bar.vue";
import headerLogo from "./header-logo.vue";

@Component({
	components: { headerLogo }
})
export default class headerNavMenu extends baseUserContextComponent {
	@Watch("$route")
	onRouteChange(to, from) {
		var instance = this;
		instance.closeCategoriesModal();
		instance.closeMobileModalMenu();
		// instance.resetSearch();
	}
	showMobileModalMenu: boolean = false;
	closeMobileModalMenu() {
		this.showMobileModalMenu = false;
		this.categoryId_col1 = null;
		this.categoryId_col2 = null;
	}
	openMobileModalMenu() {
		this.showMobileModalMenu = true;
		this.categoryId_col1 = null; // this.categoriesSorted.length == 0 ? null : this.categoriesSorted[0].id;
		this.categoryId_col2 = null;
	}
	onMenuPrincipaleClick() {
		this.categoryId_col1 = null;
	}
	showCategoriesModal: boolean = false;
	closeCategoriesModal() {
		this.showCategoriesModal = false;
		this.categoryId_col1 = null;
		this.categoryId_col2 = null;
	}
	openCategoriesModal() {
		this.showCategoriesModal = true;
		this.categoryId_col1 = null; // this.categoriesSorted.length == 0 ? null : this.categoriesSorted[0].id;
		this.categoryId_col2 = null;
	}
	clickedCatalogMenu: boolean = false;
	onverCatalogMenuItem: boolean = false;
	get openCatalogMenu(): boolean {
		return (this.lg && this.onverCatalogMenuItem) || this.clickedCatalogMenu;
	}
	onClickOutSideCatalog() {
		this.clickedCatalogMenu = false;
	}
	onTogleCatalogMenu() {
		this.clickedCatalogMenu = !this.clickedCatalogMenu;
	}
	onCatalogMenuItemOver() {
		this.onverCatalogMenuItem = true;
	}
	onCatalogMenuItemLeave() {
		this.onverCatalogMenuItem = false;
	}
	categories: categoryModel[] = [];
	// selectedCategory: categoryModel = new categoryModel();

	isActiveRoute(key: string): boolean {
		var instance = this;
		if (instance.$route.meta == null || instance.$route.meta.moduleKey == null) return false;
		return instance.$route.meta.moduleKey == key;
	}

	checkPermissions(path: string): boolean {
		var instance = this;
		if (path == "employees") return instance.hasAnyPermissions([Permissions.B2BADMISTRATOR, Permissions.REF_EMPLOYEE]);
		if (path == "customers") return instance.hasAnyPermissions([Permissions.B2BADMISTRATOR, Permissions.REF_EMPLOYEE, Permissions.EMPLOYEE]);

		return true;
	}
	fetch(): void {
		var instance = this;
		// instance.isLoading = true;
		// axios
		//   .get<pagedList<CustomerInfoModel>>("/customer/search", {
		//     params: instance.searchParams
		//   })
		//   .then(res => {
		//     instance.list = res.data ? res.data : new pagedList();
		//     instance.isLoading = false;
		//   })
		//   .catch(error => {
		//     console.error(error);
		//     instance.isLoading = false;
		//   });
	}
	mounted() {
		var instance = this;

		//instance.fetch();
	}

	beforeMount() {
		var instance = this;
		domainService.getCategories().then(res => {
			instance.categories = res == null ? [] : res;
			// if (instance.categories != null && instance.categories.length > 0)
			//   instance.selectedCategory = instance.categoriesSorted[0];
		});
	}
	getPropery(category: categoryModel, property: string) {
		if (category == null || category.locales == null) return "";
		var l = category.locales["it-IT"];
		return l == null ? "" : l[property];
	}
	getImageUrl(category: categoryModel) {
		if (category == null || category.media == null || category.media.length == 0) return null;

		var media = category.media
			//.sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1))
			.find(x => x.mediaType == mediaType.Image && x.languageId == "it-IT");

		return media == null ? null : media.path;
	}
	hasSubcategories(category: categoryModel): boolean {
		return category != null && category.subCategories != null && category.subCategories.length > 0;
	}
	isActive(c: categoryModel): boolean {
		var instance = this;
		return c != null && instance.selectedCategory != null && c.id == instance.selectedCategory.id;
	}
	get categoriesSorted(): categoryModel[] {
		var instance = this;
		var cat = [];
		if (instance.categories != null) {
			cat = instance.categories.sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1));
		}
		return cat;
	}
	get categoryId(): number {
		var instance = this;
		if (instance.$route.query && instance.$route.query.categoryId) return instance.$route.query.categoryId as any;
		return instance.categories == null || instance.categories.length == 0 ? null : instance.categoriesSorted[0].id;
	}
	get selectedCategory(): categoryModel {
		var instance = this;
		if (instance.categories == null || instance.categories.length == 0) return new categoryModel();
		var c = instance.categories.find(x => instance.isCategorySelected(x));
		return c == null ? new categoryModel() : c;
	}
	isAnyChildrenCategorySelected(categories: categoryModel[]): boolean {
		var instance = this;
		if (instance.categoryId == null) return false;
		if (categories == null || categories.length == 0) return false;
		else {
			return categories.find(x => x.id == instance.categoryId || this.isAnyChildrenCategorySelected(x.subCategories)) != null;
		}
	}
	isCategorySelected(c: categoryModel): boolean {
		var instace = this;
		return c != null && (c.id == instace.categoryId || this.isAnyChildrenCategorySelected(c.subCategories));
	}
	onCategoryClick_Col1(c: categoryModel) {
		this.categoryId_col1 = c.id;
		this.categoryId_col2 = null;
	}
	categoryId_col1: number = null;
	get currentCategory_col1(): categoryModel {
		var instance = this;
		if (instance.categories == null || instance.categories.length == 0) return new categoryModel();
		var c = instance.categories.find(x => instance.categoryId_col1 == x.id);
		return c == null ? new categoryModel() : c;
	}

	get currentCategory_col1_subCategoriesSorted(): categoryModel[] {
		var instance = this;
		var cat = [];
		if (instance.currentCategory_col1 != null && instance.currentCategory_col1.subCategories != null) {
			cat = instance.currentCategory_col1.subCategories.sort((a, b) => (a.displayOrder >= b.displayOrder ? 1 : -1));
		}
		return cat;
	}
	onCategoryClick_Col2(c: categoryModel) {
		this.categoryId_col2 = c.id;
	}
	categoryId_col2: number = null;

	get currentCategory_col2(): categoryModel {
		var instance = this;
		if (instance.currentCategory_col1_subCategoriesSorted == null || instance.currentCategory_col1_subCategoriesSorted.length == 0) return new categoryModel();
		var c = instance.currentCategory_col1_subCategoriesSorted.find(x => instance.categoryId_col2 == x.id);
		return c == null ? new categoryModel() : c;
	}

	get currentCategory_col2_subCategoriesSorted(): categoryModel[] {
		var instance = this;
		var cat = [];
		if (instance.currentCategory_col2 != null && instance.currentCategory_col2.subCategories != null) {
			cat = instance.currentCategory_col2.subCategories.sort((a, b) => (a.displayOrder >= b.displayOrder ? 1 : -1));
		}
		return cat;
	}
	onCategorySelected(c: categoryModel): void {
		var instance = this;
		instance.onCategoryClick(c);
	}
	onCategoryClick(c: categoryModel): void {
		var instance = this;
		if (instance.$route.name != "catalog" || instance.$route.query.categoryId != (c == null ? null : c.id.toString()))
			instance.$router.push({
				name: "catalog",
				query: {
					categoryId: c == null ? null : c.id.toString()
				}
			});
		if (this.hasSubcategories(c)) {
			instance.$router.push({ path: `/categories/${c == null ? null : c.id}` });
		} else {
			instance.$router.push({
				name: "catalog",
				query: {
					categoryId: c == null ? null : c.id.toString()
				}
			});
		}
	}
	urlPath(c: categoryModel): string {
		//{ path: '/catalog', query: { categoryId: c.id } }
		if (this.hasSubcategories(c)) return `/categories/${c.id}`;
		return `/catalog/${c.id}`;
	}
}

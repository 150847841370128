import { Component, Prop, Watch } from "vue-property-decorator";
import axios, { AxiosPromise } from "axios";
import eventHub from "../../eventHub";
import {
  ShoppingCartModel,
  CartProductModel
} from "@/models/shoppingCartModel";
import { sisasListiniModel } from "@/models/sisasPimListiniModel";
import baseUserContextProductComponent from "../baseUserContextProductComponent";
import { umDefinition } from "@/models/umDefinition";
@Component({})
export default class cartProductChangeQuantityAndNote extends baseUserContextProductComponent {
  @Prop({ default: () => "card" })
  layout: string;
  @Prop({ default: () => new umDefinition() })
  listino: umDefinition;
  @Prop({ default: () => new CartProductModel() })
  product: CartProductModel;
  @Prop({ default: () => null })
  productId: number;
  @Watch("product", { deep: true })
  onProductChange(val: CartProductModel, oldVal: CartProductModel) {
    this.note = this.product.code2;
    // this.quantity = this.product.quantity * this.proporzione;
    this.quantity = this.product.quantity;
  }
  numListini: number = 0;
  isInEditQuantity: boolean = false;
  isSendingQuantity: boolean = false;
  isInEditNote: boolean = false;
  isSendingNote: boolean = false;
  isSendingDelete: boolean = false;
  note: string = null;
  quantity: number = null;

  get hasProduct(): boolean {
    return this.product != null && this.product.productId != null;
  }
  setEditNote() {
    this.isInEditNote = true;
    this.$emit("edit", this.isInEditQuantity || this.isInEditNote);
  }
  canelEditNote() {
    this.isInEditNote = false;
    this.note = this.product.code2;
    this.$emit("edit", this.isInEditQuantity || this.isInEditNote);
  }
  confirmEditNote() {
    var instance = this;
    instance.isSendingNote = true;
    axios
      .post<ShoppingCartModel>(`cart/addproduct`, {
        customerId: instance.getCurrentCustomerId,
        productId: instance.productId,
        quantity: this.product.quantity,
        um: instance.listino.um,
        note: this.note
      })
      .then(res => {
        instance.setCurrentCart(res.data);
        instance.$notify({
          title: "",
          text: "Le note sono state modificate",
          type: "success",
          group: "msg"
        });

        instance.isSendingNote = false;
        instance.isInEditNote = false;
        instance.$emit("edit", this.isInEditQuantity || this.isInEditNote);
      })
      .catch(error => {
        console.error(error);
        instance.isSendingNote = false;
        instance.canelEditNote();
      });
  }
  setEditQuantity() {
    this.isInEditQuantity = true;
    this.$emit("edit", this.isInEditQuantity || this.isInEditNote);
  }
  canelEditQuantity() {
    this.isInEditQuantity = false;
    // this.quantity = this.product.quantity * this.proporzione;
    this.quantity = this.product.quantity;
    this.$emit("edit", this.isInEditQuantity || this.isInEditNote);
  }
  confirmEditQuantity() {
    var instance = this;
    instance.isSendingQuantity = true;
    axios
      .post<ShoppingCartModel>(`cart/setproduct`, {
        customerId: instance.getCurrentCustomerId,
        productId: instance.productId,
        quantity: this.quantity,
        um: instance.listino.um,
        note: this.product.code2
      })
      .then(res => {
        instance.setCurrentCart(res.data);
        instance.$notify({
          title: "",
          text: "Carrello aggiornato correttamente",
          type: "success",
          group: "msg"
        });

        instance.isSendingQuantity = false;
        instance.isInEditQuantity = false;
        instance.$emit("edit", this.isInEditQuantity || this.isInEditNote);
      })
      .catch(error => {
        console.error(error);
        instance.isSendingQuantity = false;
        instance.canelEditQuantity();
      });
  }

  onRemove() {
    var instance = this;
    this.isSendingDelete = true;
    axios
      .post<ShoppingCartModel>(`cart/setproduct`, {
        customerId: instance.getCurrentCustomerId,
        productId: instance.productId,
        quantity: 0,
        um: instance.listino.um,
        note: this.product.code2
      })
      .then(res => {
        instance.setCurrentCart(res.data);
        instance.$notify({
          title: "",
          text: "Il prodotto è stato rimosso dal carrello",
          type: "success",
          group: "msg"
        });
        this.isSendingDelete = false;
      })
      .catch(error => {
        console.error(error);
        this.isSendingDelete = false;
      });
  }
  get proporzione(): number {
    var instance = this;
    // if (
    //   instance.listino == null ||
    //   instance.product == null ||
    //   instance.product.product == null
    // )
    if (instance.listino == null) return 1;
    return instance.listino.proporzione || 1;
  }
  // arrows + Backspace
  onBlurcheckQuantity(event: FocusEvent) {
    var v = parseInt(this.quantity as any);

    if (isNaN(parseInt(this.quantity as any))) {
      // this.quantity = this.product.quantity * this.proporzione;
      this.quantity = this.product.quantity;
    } else {
      var resto = v % this.proporzione;
      this.quantity = v - resto;
    }
  }
  allowKeyCodes = [37, 38, 39, 40, 8];
  allowKeys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
  onKeyDown($event: KeyboardEvent) {
    if (
      this.allowKeyCodes.indexOf($event.keyCode) == -1 &&
      this.allowKeys.indexOf($event.key) == -1
    ) {
      $event.preventDefault();
    }
  }
  increase() {
    if (this.quantity == null) this.quantity = 1 * this.proporzione;
    else this.quantity = this.quantity + 1 * this.proporzione;
  }
  decrease() {
    if (this.quantity == null) this.quantity = 1 * this.proporzione;
    else this.quantity = this.quantity - 1 * this.proporzione;
  }

  mounted() {
    this.note = this.product.code2;
    this.quantity = this.product.quantity;
  }
}

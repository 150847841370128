import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";
import { orderModel } from "@/models/orderModel";
@Component({})
export default class labelPaymentState extends Vue {
  @Prop({ default: () => new orderModel() })
  order: orderModel;
  get description(): string {
    var instance = this;
    if (instance.order != null && instance.order.paymentState == null)
      return "";
    if (instance.order.paymentState == "Completed") return "Evaso";
    if (instance.order.paymentState == "Received") return "Parzialmente evaso";
    if (instance.order.paymentState == "Pending") return "Non evaso";
    return "";
  }
  get css(): string {
    var instance = this;
    if (instance.order != null && instance.order.paymentState == null)
      return "";
    // if (instance.order.paymentState == "Completed") return "label-success";
    // if (instance.order.paymentState == "Received") return "label-danger";
    // if (instance.order.paymentState == "Pending") return "label-warning";
    // if (instance.order.paymentState == "Completed")
    //   return "label-sisas-order-payment-completed";
    // if (instance.order.paymentState == "Received")
    //   return "label-sisas-order-payment-received";
    // if (instance.order.paymentState == "Pending")
    //   return "label-sisas-order-payment-pending";
    if (instance.order.paymentState == "Completed")
      return "text-sisas-order-payment-completed";
    if (instance.order.paymentState == "Received")
      return "text-sisas-order-payment-received";
    if (instance.order.paymentState == "Pending")
      return "text-sisas-order-payment-pending";
    return "";
  }
}

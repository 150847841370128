import { Component, Prop, Watch } from "vue-property-decorator";
import axios, { AxiosPromise } from "axios";
import eventHub from "../../eventHub";
import {
  ShoppingCartModel,
  CartProductModel
} from "@/models/shoppingCartModel";
import { sisasListiniModel } from "@/models/sisasPimListiniModel";
import baseUserContextProductComponent from "../baseUserContextProductComponent";
import { umDefinition } from "@/models/umDefinition";
import { mapMutations } from "vuex";
@Component({})
export default class cartProductChangeQuantity extends baseUserContextProductComponent {


  @Prop({ default: () => null })
  productId: number;
  @Prop({ default: () => false })
  readonly: boolean;
  @Prop({ default: () => false })
  showPriceDetails: boolean;
  isInEditQuantity: boolean = false;
  isSendingQuantity: boolean = false;
  isSendingDelete: boolean = false;
  showWarningQuantity: boolean = false;


  isAddingProduct: boolean = false;
  @Prop({ default: [] })
  listini: sisasListiniModel[];
  // @Watch("item", { deep: true })
  // onItemChange(newVal: any) {
  //   this.setQuantity();
  // }
  @Prop({ default: 1 })
  quantity: number;
  @Watch("quantity", { deep: true })
  onQuantityChange(newVal: any) {
    this.myQuantity = this.quantity;
  }
  get umMinFattore(): number {
    var instance = this;
    var min = Math.min(...(instance.umsDefinitions || []).map(item => item.fattore));
    return min == Infinity ? 1 : min || 1;
  }
  get umsDefinitions(): umDefinition[] {
    return this.getListiniToUmDefinitions(this.listini, this.productId);
  }
  getQuantity(umDef: umDefinition): number {
    var instance = this;
    var residuo = instance.myQuantity || 0;

    var buffer = instance.umsDefinitions
      .slice()
      .sort((a, b) => (a.proporzione < b.proporzione ? 1 : -1));

    for (var i = 0; i < buffer.length; i++) {
      var x = buffer[i];
      var d = instance.umsDefinitions.find(u => u.um == x.um);

      var q = Math.floor(residuo / d.proporzione);
      if (q) residuo = residuo % d.proporzione;
      if (d.um == umDef.um) return q;
    }
    return 0;
  }
  get residuo(): number {
    var instance = this;
    var residuo = instance.myQuantity || 0;

    var buffer = instance.umsDefinitions
      .slice()
      .sort((a, b) => (a.proporzione < b.proporzione ? 1 : -1));

    for (var i = 0; i < buffer.length; i++) {
      var x = buffer[i];
      var d = instance.umsDefinitions.find(u => u.um == x.um);

      var q = Math.floor(residuo / d.proporzione);
      if (q) residuo = residuo % d.proporzione;
    }
    return residuo;
  }

  @Prop({ default: false })
  isLoading: boolean;
  @Prop({ default: false })
  isLoadingListini: boolean;
  @Prop({ default: false })
  showMostraOriginale: boolean;

  // @Prop({ default: () => new productModel() })
  @Prop({ default: () => null })
  item: ShoppingCartModel;
  myQuantity: number | null = 1;
  @Prop({ default: null })
  note: string | null;
  get isValidQuantity(): boolean {
    return this.myQuantity != null && this.myQuantity >= this.umMinFattore;
  }
  get enableDecrease(): boolean {
    return this.myQuantity != null && this.myQuantity > this.umMinFattore;
  }
  // arrows + Backspace
  allowKeyCodes = [37, 38, 39, 40, 8];
  allowKeys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
  onKeyDown($event: KeyboardEvent) {
    if (
      this.allowKeyCodes.indexOf($event.keyCode) == -1 &&
      this.allowKeys.indexOf($event.key) == -1
    ) {
      $event.preventDefault();
    }
  }
  onBlur() {
    if (this.residuo > 0) {
      this.showWarningQuantity = true;
      this.myQuantity = Number.parseInt(this.myQuantity as any) + (this.umMinFattore - this.residuo);
    }
    if (this.myQuantity < this.umMinFattore) {
      this.myQuantity = this.umMinFattore;
    }
    this.confirmEditQuantity();
  }
  increase() {
    if (this.myQuantity == null) this.myQuantity = this.umMinFattore;
    else this.myQuantity = Number.parseInt(this.myQuantity as any) + this.umMinFattore;
    // if (this.myQuantity == null) this.myQuantity = 1;
    // else this.myQuantity++;
    this.showWarningQuantity = false;
    this.confirmEditQuantity();
  }
  decrease() {
    if (this.myQuantity == null) this.myQuantity = this.umMinFattore;
    else this.myQuantity = Number.parseInt(this.myQuantity as any) - this.umMinFattore;
    // if (this.myQuantity == null) this.myQuantity = 1;
    // else this.myQuantity--;
    this.showWarningQuantity = false;
    this.confirmEditQuantity();
  }

  get totalForPice() {
    var instance = this;
    if (instance.myQuantity == null || instance.myQuantity == 0) return 0;

    let total = instance.total;
    if (total == null || total == 0) return 0;
    return total / instance.myQuantity;
  }
  get total() {
    var instance = this;
    if (instance.myQuantity == null || instance.myQuantity == 0) return 0;

    let total = 0;
    instance.umsDefinitions.forEach(
      // d => (total += (d.quantity || 0) * (d.prezzoUM || 0))
      d => (total += (instance.getQuantity(d) || 0) * (d.prezzoUM || 0))
    );
    return total;
  }
  get totalOriginale() {
    var instance = this;
    if (instance.myQuantity == null || instance.myQuantity == 0) return 0;

    let total = 0;
    instance.umsDefinitions.forEach(
      // d => (total += (d.quantity || 0) * (d.prezzoOriginaleUM || 0))
      d => (total += (instance.getQuantity(d) || 0) * (d.prezzoOriginaleUM || 0))
    );
    return total;
  }
  get anySconto(): boolean {
    var instance = this;
    return (
      instance.umsDefinitions &&
      instance.umsDefinitions.find(x => x.hasSconto) != null
    );
  }
  get hasRisparmio(): boolean {
    var instance = this;
    return instance.risparmio > 0;
  }
  get risparmio(): number {
    var instance = this;
    if (instance.umsDefinitions == null) return 0;
    var um = instance.umsDefinitions.find(x => x.fattore == 1);
    if (um == null) return 0;
    var prezzo = um.prezzoUM * instance.myQuantity;
    if (prezzo == instance.total) return 0;
    return ((prezzo - instance.total) * 100) / prezzo;
    // return 100 - ((instance.total * 100) / prezzo);
  }
  get hasListino() {
    var instance = this;
    return instance.item == null ||
      this.listini == null ||
      this.listini.length == 0
      ? false
      : true;
  }

  getQuantitaUM(um: string) {
    return this.myQuantity;
  }
  created() {
    this.myQuantity = this.quantity;
  }
  beforeMount() { }

  mounted() {
    var instance = this;
    // this.setQuantity();
  }
  alertNonSelezionatoClick() {
    var instance = this;
    instance.$emit('noProductToAdd');
    // instance.$notify({
    //   title: "Nessun prodotto selezionato",
    //   text: "Seleziona la configurazione",
    //   type: "warn",
    //   group: "msg"
    // });
  }


  setEditQuantity() {
    this.isInEditQuantity = true;
    this.$emit("edit", this.isInEditQuantity);
  }
  canelEditQuantity() {
    this.isInEditQuantity = false;
    // this.quantity = this.product.quantity * this.proporzione;
    this.myQuantity = this.quantity;
    this.$emit("edit", this.isInEditQuantity);
    this.showWarningQuantity = false;
  }
  confirmEditQuantity() {
    this.setPageLoading(true);
    var instance = this;
    instance.isSendingQuantity = true;
    this.$emit("sendingQuantity", instance.isSendingQuantity);
    axios
      // .post<ShoppingCartModel>(`cart/setproduct`, {
      //   customerId: instance.getCurrentCustomerId,
      //   productId: instance.productId,
      //   quantity: this.quantity,
      //   um: instance.listino.um,
      //   note: this.product.code2
      // })
      .post<ShoppingCartModel>(`cart/setproduct`, {
        customerId: instance.getCurrentCustomerId,
        productId: instance.productId,
        quantity: instance.myQuantity,
        note: instance.note
      })
      .then(res => {
        instance.setCurrentCart(res.data);
        instance.$notify({
          title: "",
          text: "Carrello aggiornato correttamente",
          type: "success",
          group: "msg"
        });

        instance.isSendingQuantity = false;
        instance.isInEditQuantity = false;
        // instance.$emit("edit", this.isInEditQuantity);
        this.$emit("sendingQuantity", instance.isSendingQuantity);
      })
      .catch(error => {
        console.error(error);
        instance.isSendingQuantity = false;
        this.$emit("sendingQuantity", instance.isSendingQuantity);
        instance.canelEditQuantity();
      }).finally(() => {

        instance.showWarningQuantity = false;
        instance.setPageLoading(false);
      });
  }

  onRemove() {
    var instance = this;
    this.isSendingDelete = true;
    axios
      .post<ShoppingCartModel>(`cart/setproduct`, {
        customerId: instance.getCurrentCustomerId,
        productId: instance.productId,
        quantity: 0,
      })
      .then(res => {
        instance.setCurrentCart(res.data);
        instance.$notify({
          title: "",
          text: "Il prodotto è stato rimosso dal carrello",
          type: "success",
          group: "msg"
        });
        this.isSendingDelete = false;
      })
      .catch(error => {
        console.error(error);
        this.isSendingDelete = false;
      });
  }
  get showPrezzoFooter(): boolean {
    return this.umsDefinitions != null && this.umsDefinitions.length > 1
  }
}

import { Component, Prop, Watch } from "vue-property-decorator";
import baseUserContextComponent from "../baseUserContextComponent";
import { categoryModel } from "@/models/categoryModel";
import { mediaType } from "@/models/mediaModel";
@Component({})
export default class categoryPanelNavBar1 extends baseUserContextComponent {
  @Prop({ default: true })
  show3rdLevel: boolean;
  @Prop({ default: () => new Array<categoryModel>() })
  categories: categoryModel[];
  get categoriesSorted(): categoryModel[] {
    var instance = this;
    var cat = [];
    if (instance.categories != null) {
      cat = instance.categories.sort((a, b) =>
        a.displayOrder > b.displayOrder ? 1 : -1
      );
    }
    return cat;
  }
  get isFull(): boolean {
    return false;
    // return (
    //   this.currentCategorySecondLevelToShow != null &&
    //   this.currentCategorySecondLevelToShow.subCategories != null &&
    //   this.currentCategorySecondLevelToShow.subCategories.length > 0
    // );
  }
  getPropery(category: categoryModel, property: string) {
    if (category == null || category.locales == null) return "";
    var l = category.locales["it-IT"];
    return l == null ? "" : l[property];
  }
  getImageUrl(category: categoryModel) {
    if (
      category == null ||
      category.media == null ||
      category.media.length == 0
    )
      return null;

    var media = category.media
      //.sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1))
      .find(x => x.mediaType == mediaType.Image && x.languageId == "it-IT");

    return media == null ? null : media.path;
  }
  hasSubcategories(category: categoryModel): boolean {
    return (
      category != null &&
      category.subCategories != null &&
      category.subCategories.length > 0
    );
  }
  isActive(c: categoryModel): boolean {
    var instance = this;
    return (
      c != null &&
      instance.selectedCategory != null &&
      c.id == instance.selectedCategory.id
    );
  }
  get categoryId(): number {
    var instance = this;
    if (instance.$route.query && instance.$route.query.categoryId)
      return instance.$route.query.categoryId as any;
    return instance.categories == null || instance.categories.length == 0
      ? null
      : instance.categoriesSorted[0].id;
  }
  get selectedCategory(): categoryModel {
    var instance = this;
    if (instance.categories == null || instance.categories.length == 0)
      return new categoryModel();
    var c = instance.categories.find(x => instance.isCategorySelected(x));
    return c == null ? new categoryModel() : c;
  }
  isAnyChildrenCategorySelected(categories: categoryModel[]): boolean {
    var instance = this;
    if (instance.categoryId == null) return false;
    if (categories == null || categories.length == 0) return false;
    else {
      return (
        categories.find(
          x =>
            x.id == instance.categoryId ||
            this.isAnyChildrenCategorySelected(x.subCategories)
        ) != null
      );
    }
  }
  isCategorySelected(c: categoryModel): boolean {
    var instace = this;
    return (
      c != null &&
      (c.id == instace.categoryId ||
        this.isAnyChildrenCategorySelected(c.subCategories))
    );
  }
  onCategoryOver(c: categoryModel) {
    if (this.categoryIdOver != c.id) this.categorySecondLevelIdOver = null;
    this.categoryIdOver = c.id;
  }
  onCategorySecondLevelOver(c: categoryModel) {
    this.categorySecondLevelIdOver = null; // c.id;
  }
  onCategoryLeave(c: categoryModel) {
    if (this.categoryIdOver == c.id) this.categoryIdOver = null;
  }
  categorySecondLevelIdOver: number = null;
  get currentCategoryIdSecondLevelToShow(): number {
    if (this.categorySecondLevelIdOver != null) {
      return this.categorySecondLevelIdOver;
    }
    return null;
  }
  // get currentCategorySecondLevelToShow(): categoryModel {
  //   var instance = this;
  //   if (
  //     instance.currentCategoryToShow.subCategories == null ||
  //     instance.currentCategoryToShow.subCategories.length == 0
  //   )
  //     return new categoryModel();
  //   var c = instance.currentCategoryToShow.subCategories.find(
  //     x => instance.currentCategoryIdSecondLevelToShow == x.id
  //   );
  //   return c == null ? new categoryModel() : c;
  // }
  categoryIdOver: number = null;
  get currentCategoryIdToShow(): number {
    if (this.categoryIdOver != null) {
      return this.categoryIdOver;
    }
    return null; // this.categoryId;
  }
  get currentCategoryToShow(): categoryModel {
    var instance = this;
    if (instance.categories == null || instance.categories.length == 0)
      return new categoryModel();
    var c = instance.categories.find(
      x => instance.currentCategoryIdToShow == x.id
    );
    return c == null ? new categoryModel() : c;
  }

  get currentCategoryToShowSubCategoriesSorted(): categoryModel[] {
    var instance = this;
    var cat = [];
    if (
      instance.currentCategoryToShow != null &&
      instance.currentCategoryToShow.subCategories != null
    ) {
      cat = instance.currentCategoryToShow.subCategories.sort((a, b) =>
        a.displayOrder >= b.displayOrder ? 1 : -1
      );
    }
    return cat;
  }
  onCategorySelected(c: categoryModel): void {
    var instance = this;
    instance.onCategoryClick(c);
  }
  onCategoryClick(c: categoryModel): void {
    var instance = this;
    if (
      instance.$route.name != "catalog" ||
      instance.$route.query.categoryId != (c == null ? null : c.id.toString())
    )
      instance.$router.push({
        name: "catalog",
        query: {
          categoryId: c == null ? null : c.id.toString()
        }
      });
  }
}

import { productModel, productType } from "@/models/productModel";
import { sisasListiniModel } from "@/models/sisasPimListiniModel";
import { familyOptionViewModel } from "@/models/familyOptionModel";
import _ from "lodash";
import { iProductOption, iProductOptionTyped } from "@/models/optionModel";
import { Component } from "vue-property-decorator";
import baseUserContextComponent from "./baseUserContextComponent";
import { umDefinition } from "@/models/umDefinition";

@Component({})
export default class baseUserContextProductComponent extends baseUserContextComponent {

  getUmsDefinition(p: productModel): umDefinition[] {
    var instance = this;
    if (p == null)
      return [];

    var umsDefinitions = new Array<umDefinition>();
    if (p.type == productType.Configurable) {
      (p.skus || []).forEach(s =>
        umsDefinitions.push(...instance.getListiniToUmDefinitions(s.listini, s.id))
      );
    }
    else
      umsDefinitions = instance.getListiniToUmDefinitions(p.listini, p.id);
    return umsDefinitions;
  }
  getListiniToUmDefinitions(
    listini: sisasListiniModel[],
    productId: number
  ): umDefinition[] {
    let instance = this;
    if (listini == null || listini.length == 0) return [];
    let all = listini.map(l => {
      var pr = instance.getProporzioneUmValue(l);

      var d = new umDefinition();
      d.productId = productId;
      d.um = l.um;
      // d.quantity = 0;
      d.hasSconto = instance.hasListinoSconto(l);
      d.prezzoOriginaleUM = l.prezzo;
      d.prezzoOriginaleUnitario =
        pr == 0 ? d.prezzoOriginaleUM : d.prezzoOriginaleUM / pr;
      d.prezzoUM = instance.getListinoPrezzoUM(l);
      d.prezzoUnitario = pr == 0 ? d.prezzoUM : d.prezzoUM / pr;
      //let f = Number.parseInt(l.fattore);
      let f = l.fattore;
      d.fattore = f || 0;
      d.proporzione = pr == 0 && listini.length == 1 ? 1 : pr;
      d.conversione = l.conversione;
      d.principale = l.principale;
      d.secondaria = l.secondaria;

      return d;
    });

    let umsDefinitions = new Array<umDefinition>();
    umsDefinitions = all.filter(
      x => x.proporzione != null && x.proporzione != 0 && x.prezzoUM != null
    );
    if (umsDefinitions.length == 0 && all.length > 0) {
      // supposizione di listino valido... forse non va bene
      let presuntoListino = _.sortBy(all, x => x.prezzoOriginaleUM)[0];
      var dpresunto = new umDefinition();
      dpresunto.productId = productId;
      dpresunto.um = presuntoListino.um;
      dpresunto.hasSconto = presuntoListino.hasSconto;
      dpresunto.prezzoOriginaleUM = presuntoListino.prezzoOriginaleUM;
      dpresunto.prezzoOriginaleUnitario = presuntoListino.prezzoOriginaleUM;
      dpresunto.prezzoUM = presuntoListino.prezzoUM;
      dpresunto.prezzoUnitario = presuntoListino.prezzoUM;
      dpresunto.fattore = 1;
      dpresunto.proporzione = 1;
      dpresunto.conversione = "*";
      dpresunto.principale = false;
      dpresunto.secondaria = false;
      umsDefinitions.push(dpresunto);
    }

    let d = umsDefinitions.find(x => x.fattore == 1);
    if (d != null) {
      let prezzoUnitario = d.prezzoUnitario;
      // let um = d.um;
      umsDefinitions = umsDefinitions.filter(
        x => x.fattore == 1 || x.prezzoUnitario < prezzoUnitario
      );
    }
    umsDefinitions = _.sortBy(umsDefinitions, x => x.prezzoUnitario);
    return umsDefinitions;
  }
  getListino(p: productModel, um: string): sisasListiniModel {
    if (p == null || p.listini == null) return null;

    var listino = p.listini.find(l => l.um == um);
    return listino;
  }
  hasAnyListino(p: productModel): boolean {
    var self = this;
    if (p.type == productType.Configurable) {
      if (p.skus == null || p.skus.length == 0)
        return false;
      p.skus.some(x => {
        let ums = self.getUmsDefinition(x);
        return ums != null && ums.length > 0;
      })
    }
    else {
      let ums = this.getUmsDefinition(p);
      return ums != null && ums.length > 0;
    }
  }
  hasListinoSconto(listino: sisasListiniModel): boolean {
    return listino != null && listino.sconto1 != null && listino.sconto1 != 0;
  }
  getProductTotalPrice(p: productModel, um: string) {
    var instance = this;
    if (p == null) return 0;
    var listino = instance.getListino(p, um);
    if (listino == null) return " -";
    var total = listino.prezzo;
    if (listino.sconto1 != null && listino.sconto1 != 0) {
      //var sconto1 = Math.abs(listino.sconto1);
      var sconto1 = listino.sconto1;
      var s = (total * sconto1) / 100;
      // total = total - s;
      total = total + s;
    }
    if (listino.sconto2 != null && listino.sconto2 != 0) {
      // var sconto2 = Math.abs(listino.sconto2);
      var sconto2 = listino.sconto2;
      var s = (total * sconto2) / 100;
      // total = total - s;
      total = total + s;
    }
    return total;
  }
  getListinoPrezzoUM(l: sisasListiniModel): number {
    if (l == null || l.prezzo == null || l.prezzo == 0) return 0;
    var total = l.prezzo;
    if (l.sconto1 != null && l.sconto1 != 0) {
      // var sconto1 = Math.abs(l.sconto1);
      var sconto1 = l.sconto1;
      var s = (total * sconto1) / 100;
      // total = total - s;
      total = total + s;
    }
    if (l.sconto2 != null && l.sconto2 != 0) {
      // var sconto1 = Math.abs(l.sconto2);
      var sconto2 = l.sconto2;
      var s = (total * sconto2) / 100;
      // total = total - s;
      total = total + s;
    }
    return total;
  }

  getProporzioneUm(product: productModel, um: string): string {
    // if (um != "CNF") return "";
    // var f = Number.parseInt(this.getOptionValue("ERP_FATTORE", product));
    // if (Number.isNaN(f)) f = null;

    // return f == null || f == 0 ? "" : `${f}x`;
    let l = this.getListino(product, um);
    let p = this.getProporzioneUmValue(l);
    return p == null || p == 0 ? "" : `${p}x`;
  }

  getProporzioneUmValue(l: sisasListiniModel): number {
    if (l == null || l.fattore == null) return 0;

    // let f = Number.parseInt(l.fattore);
    let f = l.fattore;
    if (f == null || f == 0) return 0;
    if (l.conversione == "*") f = 1 / f;
    return f;
    // if (um == "NR") return 1;
    // if (um != "CNF") return 0;
    // var f = Number.parseInt(this.getOptionValue("ERP_FATTORE", product));
    // if (Number.isNaN(f)) f = null;

    // return f == null || f == 0 ? 0 : f;
  }

  getProductTotalPriceByListino(p: productModel, listino: sisasListiniModel) {
    var instance = this;
    if (p == null) return 0;
    if (listino == null) return " -";
    var total = listino.prezzo;
    if (listino.sconto1 != null && listino.sconto1 != 0) {
      // var sconto1 = Math.abs(listino.sconto1);
      var sconto1 = listino.sconto1;
      var s = (total * sconto1) / 100;
      // total = total - s;
      total = total + s;
    }
    if (listino.sconto2 != null && listino.sconto2 != 0) {
      // var sconto2 = Math.abs(listino.sconto2);
      var sconto2 = listino.sconto2;
      var s = (total * sconto2) / 100;
      // total = total - s;
      total = total + s;
    }
    return total;
  }

  getVariantCodes(product: productModel): Array<string> {
    var instance = this;
    if (
      product == null ||
      product.variantsValues == null ||
      product.familyOptions == null
    )
      return [];

    var variants = product.familyOptions.filter(x => x.isVariant);
    if (variants == null) var definition = [];
    return variants.map(x => x.code);
  }
  getVariantDefinitions(product: productModel): Array<familyOptionViewModel> {
    var instance = this;
    if (
      product == null ||
      product.variantsValues == null ||
      product.familyOptions == null
    )
      return [];

    var variants = product.familyOptions.filter(x => x.isVariant);
    if (variants == null) return []; // var definition = [];
    return variants.sort((a, b) => {
      return a.groupDisplayOrder > b.groupDisplayOrder
        ? 1
        : a.groupDisplayOrder > b.groupDisplayOrder
          ? -1
          : a.displayOrder > b.displayOrder
            ? 1
            : -1;
    });
  }
  getSkusProducts(product: productModel): productModel[] {
    var instance = this;
    if (product == null || product.skus == null) return [];
    // : instance.master.skus.filter(x => x.sku != instance.item.sku);
    var orderdSkus = product.skus;
    var length = instance.getVariantDefinitions(product).length;
    var sortField = [];
    for (var index = 0; index < length; index++) {
      var variant = instance.getVariantDefinitions(product)[index];
      sortField.push("variantsLocalized[" + variant.code + "]");
    }
    var result = _.sortBy(orderdSkus, sortField as Array<string>);
    return result.sort((a, b) =>
      a.isTopProduct && b.isTopProduct ? 0 : a.isTopProduct ? -1 : 1
    );
    //    return orderdSkus;
  }
  getOptionLabel(p: productModel, optionName: string): string {
    var instance = this;
    if (p == null || p.familyOptions == null) return null;
    var opt = p.familyOptions.find(x => x.code == optionName);
    return opt == null ? null : opt.name;
  }

  getMinPriceUMDefiition(p: productModel): number {
    var instance = this;
    if (p == null) return null;
    var umsDef = instance.getUmsDefinition(p);
    if (umsDef == null || umsDef.length == 0) return null;
    var minProd = umsDef
      .filter(x => x.prezzoUnitario != null)
      .sort((a, b) => (a.prezzoUnitario > b.prezzoUnitario ? 1 : -1))[0];

    return minProd == null ? null : minProd.prezzoUnitario;
  }
  getMaxPriceUMDefiition(p: productModel): number {
    var instance = this;
    if (p == null) return null;
    var umsDef = instance.getUmsDefinition(p);
    if (umsDef == null || umsDef.length == 0) return null;
    var maxProd = umsDef
      .filter(x => x.prezzoUnitario != null)
      .sort((a, b) => (a.prezzoUnitario < b.prezzoUnitario ? 1 : -1))[0];

    return maxProd == null ? null : maxProd.prezzoUnitario;
  }
  hasOptionValue(optionName: string, product: productModel): boolean {
    if (product == null && product.options == null) return false;
    var opt = product.options.find(x => x.optionCode == optionName);
    if (opt == null) return false;
    if (opt.type == "Text" || opt.type == "TextArea" || opt.type == "TextHTML")
      return opt.value == null ||
        (opt as any).value[0] == null ||
        (opt as any).value[0] == "" ||
        (opt as any).value[0].value == null ||
        (opt as any).value[0].value == "" ||
        (opt.type == "TextHTML" &&
          (opt as any).value[0].value == "<p>&nbsp;</p>")
        ? false
        : true;
    return opt.value != null;
  }
  getOptionValue(optionName: string, product: productModel): string {
    if (product == null && product.options == null) return "";
    var opt = product.options.find(x => x.optionCode == optionName);
    if (opt == null) return "";

    if (opt.type == "Text" || opt.type == "TextArea" || opt.type == "TextHTML")
      return opt.value == null || (opt as any).value[0] == null
        ? ""
        : (opt as any).value[0].value;
    return opt.value;
  }
  getOptionType(optionName: string, product: productModel): string {
    if (product == null && product.options == null) return "";
    var opt = product.options.find(x => x.optionCode == optionName);
    if (opt == null) return "";
    return opt.type;
  }
  getOptionIconografie(product: productModel): iProductOption[] {
    if (product == null && product == null) return [];
    var opts = product.options.filter(x => x.type == "Iconografia");
    return opts == null ? [] : opts;
  }
  isNoteRichieste(product: productModel): boolean {
    return this.getOptionValue("00032", product).trim() != '';
  }
  getLabelNoteRichieste(product: productModel): string {
    return this.getOptionValue("00032", product)
  }
  isFileRichiesto(product: productModel): boolean {
    if (product == null && product == null) return false;
    var opt = product.options.find(x => x.optionCode == "00028");
    return opt != null && opt.value == true;
  }
}

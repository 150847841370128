import { Component } from "vue-property-decorator";
import { Route } from "vue-router";
import PageOptions from "../config/PageOptions";
import baseUserContextPage from "./baseUserContextPage";

@Component({
  beforeRouteEnter(route, redirect, next) {
    PageOptions.pageEmpty = true;
    document.body.className = "pt-0";
    next();
  },
  beforeRouteLeave(route, redirect, next) {
    PageOptions.pageEmpty = false;
    document.body.className = "";
    next();
  }
})
export default class baseUserContextEmptyPage extends baseUserContextPage {

}

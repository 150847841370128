import { orderDetailModel, sisasDocumentiRighe } from "./orderDetailModel";
import { addressModel } from "./addressModel";
import { sisasDocumentiEvasione } from "./sisasDocumentiEvasione";
import { CustomerInfoModel } from "./customerModels";

export enum orderState {
  New = "New",
  Processing = "Processing",
  Invoiced = "Invoiced",
  OnHold = "OnHold",
  Completed = "Completed",
  Cancelled = "Cancelled",
  Shipping = "Shipping"
}
export class orderModel {
  constructor() {
    this.id = null;
    this.siteId = null;
    this.orderSource = null;
    this.orderSourceId = null;
    this.createBy = null;
    this.code = null;
    this.createDate = null;
    this.updateDate = null;
    this.updateBy = null;
    this.currencyCode = null;
    this.paymentMethod = null;
    this.netTotal = null;
    this.taxTotal = null;
    this.total = null;
    this.shippingTotal = null;
    this.grandTotal = null;
    this.paid = null;
    this.notes = null;
    this.customer_Id = null;
    // this.account: AccountModel;
    this.billing = new addressModel();
    this.shippingsType = null;
    this.shippingsPrice = null;
    this.paymentType = null;
    this.orderState = orderState.New;
    this.orderStatus = null;
    this.paymentState = null;
    this.paymentStatus = null;
    // this.shippingState: ShippingState;
    this.shippingStatus = null;
    this.orderAddresses = [];
    this.orderDetails = [];
    this.orderCustomer = new CustomerInfoModel();
    this.note = null;
    this.customer_ReferenceCode = null;
  }
  id: number;
  siteId: number;
  orderSource: string;
  orderSourceId: string;
  createBy: string;
  code: string;
  createDate: Date | string | null;
  updateDate: Date | string | null;
  updateBy: string;
  currencyCode: string;
  paymentMethod: string;
  netTotal: number | null;
  taxTotal: number | null;
  total: number | null;
  shippingTotal: number | null;
  grandTotal: number | null;
  paid: number | null;
  notes: string;
  customer_Id: number | null;
  // account: AccountModel;
  billing: addressModel;
  shippingsType: string;
  shippingsPrice: number;
  paymentType: string;
  orderState: orderState;
  orderStatus: string;
  paymentState: string;
  paymentStatus: string;
  // shippingState: ShippingState;
  shippingStatus: string;
  orderAddresses: addressModel[];
  orderDetails: orderDetailModel[];
  orderCustomer: CustomerInfoModel;
  note: string;
  customer_ReferenceCode: string;
}

export class sisasOrderModel extends orderModel {
  constructor() {
    super();
    this.sisasDocumentiTestata = new sisasDocumentiTestata();
    this.sisasDocumentiRighe = [];
    this.sisasDocumentiEvasioni = [];

  }
  sisasDocumentiTestata: sisasDocumentiTestata;
  sisasDocumentiRighe: sisasDocumentiRighe[];
  sisasDocumentiEvasioni: sisasDocumentiEvasione[];
}

export class sisasDocumentiTestata {
  constructor() {
    this.chiavedoc = null;
    this.esecomp = null;
    this.classedoc = null;
    this.flagveac = null;
    this.flagprovv = null;
    this.numdoc = null;
    this.seriedoc = null;
    this.datadoc = null;
    this.dataevtes = null;
    this.tipointe = null;
    this.codinte = null;
    this.desinte = null;
    this.codagE1 = null;
    this.codagE2 = null;
    this.codpag = null;
    this.valuta = null;
    this.scontoT1 = null;
    this.scontoT2 = null;
    this.scontopag = null;
    this.codsped = null;
    this.codvett = null;
    this.codporto = null;
    this.pesonetto = null;
    this.pesolordo = null;
    this.numcolli = null;
    this.volume = null;
    this.aspetto = null;
    this.numpallet = null;
    this.descvettore = null;
    this.descsped = null;
    this.descporto = null;
  }

  chiavedoc: string;
  esecomp: number;
  classedoc: string;
  flagveac: string;
  flagprovv: string;
  numdoc: string;
  seriedoc: string;
  datadoc: string;
  dataevtes: string;
  tipointe: string;
  codinte: string;
  desinte: string;
  codagE1: string;
  codagE2: string;
  codpag: string;
  valuta: string;
  scontoT1: string;
  scontoT2: string;
  scontopag: string;
  codsped: string;
  codvett: string;
  codporto: string;
  pesonetto: string;
  pesolordo: string;
  numcolli: string;
  volume: string;
  aspetto: string;
  numpallet: string;
  descvettore: string;
  descsped: string;
  descporto: string;
}

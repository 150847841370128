import { Component, Prop, Watch } from "vue-property-decorator";
import axios from "axios";
import baseUserContexetProductComponent from "@/components/baseUserContextProductComponent";
import {
  productModel,
  productType,
  productSelectorResult
} from "@/models/productModel";
var Qs = require("qs");
@Component({})
export default class addToCartSlideModalProduct extends baseUserContexetProductComponent {
  selectedOptions: any = null;
  markNotSelected: boolean = false;
  @Prop({ default: false })
  loadSkus: boolean;
  @Prop({ default: null })
  prefilledId: number;
  @Prop({ default: () => false })
  isLoadingProduct: boolean;
  @Prop({ default: () => false })
  isLoadingListini: boolean;
  @Prop({ default: false })
  value: boolean;
  @Prop({ default: () => [] })
  selectedFilters: any[];
  allFiltersSelected: boolean = false;
  @Watch("value")
  onValueChange(newVal, oldVal) {
    var instance = this;
    // if (newVal && this.product != null && this.product.type != "Configurable")
    //   this.onSelectProduct(this.product);
    // this.selected = null;
    // if (this.product != null && this.product.type != "Configurable")
    //   this.onSelectProduct(this.product);
    // instance.reset();
    instance.markNotSelected = false;
    instance.selectedOptions = null;
  }
  isLoading: boolean = false;
  get isLoadingInfo(): boolean {
    return this.isLoading || this.isLoadingProduct;
  }
  get show(): boolean {
    return this.value;
  }
  set show(event) {
    this.$emit("input", event);
  }
  get showVaiAldDettaglio(): boolean {
    var instance = this;
    if (instance.product == null || instance.$route.path == `/product/${instance.product.id}`)
      return false;
    return true;
  }
  onVaiAlDettaglio(e: Event) {
    var instance = this;
    if (instance.showVaiAldDettaglio && instance.product != null) {
      instance.show = false;
      var q = instance.queryParams;
      instance.$router.push({
        path: `/product/${instance.product.id}`,
        query: q
      });

    }
  }
  get showProductSelector(): boolean {
    return (
      this.product != null && this.product.type == productType.Configurable
    );
  }
  get currentProduct(): productModel {
    return this.selectedProduct == null ? this.product : this.selectedProduct;
  }
  get currentProductMainImage(): string {
    return this.selectedProduct != null &&
      this.selectedProduct.mainImage != null &&
      this.selectedProduct.mainImage != ""
      ? this.selectedProduct.mainImage
      : this.product != null &&
        this.product.mainImage != null &&
        this.product.mainImage != ""
        ? this.product.mainImage
        : "";
  }
  @Prop({ default: () => new productModel() })
  product: productModel;
  item: productModel = null;
  selectedProduct: productModel = null;
  @Watch("product", { deep: true })
  onProductChange(newVal, oldVal) {
    var instance = this;
    instance.item = Object.assign({}, instance.product);
    if (instance.item != null && instance.item.type != "Configurable") {
      var p = new productSelectorResult();
      p.allFiltersSelected = true;
      p.product = instance.item;
      instance.onSelectProduct(p);
    } else instance.onSelectProduct(new productSelectorResult());
    if (instance.item != null && instance.item.type == "Configurable") {
      instance.fetch();
    }
  }
  @Prop({ default: 1 })
  quantity: number | null;
  @Prop({ default: null })
  defaultum: string | null;

  get subTitle(): string {
    if (this.currentProduct == null) return "";
    if (this.currentProduct.type != productType.Configurable)
      return this.currentProduct.sku;
    // return `(${this.currentProduct.skus.length} Prodotti)`;
    return this.currentProduct.sku;
  }

  get title(): string {
    if (this.currentProduct != null && this.currentProduct.name != null)
      return this.currentProduct.name["it-IT"];
    return "";
  }

  onSelectProduct(productSelect: productSelectorResult) {
    var instance = this;
    instance.selectedProduct = productSelect.product;
    instance.allFiltersSelected = productSelect.allFiltersSelected;
  }
  onCloseModal() {
    this.$emit("input", false);
  }
  onProductAdded(product: productModel) {
    this.onCloseModal();
  }
  mounted() { }
  fetch(): void {
    var instance = this;
    instance.isLoading = true;

    if (instance.item != null && instance.item.id != null) {
      axios
        .get<any>(`/product/info`, {
          params: {
            productId: instance.item.id,
            customerId: instance.getCurrentCustomerId,
            loadListini: true
          }
        })
        .then(res => {
          instance.item = res.data ? res.data : new productModel();
          instance.isLoading = false;
          // instance.fetchListini();
        })
        .catch(error => {
          console.error(error);
          instance.isLoading = false;
        });
    }
  }
  onNoProductToAdd() {

    var instance = this;
    instance.markNotSelected = true;
    // instance.$notify({
    //   title: "Nessun prodotto selezionato",
    //   text: "Seleziona la configurazione",
    //   type: "warn",
    //   group: "msg"
    // });
  }
  onSelectedOptions(selected: any) {
    this.selectedOptions = selected;
  }
  get queryParams(): any {
    var instance = this;
    if (instance.selectedOptions == null)
      return null;
    var f = Qs.stringify(instance.selectedOptions);
    var q = {
      filters: f == null || f == "" ? undefined : f
    };
    return q;
  }
}

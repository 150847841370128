import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";
import { sisasListiniModel } from "@/models/sisasPimListiniModel";
import baseUserContextComponent from "./baseUserContextComponent";
@Component({})
export default class labelScontiListino extends baseUserContextComponent {
  @Prop({ default: () => null })
  listino: sisasListiniModel;
  @Prop({ default: () => false })
  longDescription: boolean;
  @Prop({ default: () => "" })
  size: string;
  get showLabel(): boolean {
    var instance = this;
    return (
      instance.listino != null &&
      instance.listino.sconto1 != null &&
      instance.listino.sconto1 != 0
    );
  }
  get description(): string {
    var instance = this;
    if (instance.showLabel == false || instance.getCurrentCustomer == null)
      return "";
    if (instance.longDescription == false) return "Prezzo riservato";
    if (instance.getCurrentCustomer.type == "Company")
      return `Prezzo riservato ${instance.getCurrentCustomer.company}`;
    return `Prezzo riservato ${instance.getCurrentCustomer.lastName}`;
  }
}

export class UserLoginModel {
  constructor() {
    this.username = "";
    this.password = "";
  }
  public username: string;
  public password: string;
}

export class UserLoggedInfoModel {
  constructor() {
    this.id = null;
    this.firstName = "";
    this.lastName = "";
    this.defaultLanguage = "";
    this.signInName = "";
    this.emailAddress = "";
    this.roles = [];
  }
  public id: number;
  public firstName: string;
  public lastName: string;
  public defaultLanguage: string;
  public signInName: string;
  public emailAddress: string;
  public roles: string[];
}
























































































import { Component, Prop, Watch } from "vue-property-decorator";
import { productModel, productType } from "@/models/productModel";
import { sisasListiniModel } from "@/models/sisasPimListiniModel";
import _ from "lodash";
import baseUserContextProductComponent from "../baseUserContextProductComponent";
// var Qs = require("qs");

@Component({})
export default class productRow extends baseUserContextProductComponent {
	addToCartIsOpen: boolean = false;
	@Prop({ default: () => new productModel() })
	product: productModel;
	@Prop({ default: () => null })
	spreselectedId: number;
	@Prop({ default: () => null })
	categoryId: number;

	@Prop({ default: () => false })
	isLoadingListini: boolean;
	@Prop({ default: () => "" })
	size: string;
	@Prop({ default: () => false })
	hideAddToCart: boolean;

	get isSmall(): boolean {
		return this.isMobile || this.size == "sm";
	}
	get queryParams(): any {
		var instance = this;
		var params = instance.$route.query;
		var q = {};
		// if (params && params.categoryId != undefined && params.categoryId != null) {
		//   q["categoryId"] = params.categoryId;
		// }
		if (params && params.filters != undefined && params.filters != null) {
			q["filters"] = params.filters;
		}
		return q;
	}
	getRouteParams(product: productModel) {
		var instance = this;
		if (instance.spreselectedId != null && product.type == productType.Configurable && product.skus != null && product.skus.length != 0) {
			var p = product.skus.find(x => x.id == instance.spreselectedId);
			if (p != null)
				return {
					id: instance.spreselectedId,
					categoryId: instance.categoryId
				};
		}
		return {
			id: product.id,
			categoryId: instance.categoryId
		};
	}
	onCustomerSelected(customer: any) {
		//this.$emit("customerSelected", customer);
	}

	getDetailUrl(product: productModel): string {
		var instance = this;
		//   var categoryUrl = instance.urlCategoryId == null ? "" : `?categoryId=${instance.urlCategoryId}`;

		//   var filtersUrl = ""; // instance.urlFilters == null ? "" : (categoryUrl == "" ? "?filters=" : "&filters=") + Qs.stringify(instance.urlFilters);

		//   if (instance.$route.query && instance.$route.query.filters != undefined && instance.$route.query.filters != null) {
		//    var obj = Qs.parse(instance.$route.query.filters);
		//    var stringObj = Qs.stringify(obj);
		//    filtersUrl = `${categoryUrl == "" ? "?filters=" : "&filters="}${stringObj}`;
		//   }
		//   if (product.type != productType.Configurable || product.skus == null || product.skus.length == 0) return `/product/${product.id}${categoryUrl}${filtersUrl}`;
		//   return `/product/${product.skus[0].id}${categoryUrl}${filtersUrl}`;
		// if (
		//   product.type != productType.Configurable ||
		//   product.skus == null ||
		//   product.skus.length == 0
		// )
		if (instance.spreselectedId != null && product.type == productType.Configurable && product.skus != null && product.skus.length != 0) {
			var p = product.skus.find(x => x.id == instance.spreselectedId);
			if (p != null) return `/product/${instance.categoryId}/${instance.spreselectedId}`;
		}
		return `/product/${instance.categoryId}/${product.id}`;
		// return `/product/${product.skus[0].id}`;
	}

	get subTitle(): string {
		if (this.product == null) return "";
		if (this.product.type != productType.Configurable) return `Codice ${this.product.sku}`;
		// return `(${this.product.skus.length} Prodotti)`;
		return `Codice ${this.product.sku}`;
		// return ``;
	}
	get lblVersioni(): string {
		if (this.product == null) return "";
		if (this.product.type != productType.Configurable) return ``;
		return `Disponibile in ${this.product.skus.length} versioni`;
		// return ``;
	}
	get btnAddToCartText(): string {
		if (this.product == null) return "";
		if (this.product.type != productType.Configurable) return "Aggiungi al carrello";
		// return `Scegli tra le ${this.product.skus.length} versioni`;
		return `vai alle versioni`;
	}

	get listino_um_principale(): sisasListiniModel {
		if (this.product == null || this.product.listini == null) return null;

		var listino = this.product.listini.find(l => l.principale);
		return listino;
	}

	mounted() {}
	onSelect() {
		this.$emit("select", this.product);
	}
}

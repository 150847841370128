export class pagedList<T> {
  constructor() {
    currentPage: null;
    pageIndex: null;
    pageSize: null;
    totalCount: null;
    totalPages: null;
    previousPage: null;
    nextPage: null;
    items: [];
    hasPreviousPage: false;
    hasNextPage: false;
  }
  currentPage: number;
  pageIndex: number;
  pageSize: number;
  totalCount: number;
  totalPages: number;
  previousPage: number;
  nextPage: number;
  items: Array<T>;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}
export enum sortDirection {
  ASC = "ASC",
  DESC = "DESC"
}
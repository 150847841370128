//
// Summary:
//     Types of the service messages
export enum MessageType {
  //
  // Summary:
  //     Generic info message
  info = "Info",
  //
  // Summary:
  //     Warning message
  warning = "Warning",
  //
  // Summary:
  //     Error message
  error = "Error"
}

export interface ResponseMessage {
  //
  // Summary:
  //     Property originating the message
  propertyName: string;
  //
  // Summary:
  //     Code of the message
  code: string;
  //
  // Summary:
  //     Content of the message
  text: string;
  //
  // Summary:
  //     Type of the message
  type: MessageType;
}

import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";
import axios, { AxiosPromise } from "axios";
import {
  optionAttachmentModel,
  attachmentValueModel
} from "@/models/optionModel";
import baseUserContextComponent from "../baseUserContextComponent";
@Component({})
export default class optAllegati extends baseUserContextComponent {
  @Prop({ default: () => [] })
  allegati: optionAttachmentModel[];

  get allegatiList(): attachmentValueModel[] {
    var instance = this;
    if (instance.allegati == null || instance.allegati.length == 0) return [];
    var opts = instance.allegati.filter(o => o.languageId == "it-IT");
    if (opts == null || opts.length == 0) return [];
   
    return opts
      .map(o => o.value)
      .sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1));
  }
  getIcon(a: attachmentValueModel): string {
    if (a.path == null) return "";
    if (a.path.toLowerCase().endsWith(".pdf")) return "far fa-file-pdf";
    if (
      a.path.toLowerCase().endsWith(".doc") ||
      a.path.toLowerCase().endsWith(".docx")
    )
      return "far fa-file-word";
    if (
      a.path.toLowerCase().endsWith(".xls") ||
      a.path.toLowerCase().endsWith(".xlsx")
    )
      return "far fa-file-excel";
    return "far fa-file";
  }
  downloadItem(path: string) {
    var instance = this;

    axios({
        url: "/storage/downloadfile",
        method: 'GET',
        responseType: 'blob',
        params: {
            path: path
        }
    })
        .then(res => {
            let link = document.createElement('a');

            // Extract the file name from the content disposition of the response, 
            // This section should be centralized somewhere
            let contentDisposition = res.request.getResponseHeader('Content-Disposition');
            let tokens = contentDisposition.split(";");

            tokens.forEach(e => {
                if (e.indexOf("filename=") != -1) {
                    link.download = e.split("=")[1].replace(/"/g, "");
                }
            });

            let blob = new Blob([res.data], { type: 'application/octet-stream' });

            link.href = window.URL.createObjectURL(blob);
            link.click();
        })
        .catch(error => console.error(error));
};
}

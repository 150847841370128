// #region <FILTERS OPTIONS>
export class optionModel {
  constructor() {
    this.code = null;
    this.languageId = null;
    this.name = null;
    this.type = null;
    this.values = [];
  }

  code: string;

  type: string;

  languageId: string;

  name: string;

  values: optionValueModel[];
}

export class optionValueModel {
  constructor() {
    this.code = null;
    this.languageId = null;
    this.name = null;
    this.selected = false;
    this.isValidSelection = true;
  }
  code: string;

  languageId: string;

  name: string;

  //added for javascript purpose
  selected: boolean;
  isValidSelection: boolean;
}

export class optionAndSingleValueModel {
  constructor() {
    this.option = new optionModel();
    this.value = new optionValueModel();
  }
  option: optionModel;
  value: optionValueModel;
}
// #endregion  <FILTERS OPTIONS>

// #region <PRODUCT OPTIONS>
export interface iProductOption {
  optionCode: string;
  type: string;
  value: any;
}
export interface iProductOptionTyped<T> extends iProductOption {
  value: T;
}

export class localizedProductOptionValueModel<T> {
  constructor() {
    this.languageId = null;
    this.value = null;
  }

  languageId: string;

  value: T;
}
export class productOptionModel<T> implements iProductOptionTyped<T> {
  constructor() {
    this.optionCode = "";
    this.type = "";
    this.value = null;
  }
  public optionCode: string;
  public type: "";
  public value: T;
}
export class optionIconografiaModel extends productOptionModel<
  localizedProductOptionValueModel<iconografiaValueModel>[]
> {
  constructor() {
    super();
    this.value = [];
  }
}
export class iconografiaValueModel {
  constructor() {
    this.code = null;
    this.description = null;
    this.label = null;
    this.displayOrder = null;
    this.relativePath = null;
  }

  code: string;

  description: string;

  displayOrder: number;

  label: string;

  relativePath: string;
}
export class optionAttachmentModel extends 
  localizedProductOptionValueModel<attachmentValueModel>{
  constructor() {
    super();
  }
}
export class attachmentValueModel {
  constructor() {
    this.description = null;
    this.displayOrder = null;
    this.path = null;
    this.size = null;
  }
  description: string;
  displayOrder: number;
  path: string;
  size: number;
}
// #endregion <PRODUCT OPTIONS>

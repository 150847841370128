
(window as any).$ = require('jquery')
  (window as any).JQuery = require('jquery')
import 'bootstrap';
import Vue from "vue";
import VueCompositionAPI from '@vue/composition-api';
// import thema from "./main_thema";
import thema_v2 from "./main_thema_v2";
import main_registrations from "./main_registrations";
import numFormat from "vue-filter-number-format";
import numeralIt from "numeral/locales/it";
import "./filters";
import "./directives";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import Qs from "qs";
import eventHub from "./eventHub";

numeralIt;
Vue.filter("numFormat", numFormat);

Vue.use(VueCompositionAPI);

Vue.config.productionTip = false;

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;// "/api";

//axios.defaults.headers.common['Authorization'] = 'bearer ' + localStorage.getItem("ConvPortal-Token");
//axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

// axios.defaults.headers.get["Accepts"] = "application/json";

axios.interceptors.request.use(config => {
  config.headers.common["Authorization"] =
    "bearer " + localStorage.getItem("ConvPortal-Token");

  config.paramsSerializer = params => {
    // Qs is already included in the Axios package
    return Qs.stringify(params, {
      arrayFormat: "repeat"
    });
  };

  return config;
});
axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    eventHub.$emit(eventHub.events.AXIOS_RESPONSE_ERROR, error);
    return Promise.reject(error);
  }
);

thema_v2.init();
main_registrations.init();
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

import { render, staticRenderFns } from "./splashScreen.vue?vue&type=template&id=adfc9256&scoped=true&"
import script from "./splashScreen.vue.ts?vue&type=script&lang=ts&"
export * from "./splashScreen.vue.ts?vue&type=script&lang=ts&"
import style0 from "./splashScreen.vue?vue&type=style&index=0&id=adfc9256&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "adfc9256",
  null
  
)

export default component.exports


































































// import Vue from 'vue'
import { Component, Prop, Watch } from "vue-property-decorator";
import baseUserContextPage from "../baseUserContextComponent";
var Qs = require("qs");

@Component({})
export default class assistenzaForm extends baseUserContextPage {
	firstname: string = "";
	lastname: string = "";
	company: string = "";
	email: string = "";
	message: string = "";
	privacy: boolean = true;
	marketing: boolean = false;
	myreason: string = "";
	@Prop({ default: () => "" })
	reason: string;

	sku: string = null;
	name: string = null;

	beforeMount() {
		var instance = this;

		if (instance.getUser) {
			instance.firstname = instance.getUser.firstName;
			instance.lastname = instance.getUser.lastName;
			instance.email = instance.getUser.emailAddress;

			if (instance.getCurrentCustomer && instance.getCurrentCustomer.type == "Company") {
				instance.company = instance.getCurrentCustomer.company;
			}
		}

		instance.sku = <string>instance.$route.query.sku;
		instance.name = <string>instance.$route.query.name;
		instance.myreason = instance.reason;
	}
	onSubmit(e) {
		e.preventDefault();
		this.$notify({
			title: "",
			text: "Funzionalità non implementata",
			type: "warning",
			group: "msg"
		});
		this.resetForm();
	}
	resetForm() {
		this.firstname = "";
		this.lastname = "";
		this.company = "";
		this.email = "";
		// this.reason = "";

		this.myreason = "";
		this.message = "";
		this.privacy = true;
		this.marketing = false;
		this.sku = null;
		this.name = null;
	}
}

import { Component } from "vue-property-decorator";
import { sisasListiniModel } from "@/models/sisasPimListiniModel";
import { productModel } from "@/models/productModel";
import { pagedList } from "@/models/pagedList";
import { orderDetailModel } from "@/models/orderDetailModel";
import axios from "axios";
import * as jq from "node_modules/@types/jquery";
import Vue from 'vue';


declare const jQuery: JQueryStatic;
declare const $: JQueryStatic;
@Component({})
export default class carouselEmpty extends Vue {

  mounted() {
    var instance = this;
    $(this.$refs.carouselContainer).carousel();
  }
}

import { Component, Prop } from "vue-property-decorator";
import { facetModel } from "@/models/facetModel";
import baseUserContextComponent from "../baseUserContextComponent";
import {
  optionModel,
  optionValueModel,
  optionAndSingleValueModel
} from "@/models/optionModel";
@Component({})
export default class filterSelector extends baseUserContextComponent {
  @Prop({ default: () => new optionModel() })
  option: optionModel;
  // @Prop({ default: () => null })
  // filters: any | null;
  @Prop({ default: () => [] })
  facets: facetModel[];
  @Prop({ default: () => [] })
  facetsForCount: facetModel[];


  @Prop({ default: () => false })
  openDefault: boolean;

  search: string = null;
  visible: boolean = false;
  baseItemToShow: number = 5;
  showAll: boolean = false;

  get accordionId() {
    return "accordion-" + (this.option == null ? "" : this.option.code);
  }
  get filterSelectorId() {
    return "filter-selector-" + (this.option == null ? "" : this.option.code);
  }
  get facet(): facetModel {
    var f =
      this.facets == null || this.option == null
        ? null
        : this.facets.find(x => x.code == this.option.code);
    return f;
  }
  get facetForCount(): facetModel {
    var f =
      this.facetsForCount == null || this.option == null
        ? null
        : this.facetsForCount.find(x => x.code == this.option.code);
    return f;
  }
  get showFilter() {
    return this.values != null && this.values.length > 0;
  }
  get values() {
    var instance = this;
    if (instance.facet == null) return [];

    return instance.option.values.filter(x => instance.getCount(x) > 0 || instance.isSelected(x));

  }
  get valuesFiltered() {
    var instance = this;
    var list = instance.values;
    if (instance.search != null && instance.search.trim() != "")
      list = (list || []).filter(
        x =>
          x != null &&
          x.name.toLowerCase().search(instance.search.toLowerCase()) !== -1
      );
    return list;
  }
  get valuesFilteredToShow() {
    var instance = this;
    var list = instance.valuesFiltered;

    return instance.showAll ? list : list.slice(0, instance.baseItemToShow);//.slice(0, 20);
  }
  get showSearch(): boolean {
    var instance = this;
    if (instance.facet == null) return false;

    var list = instance.option.values.filter(x => instance.getCount(x) > 0);
    return list != null && list.length > 10 && instance.showAll;
  }
  get showScroll(): boolean {
    var instance = this;
    if (instance.facet == null) return false;

    var list = instance.option.values.filter(x => instance.getCount(x) > 0);
    return list != null && list.length > 10;
  }
  getCount(o: optionValueModel): number {
    var instance = this;

    if (instance.facet == null || o == null) return 0;
    var count = instance.facet.values[o.code];
    if (count == null && o.code != null)
      count = instance.facet.values[o.code.toLocaleLowerCase()];
    if (count == null && o.code != null)
      count = instance.facet.values[o.code.toLocaleUpperCase()];
    return count == null ? 0 : count;
  }
  getCountAgg(o: optionValueModel): number {
    var instance = this;

    if (instance.facetForCount == null || o == null) return 0;
    var count = instance.facetForCount.values[o.code];
    if (count == null && o.code != null)
      count = instance.facetForCount.values[o.code.toLocaleLowerCase()];
    if (count == null && o.code != null)
      count = instance.facetForCount.values[o.code.toLocaleUpperCase()];
    return count == null ? 0 : count;
  }
  isSelected(o: optionValueModel): boolean {
    // var instance = this;
    // if (
    //   instance.filters == null ||
    //   this.option == null ||
    //   instance.filters.hasOwnProperty(this.option.code) == false
    // )
    //   return false;
    // return instance.filters[this.option.code].indexOf(o.code) != -1;
    return o.selected;
  }
  showValue(o: optionValueModel): boolean {
    var instance = this;
    return instance.isSelected(o) || instance.getCount(o) > 0;
  }
  onClick(o: optionValueModel) {
    var s = new optionAndSingleValueModel();
    s.option = this.option;
    s.value = o;
    // s[this.option.code] = o.code;
    this.$emit("select", s);
  }
  created() {
    this.search = null;
    this.visible = this.openDefault;
  }
  onCollapse() {
    this.visible = !this.visible;
    if (this.visible == false)
      this.showAll = false;
  }
}

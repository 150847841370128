// import Vue from 'vue'
import { Component, Watch, Prop } from "vue-property-decorator";
import baseUserContextComponent from "../baseUserContextComponent";
import { addressModel } from "@/models/addressModel";

@Component({})
export default class customerAddressModalSelector extends baseUserContextComponent {
  @Prop({ default: true })
  lazy: boolean;
  @Prop({ default: false })
  value: boolean;
  @Watch("value")
  onValueChanged(val: boolean, oldVal: boolean) {
    // if (val) {
    //   this.fetch();
    // } else {
    //   this.list = new pagedList();
    // }
  }
  onInput(event) {
    this.$emit("input", event);
  }
  get show(): boolean {
    return this.value;
  }
  set show(event) {
    this.$emit("input", event);
  }
  @Prop({ default: "Seleziona un indirizzo" })
  title: string;
  isLoading: boolean = false;
  //list: pagedList<CustomerInfoModel> = new pagedList();
  @Prop({ default: () => [] })
  addresses: addressModel[];
  // searchParams: any = {
  //   pageSize: 10,
  //   pageIndex: 0,
  //   search: null
  // };
  onSelect(address: addressModel) {
    this.$emit("selected", address);
  }
  // pageChanged(page: number) {
  //   this.searchParams.pageIndex = page;
  //   this.fetch();
  // }
  // onSearch() {
  //   this.searchParams.pageIndex = 0;
  //   this.fetch();
  // }
  // fetch(): void {
  //   var instance = this;
  //   instance.isLoading = true;
  //   axios
  //     .get<pagedList<CustomerInfoModel>>("/customer/search", {
  //       params: instance.searchParams
  //     })
  //     .then(res => {
  //       instance.list = res.data ? res.data : new pagedList();
  //       instance.isLoading = false;
  //     })
  //     .catch(error => {
  //       console.error(error);
  //       instance.isLoading = false;
  //     });
  // }
  mounted() {
    var instance = this;

    //instance.fetch();
  }
}

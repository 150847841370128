import { Component, Prop, Watch } from "vue-property-decorator";
import Vue from "vue";
import axios from "axios";
import { pagedList, sortDirection } from "@/models/pagedList";
import { CustomerInfoModel } from "@/models/customerModels";
import { orderModel, orderState } from "@/models/orderModel";
import { orderDetailModel } from "@/models/orderDetailModel";
import { productModel } from "@/models/productModel";
import { sisasListiniModel } from "@/models/sisasPimListiniModel";
import baseUserContextComponent from "../baseUserContextComponent";
import { categoryModel } from "@/models/categoryModel";
import domainService from "@/services/domainService";
import { mediaType } from "@/models/mediaModel";

@Component({})
export default class customerDetail extends baseUserContextComponent {
  @Prop({ default: () => new CustomerInfoModel() })
  customer: CustomerInfoModel;

  @Watch("customer", { deep: true })
  onCutomerChanged(val: CustomerInfoModel, oldVal: CustomerInfoModel) {
    var instance = this;

    instance.fetchOrders();
    instance.fetchProducts();
  }
  @Prop({ default: false })
  isLoading: boolean = false;

  @Prop({ default: "Cliente" })
  panelInfoTitle: string;

  @Prop({ default: "Ordini del cliente" })
  ordersTitle: string;

  @Prop({ default: true })
  ordersShowDestination: boolean;
  @Prop({ default: false })
  ordersEnableSearch: boolean;

  showAddToCartModal: boolean = false;
  selectedProduct: productModel = null;

  isLoadingOrders: boolean = false;
  lastOrders: pagedList<orderModel> = new pagedList();
  isLoadingTopProducts: boolean = false;
  topProducts: pagedList<orderDetailModel> = new pagedList();
  isLoadingListini: boolean = false;
  isLoadingProduct: boolean = false;
  selectedFilters: {
    [key: string]: string[];
  } = {};

  searchOrders: string = null;
  orderField: string = "createDate";
  sortDirection: sortDirection = sortDirection.DESC;

  pageOrdersIndex: number = 0;

  categories: categoryModel[] = [];

  categorySelected: categoryModel = null;

  get baseUrl(): string {
    return (process.env.VUE_APP_BASE_URL || '').substring(0, process.env.VUE_APP_BASE_URL.lastIndexOf("/api"));//.trimRight('/api').trimRight('api');
  }

  get products(): orderDetailModel[] {
    var instance = this;
    if (instance.topProducts == null || instance.topProducts.items == null)
      return [];
    return instance.topProducts.items;
  }

  get lastOrder(): orderModel {
    var instance = this;
    if (instance.lastOrders && instance.lastOrders.items && instance.lastOrders.items.length > 0) {
      return instance.lastOrders.items[0];
    }
  }

  get getLastOrderStateString(): string {
    if (!this.lastOrder) return "";
    var instance = this;
    if (instance.lastOrder.orderState == orderState.New) return "invio non riuscito";
    if (instance.lastOrder.orderState == orderState.Processing) return "in attesa di conferma";
    if (instance.lastOrder.orderState == orderState.Completed) return "completato";
    if (instance.lastOrder.orderState == orderState.Cancelled) return "annullato";
    return "";
  }

  get footerCategories(): categoryModel[] {
    var instance = this;
    if (instance.categories.length > 0) {
      return instance.categories.slice(0, 6);
    }
    return [];
  }

  onSearchOrders() {
    this.pageOrdersIndex = 0;
    this.fetchOrders();
  }
  pageOrdersChanged(page: number) {
    this.pageOrdersIndex = page;
    this.fetchOrders();
  }
  onSort(field: string) {
    var instance = this;
    if (instance.orderField != field) {
      instance.orderField = field;
      instance.sortDirection = sortDirection.ASC;
    } else {
      instance.sortDirection =
        instance.sortDirection == sortDirection.ASC
          ? sortDirection.DESC
          : sortDirection.ASC;
    }
    instance.fetchOrders();
  }
  fetchOrders() {
    var instance = this;
    if (instance.customer.id != null) {
      instance.isLoadingOrders = true;
      axios
        .get<pagedList<orderModel>>(
          `order/search?pageSize=1&pageIndex=${
            instance.pageOrdersIndex
          }&orderField=${instance.orderField}&direction=${
            instance.sortDirection
          }&customerId=${instance.customer.id}&search=${
            instance.searchOrders == null ? "" : instance.searchOrders
          }`
        )
        .then(res => {
          instance.lastOrders = res.data ? res.data : new pagedList();
          instance.isLoadingOrders = false;
        })
        .catch(error => {
          instance.isLoadingOrders = false;
          console.error(error);
        });
    } else {
      instance.lastOrders = new pagedList();
    }
  }
  fetchProducts() {
    var instance = this;
    if (instance.customer.id != null) {
      instance.isLoadingTopProducts = true;
      axios
        .get<pagedList<orderDetailModel>>(
          `order/topproducts?pageSize=6&customerId=${instance.customer.id}`
        )
        .then(res => {
          instance.topProducts = res.data ? res.data : new pagedList();
          instance.fetchListini();
          instance.isLoadingTopProducts = false;
        })
        .catch(error => {
          console.error(error);
          instance.isLoadingTopProducts = false;
        });
    } else {
      instance.topProducts = new pagedList();
    }
  }
  fetchListini(): void {
    var instance = this;
    instance.isLoadingListini = true;

    if (instance.skus != null && instance.skus.length > 0) {
      axios
        .post<sisasListiniModel[]>("/product/listini", {
          customerId: instance.customer.id,
          skus: instance.skus
        })
        .then(res => {
          if (res.data) {
            instance.topProducts.items.forEach(p => {
              var listini = res.data.filter(x => x.articolo == p.sku);
              if (listini != null) {
                if (p.product == null) p.product = new productModel();
                p.product.listini = listini;
              }
            });
          }
          instance.isLoadingListini = false;
        })
        .catch(error => {
          console.error(error);
          instance.isLoadingListini = false;
        });
    }
  }
  get skus(): string[] {
    var instance = this;
    if (
      instance.topProducts == null ||
      instance.topProducts.items == null ||
      instance.topProducts.items.length == 0
    )
      return [];

    var skus = [];

    instance.topProducts.items.forEach(p => {
      skus.push(p.sku);
    });

    return skus;
  }
  created() {
    var instance = this;

    instance.fetchOrders();
    instance.fetchProducts();
  }
  onSelectProduct(p: productModel) {
    var instance = this;
    instance.selectedFilters = {};
    instance.selectedProduct = null;
    instance.isLoadingProduct = true;
    instance.showAddToCartModal = true;
    axios
      .get<productModel>("/product/masterorproduct", {
        params: {
          productId: p.id,
          loadListini: true,
          customerId: instance.customer.id
        }
      })
      .then(res => {
        if (res) {
          let prod = res.data || new productModel();
          if (p.id != prod.id) {
            var sku = (prod.skus || new Array<productModel>()).find(
              x => x.id == p.id
            );
            if (sku != null && sku.variantsValues != null) {
              for (const [key, value] of Object.entries(sku.variantsValues)) {
                instance.selectedFilters[key] = [];
                instance.selectedFilters[key].push(value);
              }
            }
          }
          instance.selectedProduct = prod;
        }
        instance.isLoadingProduct = false;
      })
      .catch(error => {
        console.error(error);
        instance.isLoadingProduct = false;
      });
  }
  beforeMount() {
    var instance = this;
    domainService
      .getCategories()
      .then(res => {
        this.categories = res == null ? [] : res;
        this.categorySelected = this.categories[0];
      });
  }
  getCategoryName(category: categoryModel): string {
    var instance = this;
    return category.id != null ? instance.getCategoryPropery(category, "name") : "Catalogo";
    // return 'Categorie';
  }
  getCategoryPropery(category: categoryModel, property: string) {
    if (category == null || category.locales == null) return "";
    var l = category.locales["it-IT"];
    return l == null ? "" : l[property];
  }
  onSelectCategory(category: categoryModel) {
    if (category == null) return;
    var instance = this;
    instance.categorySelected = category;
  }
  getDetailUrl(order: orderModel): string {
    var instance = this;
    // return `/order/${instance.getOrderCode(order)}`;
    return `/order/${order.id}`;
  }
  getImageUrl(category: categoryModel) {
    if (
      category == null ||
      category.media == null ||
      category.media.length == 0
    )
      return null;

    var media = category.media
      //.sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1))
      .find(x => x.mediaType == mediaType.Image && x.languageId == "it-IT");

    return media == null ? null : media.path;
  }
  getBackgroundImageStyle(path, width, height) {
    return "background-image: url('" + this.baseUrl + "/images/" + width + "/" + height + "/" + path + "');";
  }
  onClickCategory(c: categoryModel) {
    var instance = this;
    if (c == null) instance.$router.push({ path: `/categories` });
    else if (c.subCategories == null || c.subCategories.length == 0 || c.subCategories.every(s => s.subCategories == null || s.subCategories.length == 0))
      instance.$router.push({ path: `/catalog/${c.id}` });
    else instance.$router.push({ path: `/categories/${c.id}` });
  }
}

import Vue from "vue";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import VueInsProgressBar from "vue-ins-progress-bar";
// import VueSparkline from "vue-sparklines";
import VueNotification from "vue-notification";
import Transitions from "vue2-transitions";
import Vuikit from 'vuikit'
import VuikitIcons from '@vuikit/icons'

//shared components
import progressLine from "@/components/progressLine.vue";
import headerLogo from "@/components/header/header-logo.vue";
import labelPaymentState from "@/components/label-payment-state.vue";
import labelOrderState from "@/components/label-order-state.vue";
import labelScontiListino from "@/components/label-sconti-listino.vue";
import pager from "@/components/pager.vue";
import appImage from "@/components/app-image.vue";
import backButton from "@/components/back-button.vue";
import topNav from "@/components/top-nav/topNav.vue";
import TopMenuCategorySm from "@/components/top-menu/top-menu-category-sm.vue";
//thema app components
import productsList from "@/components/products/products-list.vue";
import productsQuickSearch from "@/components/products/products-quick-search.vue";
import productPricePanel from "@/components/products/product-price-panel.vue";
import productCard from "@/components/products/product-card.vue";
import productRow from "@/components/products/product-row.vue";
import productSelectorConfigured from "@/components/products/product-selector-configured.vue";
import productInfoDetail from "@/components/products/product-info-detail.vue";
import productDetailSimple from "@/components/products/product-detail-simple.vue";
import productDetailConfigurable from "@/components/products/product-detail-configurable.vue";
import productOrderThumbnail from "@/components/products/product-order-thumbnail.vue";
import ordersList from "@/components/orders/orders-list.vue";
import ordersStatistics from "@/components/orders/orders-statistics.vue";
import dashboardEmpty from "@/components/dashboards/dashboard-empty.vue";
import dashboardCustomer from "@/components/dashboards/dashboard-customer.vue";
import customerInfo from "@/components/customers/customer-info.vue";
import customerAddress from "@/components/customers/customer-address.vue";
import customerCurrentInfo from "@/components/customers/customer-current-info.vue";
import customerDetail from "@/components/customers/customer-detail.vue";
import customerSuggestedProductsList from "@/components/customers/customer-suggested-products-list.vue";
import categorySuggestedProductsList from "@/components/categories/category-suggested-products-list.vue";
import customersList from "@/components/customers/customers-list.vue";
import customerModalSelector from "@/components/customers/customer-modal-selector.vue";
import customerAddressModalSelector from "@/components/customers/customer-address-modal-selector.vue";
import dashboardEmployee from "@/components/dashboards/dashboard-employee.vue";
import employeeInfo from "@/components/employees/employee-info.vue";
import employeeDetail from "@/components/employees/employee-detail.vue";
import employeesList from "@/components/employees/employees-list.vue";
import dashboardAreaManager from "@/components/dashboards/dashboard-empty.vue";
import addToCartSlideModalProduct from "@/components/cart/add-to-cart-slide-modal-product.vue";
import addToCartPanel from "@/components/cart/add-to-cart-panel.vue";

import cartNavbar from "@/components/cart/cart-navbar.vue";
//import cartProductListItem from "@/components/cart/cart-product-list-item.vue";
import cartProductListItemGroup from "@/components/cart/cart-product-list-item-group.vue";
//import cartProductListItemGroupOld from "@/components/cart/cart-product-list-item-group_old.vue";
import cartProductChangeQuantityAndNote from "@/components/cart/cart-product-change-quantity-and-note.vue";
import cartProductChangeQuantity from "@/components/cart/cart-product-change-quantity.vue";
import cartNavbarProductListItem from "@/components/cart/cart-navbar-product-list-item.vue";
import filterSelector from "@/components/filters/filter-selector.vue";
import filterButtonSelector from "@/components/filters/filter-button-selector.vue";
import filterSelectorDropdown from "@/components/filters/filter-selector-dropdown.vue";
import categoryFilter from "@/components/categories/category-filter.vue";
import categorySelector from "@/components/categories/category-selector.vue";
import categoryCard from "@/components/categories/category-card.vue";
import categoryCardSubcategory from "@/components/categories/category-card-subcategory.vue";
import categoryBreadcrumb from "@/components/categories/category-breadcrumb.vue";
import categoryPanel from "@/components/categories/category-panel.vue";
import categoryPanelNavBar from "@/components/categories/category-panel-nav-bar.vue";
import categoryPanelNavBarTabs from "@/components/categories/category-panel-nav-bar-tabs.vue";
import categoryPanelNavBar1 from "@/components/categories/category-panel-nav-bar-1.vue";
import categoryListGroup from "@/components/categories/category-list-group.vue";
import categoryListGroupItem from "@/components/categories/category-list-group-item.vue";
import carouselEmpty from "@/components/carousel/carousel.vue";
import assistenzaForm from "@/components/assistenza/assistenzaForm.vue";
import quickInsertProduct from "@/components/cart/quick-insert-product.vue";
import breadcrumbs from "@/components/breadcrumbs.vue";

import iconografiaDetails from "@/components/plugins/iconografia-details.vue";
import optAllegai from "@/components/plugins/opt-allegati.vue";
import productpriceTableEmpty from "@/components/products/product-price-table-empty.vue";

// directives

import dir_collapse from "@/directives/collapse";
import dir_dropdown from "@/directives/dropdown";
import loginPanel from "@/components/login/loginPanel.vue";
import searchInputHeader from "@/components/search-input-header.vue";
import categoryBreaddcrumbBackLavel from "@/components/categories/category-breadcrumb-backlavel.vue";
import defaultLayout from "@/layouts/defaultLayout.vue";
import emptyLayout from "@/layouts/empty.vue";
import checkoutLayout from "@/layouts/checkout.vue";
import appFooter from "@/components/footer/app-footer.vue";
import addedToCartModalProduct from "@/components/cart/added-to-cart-modal-product.vue";
import headerCheckout from "@/components/header/header-checkout.vue";
import headerDefault from "@/components/header/header-default.vue";

import headerNavCustomerMenu from "@/components/header/headerNav-customer-menu.vue";
import headerNavMenu from "@/components/header/header-nav-menu.vue"



import "./assets/css/transitions/transitions.scss";
import "./assets/css/app.scss";
class main_registraion {
  init = function (): void {
    Vue.use(Transitions);
    Vue.use(BootstrapVue);
    Vue.use(BootstrapVueIcons);
    Vue.use(VueNotification);
    Vue.use(VueInsProgressBar, {
      position: "fixed",
      show: true,
      height: "3px"
    });
    Vue.use(Vuikit)
    Vue.use(VuikitIcons)


    Vue.component("progress-line", progressLine);
    Vue.component("app-image", appImage);
    Vue.component("header-logo", headerLogo);
    Vue.component("back-button", backButton);
    Vue.component("label-payment-state", labelPaymentState);
    Vue.component("label-order-state", labelOrderState);
    Vue.component("label-sconti-listino", labelScontiListino);
    Vue.component("pager", pager);
    Vue.component("top-nav", topNav);
    Vue.component("header-checkout", headerCheckout);

    Vue.component("top-menu-category", TopMenuCategorySm);

    Vue.component("products-list", productsList);
    Vue.component("products-quick-search", productsQuickSearch);
    Vue.component("product-price-panel", productPricePanel);
    Vue.component("product-card", productCard);
    Vue.component("product-row", productRow);
    Vue.component("product-selector-configured", productSelectorConfigured);
    Vue.component("product-order-thumbnail", productOrderThumbnail);
    Vue.component("orders-list", ordersList);
    Vue.component("customers-list", customersList);
    Vue.component("employees-list", employeesList);
    Vue.component("orders-statistics", ordersStatistics);
    Vue.component("product-info-detail", productInfoDetail);
    Vue.component("product-detail-simple", productDetailSimple);
    Vue.component("product-detail-configurable", productDetailConfigurable);
    Vue.component("product-price-table-empty", productpriceTableEmpty);


    Vue.component(
      "add-to-cart-slide-modal-product",
      addToCartSlideModalProduct
    );
    Vue.component(
      "added-to-cart-modal-product",
      addedToCartModalProduct
    );
    Vue.component("add-to-cart-panel", addToCartPanel);
    Vue.component("cart-navbar", cartNavbar);
    // Vue.component("cart-product-list-item", cartProductListItem);
    Vue.component("cart-product-list-item-group", cartProductListItemGroup);
    // Vue.component(
    //   "cart-product-list-item-group-old",
    //   cartProductListItemGroupOld
    // );
    Vue.component(
      "cart-product-change-quantity-and-note",
      cartProductChangeQuantityAndNote
    );
    Vue.component(
      "cart-product-change-quantity",
      cartProductChangeQuantity
    );
    Vue.component("cart-navbar-product-list-item", cartNavbarProductListItem);

    Vue.component("customer-modal-selector", customerModalSelector);
    Vue.component(
      "customer-address-modal-selector",
      customerAddressModalSelector
    );

    Vue.component("filter-selector", filterSelector);
    Vue.component("filter-button-selector", filterButtonSelector);
    Vue.component("filter-selector-dropdown", filterSelectorDropdown);

    Vue.component("category-filter", categoryFilter);
    Vue.component("category-selector", categorySelector);
    Vue.component("category-card", categoryCard);
    Vue.component("category-card-subcategory", categoryCardSubcategory);
    Vue.component("category-breadcrumb", categoryBreadcrumb);
    Vue.component("category-breadcrumb-backlavel", categoryBreaddcrumbBackLavel);
    Vue.component("category-panel", categoryPanel);
    Vue.component("category-panel-nav-bar", categoryPanelNavBar);
    Vue.component("category-panel-nav-bar-tabs", categoryPanelNavBarTabs);
    Vue.component("category-panel-nav-bar-1", categoryPanelNavBar1);
    Vue.component("category-list-group", categoryListGroup);
    Vue.component("category-list-group-item", categoryListGroupItem);

    Vue.component("customer-current-info", customerCurrentInfo);
    Vue.component("customer-address", customerAddress);

    Vue.component("login-panel", loginPanel);

    Vue.component("customer-info", customerInfo);
    Vue.component("customer-detail", customerDetail);
    Vue.component("customer-suggested-products-list", customerSuggestedProductsList);
    Vue.component("category-suggested-products-list", categorySuggestedProductsList);
    Vue.component("employee-info", employeeInfo);
    Vue.component("employee-detail", employeeDetail);
    Vue.component("dashboard-empty", dashboardEmpty);
    Vue.component("dashboard-customer", dashboardCustomer);
    Vue.component("dashboard-employee", dashboardEmployee);
    Vue.component("dashboard-area-manager", dashboardAreaManager);

    Vue.component("dashboard-area-manager", dashboardAreaManager);

    Vue.component("carousel-empty", carouselEmpty);

    Vue.component("assistenza-form", assistenzaForm);

    Vue.component("iconografia-details", iconografiaDetails);
    Vue.component("opt-allegati", optAllegai);
    Vue.component("search-input-header", searchInputHeader);

    Vue.directive("app-collapse", dir_collapse);
    Vue.directive("app-dropdown", dir_dropdown);

    Vue.component("default-layout", defaultLayout);
    Vue.component("empty-layout", emptyLayout);
    Vue.component("checkout-layout", checkoutLayout);
    Vue.component("app-footer", appFooter);
    Vue.component("quick-insert-product", quickInsertProduct);
    Vue.component("header-default", headerDefault);
    Vue.component("breadcrumbs", breadcrumbs);

    Vue.component("headernav-customer-menu", headerNavCustomerMenu);
    Vue.component("header-nav-menu", headerNavMenu);
  };
}


export default new main_registraion();

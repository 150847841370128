import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class BackButton extends Vue {
  @Prop({ default: () => null })
  btnClass: String;

 onClick(e:any){
   this.$emit('click',e);
 }
}

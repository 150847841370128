import axios, { AxiosPromise } from "axios";
import { categoryModel } from "@/models/categoryModel";
import { optionModel } from "@/models/optionModel";
import { facetModel } from "@/models/facetModel";

export default class domainService {
  constructor() {
    // this._categories = null;
    // this._optionFilters = null;
  }
  private static _categories: Array<categoryModel> | null = null;
  private static _optionFilters: Array<optionModel> | null = null;
  private static _categoriesFacet: facetModel | null = null;

  public static getCategoriesFacet(): Promise<facetModel> {
    var instance = this;
    return new Promise((resolve, reject) => {
      if (this._categoriesFacet != null)
        resolve(this._categoriesFacet);
      else {
        axios
          .get<facetModel>(`/product/categoryFacets/true`)
          .then(res => {
            instance._categoriesFacet = res.data
              ? res.data
              : new facetModel();
            resolve(this._categoriesFacet);
          })
          .catch(error => {
            console.error(error);
            reject(error);
          });
      }
    });
  }
  public static getCategories(): Promise<categoryModel[]> {
    var instance = this;
    return new Promise((resolve, reject) => {
      if (this._categories != null && this._categories.length > 0)
        resolve(this._categories);
      else {
        axios
          .get<categoryModel[]>("/product/categories")
          .then(res => {
            instance._categories = res.data
              ? res.data
              : new Array<categoryModel>();
            resolve(this._categories);
          })
          .catch(error => {
            console.error(error);
            reject(error);
          });
      }
    });
  }
  public static getOptinFilters(): Promise<optionModel[]> {
    var instance = this;
    return new Promise((resolve, reject) => {
      if (this._optionFilters != null && this._optionFilters.length > 0)
        resolve(this._optionFilters);
      else {
        axios
          .get<optionModel[]>("/product/filters")
          .then(res => {
            instance._optionFilters = res.data
              ? res.data
              : new Array<optionModel>();
            resolve(this._optionFilters);
          })
          .catch(error => {
            console.error(error);
            reject(error);
          });
      }
    });
  }
}

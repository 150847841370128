<template>
	<!-- begin #footer -->
	<div class="d-flex w-100 bg-gray-800 px-4 py-3 text-white  justify-content-between">
		<div>
			<small>Sisas s.p.a. &copy; {{ new Date().getFullYear() }}</small>
		</div>
		<div>
			<router-link class="text-white" :to="{ name: 'assistenza' }"> <small>ASSISTENZA CLIENTI</small></router-link>
		</div>
	</div>
	<!-- end #footer -->
</template>

<script>
export default {
	name: "appFooter",
	data() {
		return {};
	}
};
</script>

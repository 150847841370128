import { render, staticRenderFns } from "./app-image.vue?vue&type=template&id=adb1f7f6&scoped=true&"
import script from "./app-image.vue.ts?vue&type=script&lang=ts&"
export * from "./app-image.vue.ts?vue&type=script&lang=ts&"
import style0 from "./app-image.vue?vue&type=style&index=0&id=adb1f7f6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "adb1f7f6",
  null
  
)

export default component.exports
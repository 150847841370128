import { productModel } from './productModel';

export class orderDetailModel {
  constructor() {
    this.id = null;
    this.orderId = null;
    this.productId = null;
    this.code = null;
    this.code1 = null;
    this.code2 = null;
    this.code3 = null;
    this.code4 = null;
    this.code5 = null;
    this.sku = null;
    this.productName = null;
    this.image = null;
    this.weight = null;
    this.quantity = null;
    this.quantityShipped = null;
    this.originalPrice = null;
    this.price = null;
    this.netPrice = null;
    this.taxPercent = null;
    this.taxAmount = null;
    this.discountPercent = null;
    this.discountAmount = null;
    this.total = null;
    this.brandName = null;
    this.variantDescriptor = null;
    this.product = new productModel();
    // added for reorder
    this.selected = false;
  }
  id: number;
  orderId: number | null;
  productId: number | null;
  sku: string;
  code: number | string;
  code1: number | string;
  code2: number | string;
  code3: number | string;
  code4: number | string;
  code5: number | string;
  productName: string;
  image: string;
  weight: number | null;
  quantity: number | null;
  quantityShipped: number | null;
  originalPrice: number | null;
  price: number | null;
  netPrice: number | null;
  taxPercent: number | null;
  taxAmount: number | null;
  discountPercent: number | null;
  discountAmount: number | null;
  total: number | null;
  brandName: string;
  variantDescriptor: string;

  product: productModel;

  selected: boolean;
}

export class sisasDocumentiRighe {
  constructor() {
    this.id = null;
    this.CHIAVEDOC = null;
    this.idriga = null;
    this.seqriga = null;
    this.tiporiga = null;
    this.aliasarti = null;
    this.articolo = null;
    this.descriga = null;
    this.unimis = null;
    this.contratto = null;
    this.listino = null;
    this.omaggio = null;
    this.nomenclatura = null;
    this.dataevprev = null;
    this.dataeveff = null;
    this.serigrafia = null;
    this.checkeva = null;
    this.qtamov = null;
    this.qtauM1 = null;
    this.valriglor = null;
    this.valrignet = null;
    this.qtaeva = null;
    this.qtaev1 = null;
    this.valevaso = null;
    this.qtaape = null;
    this.valapelor = null;
    this.valapenet = null;
    this.scontoR1 = null;
    this.scontoR2 = null;
    this.scontoR3 = null;
    this.scontoR4 = null;
    this.codiva = null;
    this.perproV1 = null;
    this.perproV2 = null;
    this.pesoarti = null;
  }
  id: number;
  CHIAVEDOC: string;
  idriga: string;
  seqriga: string;
  tiporiga: string;
  aliasarti: string;
  articolo: string;
  descriga: string;
  unimis: string;
  contratto: string;
  listino: string;
  omaggio: string;
  nomenclatura: string;
  dataevprev: string;
  dataeveff: string;
  serigrafia: string;
  checkeva: string;
  qtamov: number | string;
  qtauM1: number | string;
  valriglor: number | string;
  valrignet: number | string;
  qtaeva: number | string;
  qtaev1: number | string;
  valevaso: number | string;
  qtaape: number | string;
  valapelor: number | string;
  valapenet: number | string;
  scontoR1: number | null;
  scontoR2: number | null;
  scontoR3: number | null;
  scontoR4: number | null;
  codiva: string;
  perproV1: number | string;
  perproV2: number | string;
  pesoarti: number | string;
}









import { Component, Prop } from "vue-property-decorator";
import { categoryModel } from "@/models/categoryModel";
import { facetModel } from "@/models/facetModel";
import baseUserContextComponent from "../baseUserContextComponent";
@Component({})
export default class categorySelector extends baseUserContextComponent {
	@Prop({ default: () => new Array<categoryModel>() })
	categories: categoryModel[];
	@Prop({ default: () => null })
	selectedId: number | null;
	@Prop({ default: () => "cards" })
	type: string | null;
	@Prop({ default: () => null })
	facet: facetModel;
	@Prop({ default: () => true })
	showTotal: boolean;

	get categoriesSorted(): categoryModel[] {
		var instance = this;
		var cat = [];
		if (instance.categories != null) {
			cat = instance.categories.sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1));
		}
		return cat;
	}

	onSelect(c: categoryModel) {
		this.$emit("select", c);
	}
}

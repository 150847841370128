
























import { Component, Prop } from "vue-property-decorator";
import baseUserContextComponent from "./baseUserContextComponent";

class breadcrumb {
	label: string;
	link: string;
}

@Component({})
export default class breadcrumbs extends baseUserContextComponent {
	@Prop({ default: () => new Array<breadcrumb>() })
	breadcrumbs: breadcrumb[];

	@Prop({ default: false })
	showBackBtn: boolean;

	routerGoBack() {
		this.$router.go(-1);
	}
}

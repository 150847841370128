import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { CustomerInfoModel } from "@/models/customerModels";
@Component({})
export default class customerInfo extends Vue {
  @Prop({ default: "Info cliente" })
  title: string;

  @Prop({ default: () => new CustomerInfoModel() })
  customer: CustomerInfoModel;

  get customerDescription(): string {
    var instance = this;
    if (instance.customer == null) return "";

    if (instance.customer.company) return instance.customer.company;

    var desc = instance.customer.firstName;
    if (instance.customer.middleName) {
      if (desc) desc += " ";
      desc += instance.customer.middleName;
    }
    if (instance.customer.lastName) {
      if (desc) desc += " ";
      desc += instance.customer.lastName;
    }
    return desc;
  }
  get agenteDescription(): string {
    var instance = this;
    if (
      instance.customer == null ||
      instance.customer.employees == null ||
      instance.customer.employees.length == 0
    )
      return "";

    var employee = instance.customer.employees[0];
    if (employee.name) return employee.name;
    var desc = employee.firstName;
    if (instance.customer.middleName) {
      if (desc) desc += " ";
      desc += instance.customer.middleName;
    }
    if (instance.customer.lastName) {
      if (desc) desc += " ";
      desc += instance.customer.lastName;
    }
    return desc;
  }

  get customerListino(): string {
    var instance = this;
    if (
      instance.customer == null ||
      instance.customer.condizioniCommerciali == null
    )
      return null;
    else
      return instance.customer.condizioniCommerciali.decrizionE_LISTINO
        ? instance.customer.condizioniCommerciali.decrizionE_LISTINO
        : instance.customer.condizioniCommerciali.codicE_LISTINO;
  }
  get customerScontistica(): string {
    var instance = this;
    if (
      instance.customer == null ||
      instance.customer.condizioniCommerciali == null
    )
      return null;
    else
      return instance.customer.condizioniCommerciali.decrizionE_SCONTI
        ? instance.customer.condizioniCommerciali.decrizionE_SCONTI
        : instance.customer.condizioniCommerciali.codicE_SCONTI;
  }
  get customerContratto(): string {
    var instance = this;
    if (
      instance.customer == null ||
      instance.customer.condizioniCommerciali == null
    )
      return null;
    else
      return instance.customer.condizioniCommerciali.decrizionE_CONTRATTO
        ? instance.customer.condizioniCommerciali.decrizionE_CONTRATTO
        : instance.customer.condizioniCommerciali.codicE_CONTRATTO;
  }
}

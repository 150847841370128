import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { pagedList, sortDirection } from "@/models/pagedList";
import { EmployeeModel, EmployeeLightModel } from "@/models/employeeModels";
@Component({})
export default class employeesList extends Vue {
  @Prop({ default: "Lista clienti" })
  title: string;
  @Prop({ default: false })
  isLoading: boolean;
  @Prop({ default: true })
  showReferent: boolean;

  @Prop({ default: true })
  selectable: boolean;

  @Prop({ default: sortDirection.ASC })
  sortDirection: sortDirection;

  @Prop({ default: "" })
  sortField: string;
  
  @Prop({ default: true })
  enableSort: boolean;

  onSelect(item: EmployeeModel) {
    if (this.selectable) this.$emit("selected", item);
  }

  @Prop({ default: () => new pagedList<EmployeeModel>() })
  pagedList: pagedList<EmployeeModel>;

  @Prop({ default: () => new Array<EmployeeModel>() })
  list: Array<EmployeeModel>;

  headClick(field: string) {
    this.$emit("sort", field);
  }
  getSortCss(field: string): string {
    if (field != this.sortField) return "fas fa-sort text-black-transparent-5";

    return this.sortDirection == sortDirection.ASC
      ? "fas fa-sort-up text-black"
      : "fas fa-sort-down text-black";
  }

  get colSpan(): number {
    return this.showReferent ? 4 : 3;
  }

  getDescription(item: EmployeeLightModel): string {
    if (item.name) return item.name;
    var desc = item.firstName;
    if (item.middleName) {
      if (desc) desc += " ";
      desc += item.middleName;
    }
    if (item.lastName) {
      if (desc) desc += " ";
      desc += item.lastName;
    }
    return desc;
  }
  getDetailUrl(item: EmployeeLightModel): string {
    return `/employee/${item.id}`;
  }
  get items(): Array<EmployeeModel> {
    if (this.pagedList == null || this.pagedList.items == null)
      return this.list == null ? [] : this.list;
    return this.pagedList.items;
  }
}

import { render, staticRenderFns } from "./top-menu-category-sm.vue?vue&type=template&id=59b10f68&"
import script from "./top-menu-category-sm.vue.ts?vue&type=script&lang=ts&"
export * from "./top-menu-category-sm.vue.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
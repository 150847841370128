// import Vue from 'vue'
import { Component } from "vue-property-decorator";
import { UserLoginModel, UserLoggedInfoModel } from "@/models/userModels";
import { mapActions } from "vuex";
import axios, { AxiosPromise } from "axios";
import baseUserContextComponent from "../baseUserContextComponent";

@Component({
  methods: { ...mapActions(["loginUser"]) }
})
export default class loginPanel extends baseUserContextComponent {
  isLoading: boolean = false;
  showPwd: boolean = false;
  user: UserLoginModel = new UserLoginModel();
  loginUser!: (token: any) => AxiosPromise<UserLoggedInfoModel>;
  emailRules = [
    (v: string) => {
      return !!v || "Campo obbligatorio";
    },
    (v: string | null) => v == null || /.+@.+/.test(v) || "Formato non valido"
  ];
  passwordRules = [(v: string | null) => !!v || "Campo obbligatorio"];

  checkForm(e: any) {
    var instance = this;
    e.preventDefault();
    instance.isLoading = true;
    axios
      .post<any>("/account/login", instance.user)
      .then(res => {
        instance.loginUser(res.data.token).then(() => {
          // instance.$router.push({ name: "home" });
          instance.$emit("loggedIn", true);
        });
        instance.isLoading = false;
      })
      .catch(error => {
        console.error(error);
        instance.isLoading = false;
      });
  }
}

// import Vue from 'vue'
import { Component, Watch, Prop } from "vue-property-decorator";
import { productModel, productType } from "@/models/productModel";
import { mediaType, mediaModel } from "@/models/mediaModel";
import { sisasListiniModel } from "@/models/sisasPimListiniModel";
import { optionModel, iProductOption } from "@/models/optionModel";
import domainService from "@/services/domainService";
import { CustomerInfoModel } from "@/models/customerModels";
import baseUserContexetProductComponent from "@/components/baseUserContextProductComponent";
import { familyOptionViewModel } from "@/models/familyOptionModel";
import { productRelation } from "@/models/productRelation";
import eventHub from "@/eventHub";
import axios from "axios";

@Component({})
export default class productInfoDetail extends baseUserContexetProductComponent {
  // @Prop({ default: () => [] })
  relations: productRelation[] = [];
  // @Prop({ default: () => false })
  isLoadingRelations: boolean = false;
  @Prop({ default: () => new productModel() })
  item: productModel;
  @Watch("item", { deep: true })
  onProductChange(newVal: any) {
    var instance = this;
    instance.fetchRelations();
  }
  showCustomerModal: boolean = false;
  showAddToCartModal: boolean = false;
  get showAddToCartModal_LoadingCheck(): boolean {
    return this.showAddToCartModal && this.isLoadingRelations == false;
  }
  set showAddToCartModal_LoadingCheck(v: boolean) {
    this.showAddToCartModal = v;
  }
  // selectedProduct: productModel = null;
  // get productToAdd(): productModel {
  //   var instance = this;
  //   return instance.showAddToCartModal_LoadingCheck ? instance.selectedProduct : null;
  // }
  selectedProductId: number = null;
  get selectedProduct(): productModel {
    var instance = this;
    if (instance.selectedProductId == null)
      return null;
    var r = (instance.relations || []).find(x => x.product != null && x.product.id == instance.selectedProductId);
    return r == null ? null : r.product;
  }
  goToDetail(section: string) {

    let element = document.getElementById(section);
    element.scrollIntoView({ behavior: "smooth", block: 'start' });
    // element.scrollTop -= 500;

    // var elem = this.$refs[section];
    // if (elem != null && (elem as Element[]).length > 0) {
    //    var top = (elem[0] as any).offsetTop;
    //    window.scrollTo(0, top);
    // }
  }
  imageIndex: number = 0;
  get images() {
    var instance = this;
    var images = [];

    if (instance.item != null && instance.item.media != null) {
      images = instance.item.media.filter(x => x.mediaType == mediaType.Image);
    }

    return images.sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1));
  }
  get optionToShow(): iProductOption[] {
    var instance = this;
    return instance.item == null ? [] : instance.item.options;
  }
  get familyOptionToShow(): familyOptionViewModel[] {
    var instance = this;
    return instance.item == null
      ? []
      : instance.item.familyOptions.filter(
        x =>
          x.groupCode == "B2BTABS" &&
          instance.hasOptionValue(x.code, instance.item)
      );
  }
  get selectedImage(): mediaModel {
    var instance = this;
    if (
      instance.item == null ||
      instance.images.length == 0 ||
      instance.images.length < instance.imageIndex
    )
      return new mediaModel();
    else return instance.images[instance.imageIndex];
  }
  isSelected(image: mediaModel, index: number) {
    return this.imageIndex == index;
  }
  setImage(image: mediaModel, index: number) {
    return (this.imageIndex = index);
  }

  get skus(): string[] {
    var instance = this;
    if (instance.item == null) return [];

    var skus = [];
    skus.push(instance.item.sku);
    return skus;
  }
  beforeMount() { }
  mounted() {
    var instance = this;
    instance.fetchRelations();
    eventHub.$on(eventHub.events.CURRENT_CUSTOMER_CHANGED, instance.checkCurrentCustomer);
  }
  beforeDestroy() {
    var instance = this;
    eventHub.$off(
      eventHub.events.CURRENT_CUSTOMER_CHANGED,
      instance.checkCurrentCustomer
    );
  }
  checkCurrentCustomer() {
    var instance = this;
    // if (instance.getCurrentCustomerId == null)
    instance.fetchRelations();
  }
  get accessories(): productRelation[] {
    var instace = this;
    return instace.relations == null ? [] : instace.relations.filter(x => x.relationType == 'ACCESSORY').sort((a, b) => a.displayOrder >= b.displayOrder ? 1 : 0);
  }
  get upselling(): productRelation[] {
    var instace = this;
    return instace.relations == null ? [] : instace.relations.filter(x => x.relationType == 'UPSELLING').sort((a, b) => a.displayOrder >= b.displayOrder ? 1 : 0);
  }
  get crosselling(): productRelation[] {
    var instace = this;
    return instace.relations == null ? [] : instace.relations.filter(x => x.relationType == 'CROSSELLING').sort((a, b) => a.displayOrder >= b.displayOrder ? 1 : 0);
  }
  get showComponent(): boolean {
    var instance = this;
    return (instance.familyOptionToShow != null && instance.familyOptionToShow.length > 0)
      || (instance.accessories.length > 0)
      || (instance.upselling.length > 0)
      || (instance.crosselling.length > 0);
  }
  onSelectCustomer(customer: CustomerInfoModel) {

    var instance = this;
    eventHub.$on(eventHub.events.CURRENT_CUSTOMER_CHANGED, instance.openAddToCartModal_after_event_CustomerChanged);

    instance.setCurrentCustomer(customer);


  }
  openAddToCartModal_after_event_CustomerChanged() {

    var instance = this;
    instance.showCustomerModal = false;
    if (instance.selectedProduct != null) instance.showAddToCartModal = true;
    eventHub.$off(
      eventHub.events.CURRENT_CUSTOMER_CHANGED,
      instance.openAddToCartModal_after_event_CustomerChanged
    );
  }
  onSelectProduct(p: productModel) {
    var instance = this;
    instance.selectedProductId = p.id;
    if (instance.getCurrentCustomer == null) {
      instance.showCustomerModal = true;
    } else {
      instance.showAddToCartModal = true;
    }
  }
  fetchRelations() {

    var instance = this;

    if (instance.item != null && instance.item.id != null) {
      instance.isLoadingRelations = true;
      axios
        .get<productRelation[]>("/product/relations", {
          params: {
            productId: instance.item.id,
            loadListini: true,
            customerId: instance.getCurrentCustomerId
          }
        }).then(res => {
          instance.relations = res.data || [];
          instance.isLoadingRelations = false;
        }).catch(err => instance.isLoadingRelations = false);
    }
    else {
      instance.relations = [];
    }
  }
}



























































import { Component, Prop, Watch } from "vue-property-decorator";
import baseUserContextComponent from "../baseUserContextComponent";
import { productModel, productType } from "@/models/productModel";
import { sisasListiniModel } from "@/models/sisasPimListiniModel";
import _ from "lodash";
import baseUserContextProductComponent from "../baseUserContextProductComponent";
//var Qs = require("qs");

@Component({})
export default class productCard extends baseUserContextProductComponent {
	addToCartIsOpen: boolean = false;
	@Prop({ default: () => new productModel() })
	product: productModel;
	@Prop({ default: () => null })
	categoryId: number;
	@Prop({ default: () => null })
	spreselectedId: number;
	@Prop({ default: () => "none" })
	shadow: string;
	@Prop({ default: () => "" })
	size: string;
	get shadowclass(): string {
		var instace = this;
		if (instace.shadow) return " shadow-" + this.shadow;
		else return " shadow ";
	}
	get thumbnailclass(): string {
		var instace = this;
		if (instace.size == "sm") return " item-thumbnail-small ";
		else return " ";
	}
	get btnAggiungClass(): string {
		var instace = this;
		if (instace.size == "sm") return " btn-sm ";
		else return " ";
	}
	@Prop({ default: () => false })
	isLoadingListini: boolean;

	get queryParams(): any {
		var instance = this;
		var params = instance.$route.query;
		var q = {};
		// if (params && params.categoryId != undefined && params.categoryId != null) {
		//   q["categoryId"] = params.categoryId;
		// }
		if (params && params.filters != undefined && params.filters != null) {
			q["filters"] = params.filters;
		}
		return q;
	}
	getRouteParams(product: productModel) {
		var instance = this;
		if (instance.spreselectedId != null && product.type == productType.Configurable && product.skus != null && product.skus.length != 0) {
			var p = product.skus.find(x => x.id == instance.spreselectedId);
			if (p != null)
				return {
					id: instance.spreselectedId,
					categoryId: instance.categoryId
				};
		}
		return {
			id: product.id,
			categoryId: instance.categoryId
		};
	}
	onCustomerSelected(customer: any) {
		//this.$emit("customerSelected", customer);
	}

	getDetailUrl(product: productModel): string {
		var instance = this;

		if (instance.spreselectedId != null && product.type == productType.Configurable && product.skus != null && product.skus.length != 0) {
			var p = product.skus.find(x => x.id == instance.spreselectedId);
			if (p != null) return `/product/${instance.spreselectedId}`;
		}
		return `/product/${product.id}`;
		// return `/product/${product.skus[0].id}`;
	}
	get subTitle(): string {
		if (this.product == null) return "";
		if (this.product.type != productType.Configurable) return `Codice ${this.product.sku}`;
		// return `(${this.product.skus.length} Prodotti)`;
		return `Codice ${this.product.sku}`;
		// return ``;
	}
	get lblVersioni(): string {
		if (this.product == null) return "";
		if (this.product.type != productType.Configurable) return ``;
		return `Disponibile in ${this.product.skus.length} versioni`;
		// return ``;
	}
	get btnAddToCartText(): string {
		if (this.product == null) return "";
		if (this.product.type != productType.Configurable) return "Aggiungi al carrello";
		// return `Scegli tra le ${this.product.skus.length} versioni`;
		return `vai alle versioni`;
	}
	get listino_um_principale(): sisasListiniModel {
		if (this.product == null || this.product.listini == null) return null;

		var listino = this.product.listini.find(l => l.principale);
		return listino;
	}
	mounted() {}
	onSelect() {
		this.$emit("select", this.product);
	}

	get heightImg(): number {
		var instance = this;
		return instance.size == "sm" ? 70 : 100;
	}
	get widthImg(): number {
		var instance = this;
		if (instance.size == "sm") return instance.xl ? 148 : instance.lg ? 110 : instance.md ? 200 : 150;
		return instance.xl ? 148 : instance.lg ? 110 : instance.md ? 200 : 150;
	}
}

import axios, { AxiosPromise } from "axios";
import doofinderConfig from "../config/doofinder.config";
import { productFeed } from "@/models/productFeed";
import { productModel } from "@/models/productModel";
import { pagedList } from "@/models/pagedList";
export default class productSearchService {
  private static _axios = axios.create();
  private static dofinderHears: any = {
    headers: {
      Authorization: doofinderConfig.config.API_KEY

      // Accept: "*/*"
    }
    //proxy: false
  };
  // dofinderParams: any = {
  //   hashid: doofinderConfig.config.HASH_ID,
  //   rpp: "50", //result per page
  //   query: ""
  // };

  private static searchParams: any = {
    pageSize: 6,
    pageIndex: 0,
    orderField: "name",
    direction: "ASC",
    search: null,
    categoryId: null,
    customerId: null
  };
  public static search(search: string): Promise<productFeed[]> {
    var instance = this;
    return instance.fastSearch(search);

    if (doofinderConfig.config.IS_DIRECT_ACTIVE)
      return instance.searchDoofinder(search);
    return instance.searchIndexer(search);
  }
  private static fastSearch(search: string): Promise<productFeed[]> {
    var instance = this;
    var searchParam = search != null ? search : "";

    //rpp: "50", result per page
    return new Promise((resolve, reject) => {
      axios
        .get<any>(`/product/fastSearch?search=${searchParam}`)
        .then(res => {
          var products =
            res.data && res.data.results
              ? res.data.results
              : new Array<productFeed>();
          resolve(products);
        })
        .catch(error => {
          // debugger;
          console.error(error);
          reject(error);
        });
    });
  }
  private static searchDoofinder(search: string): Promise<productFeed[]> {
    var instance = this;
    var searchParam = search != null ? search : "";

    //rpp: "50", result per page
    return new Promise((resolve, reject) => {
      instance._axios
        .get<any>(
          `${doofinderConfig.config.ENDPOINT}?hashid=${
            doofinderConfig.config.HASH_ID
          }&rpp=30&query=${searchParam}`,
          instance.dofinderHears
        )
        .then(res => {
          var products =
            res.data && res.data.results
              ? res.data.results
              : new Array<productFeed>();
          resolve(products);
        })
        .catch(error => {
          // debugger;
          console.error(error);
          reject(error);
        });
    });
  }

  private static searchIndexer(search: string): Promise<productFeed[]> {
    var instance = this;
    instance.searchParams.search = search;

    return new Promise((resolve, reject) => {
      axios
        .get<pagedList<productModel>>("/product/find", {
          params: instance.searchParams
          // cancelToken: instance.fatchCall.token
        })
        .then(res => {
          var products = res.data
            ? res.data.items.map(x => {
                var product = new productFeed();
                product.id = x.id != null ? x.id.toString() : "";
                product.product_image = x.mainImage;
                product.product_sku = x.sku;
                product.title = x.name["it-IT"];
                return product;
              })
            : new Array<productFeed>();
          resolve(products);
        })
        .catch(error => {
          console.error(error);
          reject(error);
        });
    });
  }
}

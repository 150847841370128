import { Component, Prop, Watch } from "vue-property-decorator";
import baseUserContextPage from "./baseUserContextPage";

@Component({})
export default class homePage extends baseUserContextPage {
  get nameOfComponent() {
    var instance = this;
    if (instance.isUserCustomer) return "dashboard-customer";
    if (instance.isUserEmployee || instance.isUserRefEmployee)
      return "dashboard-employee";
    return "dashboard-empty";
  }
  get isComponentDefined(): boolean {
    var instance = this;
    var component = instance.$options.components[instance.nameOfComponent];
    return component != null;
  }
}

import { Component, Prop, Watch } from "vue-property-decorator";
import baseUserContexetProductComponent from "@/components/baseUserContextProductComponent";
import {
  productAddedCartModel,
  productModel,
  productType,
} from "@/models/productModel";
import { ShoppingCartModel } from "@/models/shoppingCartModel";
import _ from "lodash";
var Qs = require("qs");
@Component({})
export default class addedToCartModalProduct extends baseUserContexetProductComponent {

  @Prop({ default: () => [] })
  products: productAddedCartModel[];
  @Prop({ default: false })
  value: boolean;
  @Prop({ default: 1 })
  quantity: number;

  get show(): boolean {
    return this.value;
  }
  set show(event) {
    this.$emit("input", event);
  }
  get cart(): ShoppingCartModel {
    return this.getCurrentCart;
  }
  // get cartProductsCount(): number {
  //   return this.cart != null && this.cart.products != null ? this.cart.products.length : 0;
  // }
  get productsOnCart(): number {
    if (this.cart == null || this.cart.products == null) return 0;

    return _.uniq(_.map(this.cart.products, "sku")).length;
  }
  get cartTotal(): number {
    if (this.cart == null || this.cart.products == null) return 0;
    return this.cart.products.reduce(function (total, item) {
      return total + item.rowTotal;
    }, 0);
  }
  get contentTitle(): string {
    if (this.anyAdded) {

      if (this.products == null || this.products.length < 2)
        return "Prodotto aggiunto al carrello";
      return "Prodotti aggiunti al carrello"

    }
    else {

      if (this.products == null || this.products.length < 2)
        return "Prodotto non aggiunto al carrello";
      return "Prodotti non aggiunti al carrello"
    }
  }
  getMainImage(product: productModel): string {
    return product != null &&
      product.mainImage != null &&
      product.mainImage != ""
      ? product.mainImage
      : "";
  }

  getSubTitle(product: productModel): string {
    if (product == null) return "";
    if (product.type != productType.Configurable)
      return `Codice ${product.sku}`;
    // return `(${this.currentProduct.skus.length} Prodotti)`;
    return product.sku;
  }

  getTitle(product: productModel): string {
    if (product != null && product.name != null)
      return product.name["it-IT"];
    return "";
  }


  onCloseModal() {
    this.$emit("input", false);
  }
  get anyAdded(): boolean {
    if (this.products == null || this.products.length == 0)
      return false;
    return this.products.find(x => x.added) != null;
  }

}

// import Vue from 'vue'
import { Component, Watch, Prop, Mixins } from "vue-property-decorator";
import axios, { AxiosPromise } from "axios";
import {
  productModel,
  productType,
  productSelectorResult
} from "@/models/productModel";
//import { productType } from "@/models/productModel";
import { mediaType, mediaModel } from "@/models/mediaModel";
import { sisasListiniModel } from "@/models/sisasPimListiniModel";
import {
  iProductOption,
  optionModel,
  optionValueModel,
  optionAndSingleValueModel
} from "@/models/optionModel";
import { categoryModel } from "@/models/categoryModel";
import domainService from "@/services/domainService";
import { facetModel } from "@/models/facetModel";
import baseUserContextComponent from "../baseUserContextComponent";
import { CustomerInfoModel } from "@/models/customerModels";
import { familyOptionViewModel } from "@/models/familyOptionModel";
//import { productModel } from "@/Extensions/productsExtensions";
import baseUserContextProductComponent from "@/components/baseUserContextProductComponent";
import _ from "lodash";
var Qs = require("qs");

@Component({})
export default class productDetailConfigurable extends baseUserContextProductComponent {
  markNotSelected: boolean = false;
  visualizzazione: number = 1;
  // getSkusProducts!: (product: productModel | null) => productModel[];
  // getVariantCodes!: (product: productModel) => Array<string>;
  @Prop({ default: false })
  isLoading: boolean;
  @Prop({ default: false })
  isLoadingMaster: boolean;
  @Prop({ default: false })
  isLoadingListini: boolean;
  addToCartIsOpen: boolean = false;
  // @Prop({ default: () => [] })
  // relations: productRelation[] = [];
  @Prop({ default: () => new productModel() })
  item: productModel;
  allFiltersSelected: boolean = false;
  // isLoadingRelations: boolean = false;
  @Watch("item", { deep: true })
  onProductChange(newVal: any) {
    var instance = this;
    instance.setDefaultImageIndex();
  }
  @Watch("currentProduct", { deep: true })
  onCurrentProductChange(newVal: any) {
    var instance = this;
    instance.setDefaultImageIndex();
  }
  setDefaultImageIndex() {
    var instance = this;
    if (instance.currentProductMainImage) {
      for (var index = 0; index < instance.images.length; index++) {
        var mainImg = instance.images[index];
        if (mainImg.path == instance.currentProductMainImage) {
          instance.setImage(mainImg, index);
          return;
        }
      }
    }
    instance.imageIndex = 0;
  }

  get currentProductMainImage(): string {
    return this.selectedProduct == null ||
      this.selectedProduct.mainImage == null ||
      this.selectedProduct.mainImage == ""
      ? this.item.mainImage
      : this.selectedProduct.mainImage;
  }
  selectedProduct: productModel = null;
  onSelectProduct(productSelect: productSelectorResult) {
    var instance = this;
    instance.selectedProduct = productSelect.product;
    instance.allFiltersSelected = productSelect.allFiltersSelected;
  }
  get currentProduct(): productModel {
    return this.selectedProduct == null ? this.item : this.selectedProduct;
  }
  defaultum: string = null;

  imageIndex: number = 0;
  filters: optionModel[] = [];

  showCustomerModal: boolean = false;
  showAddToCartModal: boolean = false;
  goToConfiguration() {
    var elem = this.$refs["productConfigurationSection"];

    var top = (elem as any).offsetTop;

    window.scrollTo(0, top);
  }
  onSelect(customer: CustomerInfoModel) {
    var instance = this;

    instance.showCustomerModal = false;
    instance.setCurrentCustomer(customer);
    //   instance.$emit("customerSelected", customer);

    // instance.$emit("isOpen", instance.showModal || instance.showCustomerModal);
    // Vue.nextTick(function() {
    // do something cool
    this.$emit("customerSelected", customer);
    // instance.showAddToCartModal = true;
    // });
  }
  addToCartClick(product: productModel, um: string) {
    this.selectedProduct = product;
    this.defaultum = um;
    if (this.getCurrentCustomer == null) this.showCustomerModal = true;
    //if (this.listino_um_principale != null)
    else this.showAddToCartModal = true;
  }
  showModalCustomer() {
    if (this.getCurrentCustomer == null) this.showCustomerModal = true;
    // else if (this.listino_um_principale != null) this.showAddToCartModal = true;
  }
  get imgWidth() {
    return this.xl ? 258 : this.lg ? 230 : this.md ? 600 : this.sm ? 300 : 258;
  }
  get imgHeight(): number {
    return this.xl ? 300 : this.lg ? 300 : this.md ? 240 : 240;
  }
  get imgDivWidth() {
    return this.xl ? `${this.imgWidth + 60}px` : "100%"; //this.md ? 658 : 448;
  }
  get imgDivHeight(): string {
    return `${this.imgHeight + (this.lg ? 60 : 30)}px`;
  }
  // @Watch("$route")
  // onRouteChange(to, from) {
  //   this.fetch();
  // }
  //  get urlFilters(): any {
  //   var instance = this;
  //   var params = instance.$route.query;
  //   debugger;
  //   return params.filters != undefined && params.filters != null ? Qs.parse(params.filters) : {};
  //  }

  get categoryId(): number {
    var instance = this;
    var categoryId = null;
    if (instance.item == null) return null;

    if (instance.$route.query && instance.$route.query.categoryId)
      categoryId = instance.$route.query.categoryId as any;

    categoryId = Number.parseInt(categoryId);
    if (Number.isNaN(categoryId)) categoryId = null;

    if (
      categoryId != null &&
      instance.item.categoryIds.indexOf(categoryId) != -1
    )
      return categoryId;

    return instance.item.categoriesIdDefault != null
      ? instance.item.categoriesIdDefault
      : instance.item.categoryIds.length > 0
        ? instance.item.categoryIds[0]
        : null;
  }

  // @Watch("$route.params.id", { immediate: true })
  // onUrlIdChange(newVal: any) {
  //   // Some action

  //   this.fetch();
  // }

  getVarianteImage(p: productModel) {
    if (p.mainImage != null && p.mainImage != "") return p.mainImage;
    if (
      this.item != null &&
      this.item.mainImage != null &&
      this.item.mainImage != ""
    )
      return this.item.mainImage;

    var mediaImage = p.media.find(x => x.mediaType == mediaType.Image);
    if (mediaImage != null) return mediaImage.path;
    mediaImage =
      this.item != null
        ? this.item.media.find(x => x.mediaType == mediaType.Image)
        : null;
    if (mediaImage != null) return mediaImage.path;
    return null;
  }

  get products(): productModel[] {
    var instance = this;
    var filtered = instance.getSkusProducts(instance.item);

    for (var code in instance.activeFilterValues) {
      filtered = filtered.filter(
        x =>
          instance.activeFilterValues[code].indexOf(x.variantsValues[code]) !=
          -1
      );
    }

    return filtered;
    // var product = instance.item;
    // if (product == null || product.skus == null) return [];
    // // : instance.master.skus.filter(x => x.sku != instance.item.sku);
    // var orderdSkus = product.skus;
    // //  var length = instance.getVariantDefinitions(product).length;
    // //  var sortField = [];
    // //  for (var index = 0; index < length; index++) {
    // //   var variant = instance.getVariantDefinitions(product)[index];
    // //   sortField.push("variantsLocalized[" + variant.code + "]");
    // //  }
    // //return _.sortBy(orderdSkus, sortField as Array<string>);
    // return orderdSkus;
  }

  get id(): number {
    return this.$route && this.$route.params && this.$route.params.id
      ? Number.parseInt(this.$route.params.id)
      : null;
  }
  get images(): mediaModel[] {
    var instance = this;
    var images = [];

    if (
      instance.currentProduct != null &&
      instance.currentProduct.media != null
    ) {
      images = instance.currentProduct.media.filter(
        x => x.mediaType == mediaType.Image
      );
    }
    if (images == null || images.length == 0) {
      images = instance.item.media.filter(x => x.mediaType == mediaType.Image);
    }

    return images.sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1));
  }
  get selectedImage(): mediaModel {
    var instance = this;
    if (
      instance.item == null ||
      instance.images.length == 0 ||
      instance.images.length < instance.imageIndex
    )
      return new mediaModel();
    else return instance.images[instance.imageIndex];
  }
  isSelected(image: mediaModel, index: number) {
    return this.imageIndex == index;
  }
  setImage(image: mediaModel, index: number) {
    return (this.imageIndex = index);
  }

  get first_listino_with_discount(): sisasListiniModel {
    if (this.item == null || this.item.listini == null) return null;

    var listino = this.item.listini.find(
      l => l.sconto1 != null && l.sconto1 != 0
    );
    return listino;
  }
  get skus(): string[] {
    var instance = this;
    if (instance.item == null) return [];

    var skus = [];
    if (instance.item.skus != null && instance.item.skus.length > 0) {
      instance.item.skus.forEach(p => {
        skus.push(p.sku);
        if (p.skus != null)
          p.skus.forEach(c => {
            skus.push(c.sku);
          });
      });
    }
    return skus;
  }
  getOptionValues(v: familyOptionViewModel) {
    var instance = this;
    var f = instance.filters.find(x => x.code == v.code);

    if (f == null) return [];
    var values = f.values.filter(
      o =>
        instance
          .getSkusProducts(instance.item)
          .find(x => x.variantsValues[v.code] == o.code) != null
    );
    return values == null
      ? []
      : values.sort((a, b) => (a.name > b.name ? 1 : -1));
  }
  onOptionClick(v: familyOptionViewModel, o: optionValueModel) {
    o.selected = !o.selected;
  }
  isOptionSelected(v: familyOptionViewModel, o: optionValueModel) {
    return o.selected;
  }
  getOptionCount(v: familyOptionViewModel, o: optionValueModel) {
    var instance = this;
    var filterd = instance
      .getSkusProducts(instance.item)
      .filter(x => x.variantsValues[v.code] == o.code);
    return filterd == null ? 0 : filterd.length;
  }
  get showAvtiveFiltersRow(): boolean {
    var instance = this;
    return instance.activeFilters.length > 0;
  }
  get activeFilters() {
    var instance = this;
    if (instance.filters == null) return [];
    var variantCodes = instance.getVariantCodes(instance.item);
    var validFilters = instance.filters.filter(
      x => variantCodes.indexOf(x.code) != -1
    );
    return validFilters.filter(
      x => x.values != null && x.values.find(o => o.selected) != null
    );
  }
  get activeFilterValues() {
    var instance = this;
    if (instance.activeFilters == null) return [];
    var variantCodes = instance.activeFilters.reduce((result, x) => {
      result[x.code] = x.values
        .filter(v => v.selected == true)
        .map(o => o.code);
      return result;
    }, {});
    return variantCodes;
  }

  onSelectFilter(f: optionAndSingleValueModel) {
    var instance = this;
    f.value.selected = !f.value.selected;
  }
  getSelectedOptions(filter: optionModel) {
    var instance = this;
    if (filter.values == null) return [];
    return filter.values.filter(x => x.selected);
  }
  get urlFilters(): any {
    var instance = this;
    var params = instance.$route.query;

    return params.filters != undefined && params.filters != null
      ? Qs.parse(params.filters)
      : {};
  }
  beforeMount() {
    var instance = this;
    domainService.getOptinFilters().then(res => {
      if (res == null) instance.filters = [];
      else {
        instance.filters = res.map(x => {
          var selFilter = instance.urlFilters[x.code];
          var f = new optionModel();
          f.code = x.code;
          f.name = x.name;
          f.type = x.type;
          f.languageId = x.languageId;
          f.values =
            x.values == null
              ? []
              : x.values.map(y => {
                var o = new optionValueModel();
                o.code = y.code;
                o.name = y.name;
                o.languageId = y.languageId;
                o.selected =
                  selFilter != null && selFilter.indexOf(y.code) != -1;
                return o;
              });
          return f;
        });
      }
    });
  }
  mounted() {
    var instance = this;
    instance.setDefaultImageIndex();
  }
  onNoProductToAdd() {

    var instance = this;
    instance.markNotSelected = true;
    // instance.$notify({
    //   title: "Nessun prodotto selezionato",
    //   text: "Seleziona la configurazione",
    //   type: "warn",
    //   group: "msg"
    // });
  }
}

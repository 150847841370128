import { Component, Prop, Watch } from "vue-property-decorator";
import baseUserContextComponent from "../baseUserContextComponent";
import { categoryModel } from "@/models/categoryModel";
import categoryBreaddcrumb from "./category-breadcrumb.vue";
@Component({})
export default class categoryBreaddcrumbBackLavel extends categoryBreaddcrumb {
  get backLavelCategory(): categoryModel {
    var instance = this;
    if (instance.items == null || instance.items.length < 2)
      return null;
    return instance.items[instance.items.length - 2];
  }
}

import Vue from "vue";

class eventClass extends Vue {
  public readonly events = {
    AXIOS_RESPONSE_ERROR: "axios-response-error",
    CART_UPDATED: "cart-updated",
    USER_LOGIN: "user-login",
    CURRENT_CUSTOMER_CHANGED: "current-customer-changed",
    PAGE_LOADING: "page-loading"
  };
}

const eventHub = new eventClass();
export default eventHub;
export const debounce = (func, delay) => {
  let inDebounce;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(context, args), delay);
  };
};
export const throttle = (func, limit) => {
  let inThrottle;
  return function () {
    const args = arguments;
    const context = this;
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), limit);
    }
  };
};

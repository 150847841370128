import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class AppImage extends Vue {
  @Prop()
  image: String;

  @Prop()
  fallbackImage: String;

  @Prop()
  type: String;

  @Prop({ default: null })
  width: String;

  @Prop({ default: null })
  height: String;
  @Prop({ default: "middle" })
  valign: String;

  @Prop({ default: false })
  addWrapper: Boolean;

  @Prop({ default: false })
  thumbnail: Boolean;

  @Prop({ default: false })
  fluid: Boolean;

  @Prop({ default: false })
  sticky: Boolean;

  get baseUrl(): string {
    return (process.env.VUE_APP_BASE_URL || '').substring(0, process.env.VUE_APP_BASE_URL.lastIndexOf("/api"));//.trimRight('/api').trimRight('api');
  }
  get getWidth() {
    if (
      this.$props.width == undefined ||
      this.$props.width == null ||
      this.$props.width == "0"
    )
      return "auto";

    return this.$props.width + "px";
  }

  get getHeight() {
    if (
      this.$props.height == undefined ||
      this.$props.height == null ||
      this.$props.height == "0"
    )
      return "auto";

    return this.$props.height + "px";
  }
  get wrapperHeight() {
    if (this.sticky) return "100%";
    return this.getHeight;
  }

  get source() {
    if (
      this.image == null ||
      this.image == undefined ||
      this.image == "" ||
      this.image.startsWith("http")
    ) {
      // return this.fallbackImage;
      if (this.type == null || this.type == "scale")
        return this.baseUrl + "/images/" + this.width + "/" + this.height + "/dev" + this.fallbackImage;
      else if (this.type == "crop")
        return this.baseUrl + "/images/c/" + this.width + "/" + this.height + "/dev" + this.fallbackImage;

    } else {
      if (this.type == null || this.type == "scale")
        return this.baseUrl + "/images/" + this.width + "/" + this.height + "/" + this.image;
      else if (this.type == "crop")
        return this.baseUrl + "/images/c/" + this.width + "/" + this.height + "/" + this.image;
    }

    return "";
  }
}

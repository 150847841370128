class doofinderConfig {
  public readonly config = {
    IS_DIRECT_ACTIVE: false,
    ENDPOINT: "https://eu1-search.doofinder.com/5/search",
    API_KEY: "eu1-d2410f09c2e8707af9d34f37ab658ca105e12345",
    HASH_ID: "f280bfd4c2a060d8be56eb5957fbd8be"
  };
}

const doofinder = new doofinderConfig();
export default doofinder;

import { Component, Prop } from "vue-property-decorator";
import { facetModel } from "@/models/facetModel";
import baseUserContextComponent from "../baseUserContextComponent";
import {
  optionModel,
  optionValueModel,
  optionAndSingleValueModel
} from "@/models/optionModel";
@Component({})
export default class filterButtonSelector extends baseUserContextComponent {
  @Prop({ default: () => new optionModel() })
  option: optionModel;
  // @Prop({ default: () => null })
  // filters: any | null;
  @Prop({ default: () => [] })
  facets: facetModel[];
  @Prop({ default: () => [] })
  facetsForCount: facetModel[];


  get isOptionSelected(): boolean {
    var instance = this;
    // if (
    //   instance.filters == null ||
    //   this.option == null ||
    //   instance.filters.hasOwnProperty(this.option.code) == false
    // )
    //   return false;
    // return instance.filters[this.option.code].indexOf(o.code) != -1;
    return instance.option && instance.option.values.find(o => o.selected) != null;
  }
  get facet(): facetModel {
    var f =
      this.facets == null || this.option == null
        ? null
        : this.facets.find(x => x.code == this.option.code);
    return f;
  }
  get facetForCount(): facetModel {
    var f =
      this.facetsForCount == null || this.option == null
        ? null
        : this.facetsForCount.find(x => x.code == this.option.code);
    return f;
  }
  get showFilter() {
    return this.values != null && this.values.length > 0;
  }
  get values() {
    var instance = this;
    if (instance.facet == null) return [];

    return instance.option.values.filter(x => instance.getCount(x) > 0 || instance.isSelected(x));

  }

  getCount(o: optionValueModel): number {
    var instance = this;

    if (instance.facet == null || o == null) return 0;
    var count = instance.facet.values[o.code];
    if (count == null && o.code != null)
      count = instance.facet.values[o.code.toLocaleLowerCase()];
    if (count == null && o.code != null)
      count = instance.facet.values[o.code.toLocaleUpperCase()];
    return count == null ? 0 : count;
  }
  getCountAgg(o: optionValueModel): number {
    var instance = this;

    if (instance.facetForCount == null || o == null) return 0;
    var count = instance.facetForCount.values[o.code];
    if (count == null && o.code != null)
      count = instance.facetForCount.values[o.code.toLocaleLowerCase()];
    if (count == null && o.code != null)
      count = instance.facetForCount.values[o.code.toLocaleUpperCase()];
    return count == null ? 0 : count;
  }
  isSelected(o: optionValueModel): boolean {
    // var instance = this;
    // if (
    //   instance.filters == null ||
    //   this.option == null ||
    //   instance.filters.hasOwnProperty(this.option.code) == false
    // )
    //   return false;
    // return instance.filters[this.option.code].indexOf(o.code) != -1;
    return o.selected;
  }
  showValue(o: optionValueModel): boolean {
    var instance = this;
    return instance.isSelected(o) || instance.getCount(o) > 0;
  }
  // onClick(o: optionValueModel) {
  //   var s = new optionAndSingleValueModel();
  //   s.option = this.option;
  //   s.value = o;
  //   // s[this.option.code] = o.code;
  //   this.$emit("select", s);
  // }
  onClick() {
    this.$emit("click", this.option);
  }
  created() {

  }
}

import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { CustomerInfoModel } from "@/models/customerModels";
import { addressModel } from "@/models/addressModel";
@Component({})
export default class customerAddress extends Vue {
  @Prop({ default: () => new addressModel() })
  address: addressModel;
  @Prop({ default: () => false })
  onlyAdress: boolean;
}
